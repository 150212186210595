import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core";
import RightIconSwitch from "../../assets/images/icon/RightIconSwitch";
import PlusIconSVG from "../../assets/images/icon/PlusIcon";
import CommonTypo from "../../components/CommonTypo";
import { Box } from "@mui/material";
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'inline-flex',
        padding: '2px 4px 4px 4px',
        alignItems: 'center',
        justifyContent: "space-around",
        gap: '6px',
        borderRadius: '50px',
        // background: '#EBF2F1',
        height: '40px',
        width: '100%',
        cursor: "pointer",
        alignSelf: "stretch"
    },
    background: {
        background: '#fff',
        width: '100%',
        height: '34px',
        borderRadius: '20px',

        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        padding: "8px 24px 8px 16px",
        gap: "4px",
        // borderRadius: '5px'
    },
    emptyIcon: {
        width: '100%',
        height: '34px',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        gap: "4px",
        padding: "8px 24px 8px 16px"
    },
    corssActive: {
        background: "#FF5555",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    },
    corssActiveInverse: {
        background: "var(--color-new-main)",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    },
    rightActive: {
        background: "var(--color-new-main)",
        "& svg": {
            "& path": {
                strokeWidth: "1px",
                stroke: `var(--color-black) !important`
            }
        },
        "& .MuiTypography-root": {
            fontWeight: `600 !important`
        }
    },
    rightActiveInverse: {
        background: "#FF5555",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    }

}));
const IconYesNo2 = ({ matricValue, journalItem, onClick, onDelete = () => { } }) => {
    const classes = useStyles()
    console.log("matricValue?.levelReach !==", matricValue?.levelReach !== "No");

    return (
        <div className={classes.container} role="button" onClick={(e) => {
            e.stopPropagation();
        }}>

            {matricValue?.levelReach !== "No" ? (
                <div className={clsx(classes.background, matricValue?.levelReach === "Yes" ? matricValue?.inverse === "true" ? classes.rightActiveInverse : classes.rightActive : classes.emptyIcon)} onClick={e => {
                    e.stopPropagation();
                    console.log("matricValue", matricValue);
                    if (matricValue?.levelReach) {
                        onDelete && onDelete()
                    } else {
                        if (matricValue && Object.keys(matricValue).length > 0) {
                            onClick("Yes", matricValue?.note);
                        } else {
                            onClick("Yes", []);
                        }
                    }

                }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5306 5.03057L6.5306 13.0306C6.46092 13.1005 6.37813 13.156 6.28696 13.1938C6.1958 13.2317 6.09806 13.2512 5.99935 13.2512C5.90064 13.2512 5.8029 13.2317 5.71173 13.1938C5.62057 13.156 5.53778 13.1005 5.4681 13.0306L1.9681 9.53057C1.89833 9.4608 1.84299 9.37798 1.80524 9.28683C1.76748 9.19568 1.74805 9.09798 1.74805 8.99932C1.74805 8.90066 1.76748 8.80296 1.80524 8.71181C1.84299 8.62066 1.89833 8.53783 1.9681 8.46807C2.03786 8.3983 2.12069 8.34296 2.21184 8.30521C2.30299 8.26745 2.40069 8.24802 2.49935 8.24802C2.59801 8.24802 2.69571 8.26745 2.78686 8.30521C2.87801 8.34296 2.96083 8.3983 3.0306 8.46807L5.99997 11.4374L13.4693 3.96932C13.6102 3.82842 13.8013 3.74927 14.0006 3.74927C14.1999 3.74927 14.391 3.82842 14.5318 3.96932C14.6727 4.11021 14.7519 4.30131 14.7519 4.50057C14.7519 4.69983 14.6727 4.89092 14.5318 5.03182L14.5306 5.03057Z" fill="var(--color-text-primary)" />
                    </svg>
                    <CommonTypo fontWeight={matricValue?.levelReach === "Yes" ? matricValue?.inverse === "true" ? 600 : 600 : 400} color="var(--color-text-primary)">Yes</CommonTypo>
                </div>
            ) : (
                <div className={classes.emptyIcon} onClick={e => {
                    e.stopPropagation();
                    console.log("matricValue new ", matricValue);
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("Yes", matricValue?.note);
                    } else {
                        onClick("Yes", []);
                    }
                }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5306 5.03057L6.5306 13.0306C6.46092 13.1005 6.37813 13.156 6.28696 13.1938C6.1958 13.2317 6.09806 13.2512 5.99935 13.2512C5.90064 13.2512 5.8029 13.2317 5.71173 13.1938C5.62057 13.156 5.53778 13.1005 5.4681 13.0306L1.9681 9.53057C1.89833 9.4608 1.84299 9.37798 1.80524 9.28683C1.76748 9.19568 1.74805 9.09798 1.74805 8.99932C1.74805 8.90066 1.76748 8.80296 1.80524 8.71181C1.84299 8.62066 1.89833 8.53783 1.9681 8.46807C2.03786 8.3983 2.12069 8.34296 2.21184 8.30521C2.30299 8.26745 2.40069 8.24802 2.49935 8.24802C2.59801 8.24802 2.69571 8.26745 2.78686 8.30521C2.87801 8.34296 2.96083 8.3983 3.0306 8.46807L5.99997 11.4374L13.4693 3.96932C13.6102 3.82842 13.8013 3.74927 14.0006 3.74927C14.1999 3.74927 14.391 3.82842 14.5318 3.96932C14.6727 4.11021 14.7519 4.30131 14.7519 4.50057C14.7519 4.69983 14.6727 4.89092 14.5318 5.03182L14.5306 5.03057Z" fill="var(--color-text-secondary)" />
                    </svg>
                    <CommonTypo color="var(--color-text-secondary)">Yes</CommonTypo>
                </div>
            )}
            {matricValue && Object.keys(matricValue).length && matricValue.levelReach === "No" ? (
                <div className={clsx(classes.background, matricValue?.inverse === "true" ? classes.corssActiveInverse : classes.corssActive)} onClick={e => {
                    e.stopPropagation();
                    console.log("matricValue", matricValue);
                    if (matricValue?.levelReach) {
                        onDelete && onDelete()
                    } else {
                        if (matricValue && Object.keys(matricValue).length > 0) {
                            onClick("No", matricValue?.note);
                        } else {
                            onClick("No", []);
                        }
                    }

                }}>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5306 11.9694C13.6715 12.1103 13.7506 12.3014 13.7506 12.5006C13.7506 12.6999 13.6715 12.891 13.5306 13.0319C13.3897 13.1728 13.1986 13.2519 12.9993 13.2519C12.8001 13.2519 12.609 13.1728 12.4681 13.0319L8.49997 9.06249L4.5306 13.0306C4.3897 13.1715 4.19861 13.2507 3.99935 13.2507C3.80009 13.2507 3.60899 13.1715 3.4681 13.0306C3.3272 12.8897 3.24805 12.6986 3.24805 12.4994C3.24805 12.3001 3.3272 12.109 3.4681 11.9681L7.43747 7.99999L3.46935 4.03061C3.32845 3.88972 3.2493 3.69862 3.2493 3.49936C3.2493 3.30011 3.32845 3.10901 3.46935 2.96811C3.61024 2.82722 3.80134 2.74806 4.0006 2.74806C4.19986 2.74806 4.39095 2.82722 4.53185 2.96811L8.49997 6.93749L12.4693 2.96749C12.6102 2.82659 12.8013 2.74744 13.0006 2.74744C13.1999 2.74744 13.3909 2.82659 13.5318 2.96749C13.6727 3.10838 13.7519 3.29948 13.7519 3.49874C13.7519 3.69799 13.6727 3.88909 13.5318 4.02999L9.56247 7.99999L13.5306 11.9694Z" fill="var(--color-text-primary)" />
                    </svg>
                    <CommonTypo fontWeight={matricValue?.levelReach === "No" ? matricValue?.inverse === "true" ? 600 : 600 : 400} color="var(--color-text-primary)">No</CommonTypo>
                </div>
            ) : (
                <div className={classes.emptyIcon} onClick={e => {
                    e.stopPropagation();
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("No", matricValue?.note);
                    } else {
                        onClick("No", []);
                    }
                }} >
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5306 11.9694C13.6715 12.1103 13.7506 12.3014 13.7506 12.5006C13.7506 12.6999 13.6715 12.891 13.5306 13.0319C13.3897 13.1728 13.1986 13.2519 12.9993 13.2519C12.8001 13.2519 12.609 13.1728 12.4681 13.0319L8.49997 9.06249L4.5306 13.0306C4.3897 13.1715 4.19861 13.2507 3.99935 13.2507C3.80009 13.2507 3.60899 13.1715 3.4681 13.0306C3.3272 12.8897 3.24805 12.6986 3.24805 12.4994C3.24805 12.3001 3.3272 12.109 3.4681 11.9681L7.43747 7.99999L3.46935 4.03061C3.32845 3.88972 3.2493 3.69862 3.2493 3.49936C3.2493 3.30011 3.32845 3.10901 3.46935 2.96811C3.61024 2.82722 3.80134 2.74806 4.0006 2.74806C4.19986 2.74806 4.39095 2.82722 4.53185 2.96811L8.49997 6.93749L12.4693 2.96749C12.6102 2.82659 12.8013 2.74744 13.0006 2.74744C13.1999 2.74744 13.3909 2.82659 13.5318 2.96749C13.6727 3.10838 13.7519 3.29948 13.7519 3.49874C13.7519 3.69799 13.6727 3.88909 13.5318 4.02999L9.56247 7.99999L13.5306 11.9694Z" fill="var(--color-text-secondary)" />
                    </svg>
                    <CommonTypo color="var(--color-text-secondary)">No</CommonTypo>
                </div>
            )}
        </div>
    )
}

export default IconYesNo2