import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Picker from "react-mobile-picker-scroll";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";
import { UserTypeContext } from "../../context/userStatus";
import { trackingService } from "../../services/tracking";
import { ButtonLoader } from "../../components/buttonLoader";
import InfluenceclosePopup from "./InfluenceclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
import { AddUpdateEventModal } from "./addTrackingEventInfluenceModal";
import typography, { pxToRem } from "../../theme/typography";
import moment from "moment";
import { TrackingContext } from "../../context/userTracking";
import { setDayCompleteMessage } from "../../store/actions/trackerAction";
const optionGroups = {
  hour: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  minutus: [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ],
  // AMPM: ["AM", "PM"],
};

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  pageTitle: {
    marginBottom: "16px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "40px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: "0px !important",
    },
  },
  influenceCaption: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  marginRight: {
    marginRight: "10px!important",
  },
  marginLeft: {
    marginLeft: "10px!important",
  },
  padding: {
    paddingTop: "20px!important",
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    resize: "vertical",
    "&:focus-visible": {
      outline: "red",
    },
  },
}));

interface AddTimeOfDay {
  setOpenTimeOfDayModal: (openTimeOfDayModal: boolean) => void;
  item: any;
  isEdit: Boolean;
  setIsEdit: (isEdit: boolean) => void;
  journeyData: any;
  headerDate: any;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  callService: any;
  setInfluenceId: (influenceId: string) => void;
  callToGetData?: boolean;
  isMobile?: boolean;
  renderToNextInput?: () => void;
  categoryId?: string;
}

const AddTimeOfDay = (props: AddTimeOfDay) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const { dispatch: trackingDispatch } = useContext(TrackingContext);
  const [openStartModal, setStartModal] = useState(true);
  const [notes, setNotes] = useState<any>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [hour, setHour] = useState(moment(new Date()).format("HH"));
  const [minute, setMinute] = useState(moment(new Date()).format("mm"));
  const [editData, setEditData] = useState<any>();
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);
  const [openInfluenceclosePopup, setopenInfluenceclosePopup] = useState(false);
  const [openInfluenceDeletePopup, setopenInfluenceDeletePopup] =
    useState(false);
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: moment(new Date()).format("HH"),
    minutus: moment(new Date()).format("mm"),
    AMPM: moment.utc(new Date()).format("A"),
  });
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, notes, minute, isBackButtonClicked]);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true);
      if (
        editData?.influenceId &&
        editData?.hour === hour &&
        editData?.minute === minute &&
        (notes === editData?.note[0] || (!notes && !editData?.note[0]))
      ) {
        handleCloseStartModal();
      } else if (hour !== "" || notes !== "" || minute !== "") {
        setopenInfluenceclosePopup(true);
      } else {
        handleCloseStartModal();
      }
    }
  };
  let inputRef = null;
  const { isMobile = false } = props;
  useEffect(() => {
    const outcomeGetData = {
      influenceId: props.item?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .getInfluenceRes(outcomeState.token, outcomeGetData)
      .then((data) => {
        setEditData(data?.data);
        if (data?.data === null) {
          props?.setIsEdit(false);
          previousData();
        } else {
          props?.setIsEdit(true);
          setNotes(data?.data?.note[0] ?? "");
          const time = data?.data?.timeOfDay.split(":");
          setHour(time[0] ?? "");
          setMinute(time[1] ?? "");
          setEndTimeValuegroup({
            hour: time[0] ?? moment(new Date()).format("HH"),
            minutus: time[1] ?? moment(new Date()).format("mm"),
            AMPM: moment.utc(new Date()).format("A"),
          });
          setEditData({
            hour: time[0],
            minute: time[1],
            ...data?.data,
          });
        }
      })
      .catch((error) => {
        console.log("outcome response get error");
      });
  }, []);
  useEffect(
    () => {
      // if (inputRef.current) {
      //   const refff: any = inputRef.current
      //   refff.focus()
      // }
      // if(inputRef) {
      //   inputRef.focus()
      // }
    } /* , [inputRef.current] */
  );
  const handleCloseStartModal = async () => {
    setStartModal(false);
    console.log(`props.setOpenTimeOfDayModal`, editData, props);
    
    await props.setOpenTimeOfDayModal(false);
    props.setInfluenceId("");
    if (!editData?.influenceId) {
      props?.renderToNextInput();
    }
  };
  const handleDeleteEntry = () => {
    let payload = {
      influenceId: editData?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .deleteInfluenceRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(
            (it) => it.influenceId === payload.influenceId
          );
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any;
            const foundDateItemIcon = journyList.influence_data.findIndex(
              (it) =>
                it.influenceId === payload.influenceId &&
                it?.date === payload.date
            );
            if (foundDateItemIcon > -1) {
              journyList.influence_data.splice(foundDateItemIcon, 1);
            }
            UserTypeDispatch(addJourneyAction(journyList as any));
            if (props.callToGetData) {
              props?.callService(false);
            }
          }
        } else {
          const journeyCategoryList = [
            ...(outcomeState?.journeyCategoryList || []),
          ];
          const foundOutcome = (journeyCategoryList || []).findIndex(
            (it) =>
              it.influenceIds && it.influenceIds.includes(payload.influenceId)
          );
          if (foundOutcome > -1) {
            if (
              journeyCategoryList[foundOutcome].alls &&
              journeyCategoryList[foundOutcome].alls.length
            ) {
              const foundIndex = journeyCategoryList[
                foundOutcome
              ].alls.findIndex((it) => it.influenceId === payload.influenceId);
              if (foundIndex > -1) {
                if (
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .influenceResponses &&
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .influenceResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[
                    foundIndex
                  ].influenceResponses.findIndex(
                    (it) => it.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].influenceResponses.splice(foundDateValue, 1);
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(
              (it) => it.name === "Other"
            );
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[
                foundOther
              ].influences.findIndex(
                (it) => it.influenceId === payload.influenceId
              );
              if (outcomeFound > -1) {
                if (
                  journeyCategoryList[foundOther].influences[outcomeFound]
                    .influenceResponses &&
                  journeyCategoryList[foundOther].influences[outcomeFound]
                    .influenceResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[
                    foundOther
                  ].influences[outcomeFound].influenceResponses.findIndex(
                    (it) => it.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[
                      outcomeFound
                    ].influenceResponses.splice(foundDateValue, 1);
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          }
        }
        setShowLoadingBtn(false);
        props.setShowSnakbar(false);
        props.setSnakbarMsg(data?.message);
        props.setOpenTimeOfDayModal(false);
        // props?.callService(false);
        props?.setInfluenceId("");
        props.setMsgType("success");
        if (!outcomeState?.isPersonal) {
          if (!editData?.influenceId) {
            props?.renderToNextInput();
          }
        }
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };

  const createTimeOfDayLevel = (e = null) => {
    e && e.preventDefault();
    setShowLoadingBtn(true);
    let outcomeCreateData;

    if (hour === "") {
      setShowLoadingBtn(false);
      props.setShowSnakbar(true);
      props.setSnakbarMsg("Please enter hours");
      props.setMsgType("error");
      return;
    } else if (minute === "") {
      setShowLoadingBtn(false);
      props.setShowSnakbar(true);
      props.setSnakbarMsg("Please enter minutes");
      props.setMsgType("error");
      return;
    } else if (parseInt(hour) > 23) {
      props.setShowSnakbar(true);
      props.setSnakbarMsg("Please enter hour from 0 and 23");
      props.setMsgType("error");
      setShowLoadingBtn(false);
      return;
    } else if (parseInt(minute) > 59) {
      props.setShowSnakbar(true);
      props.setSnakbarMsg("Please enter minute from 0 and 59");
      props.setMsgType("error");
      setShowLoadingBtn(false);
      return;
    }

    outcomeCreateData = {
      influenceId: props.item?.influenceId,
      note: notes === undefined || notes === "" ? [] : [notes],
      date: props?.headerDate,
      type: props?.item?.type,
      timeOfDay: `${hour}:${minute}`,
      response_type: "TIME_OF_THE_DAY",
    };
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(
            (it) => it.influenceId === outcomeCreateData.influenceId
          );
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any;
            const foundDateItemIcon = journyList.influence_data.findIndex(
              (it) =>
                it.influenceId === outcomeCreateData.influenceId &&
                it?.date === outcomeCreateData.date
            );
            if (foundDateItemIcon > -1) {
              journyList.influence_data[foundDateItemIcon] = {
                ...foundOutcome,
                ...outcomeCreateData,
              };
            } else {
              journyList.influence_data.push({
                ...foundOutcome,
                ...outcomeCreateData,
              } as any);
            }
            UserTypeDispatch(addJourneyAction(journyList as any));
            // if (props.callToGetData) {
            //   props?.callService(false);
            // }
            if (!isMobile) {
              props?.renderToNextInput();
            }
          }
        } else {
          const journeyCategoryList = [
            ...(outcomeState?.journeyCategoryList || []),
          ];
          const foundOutcome = (journeyCategoryList || []).findIndex(
            (it) =>
              it.influenceIds &&
              it.influenceIds.includes(outcomeCreateData.influenceId)
          );
          if (foundOutcome > -1) {
            if (
              journeyCategoryList[foundOutcome].alls &&
              journeyCategoryList[foundOutcome].alls.length
            ) {
              const foundIndex = journeyCategoryList[
                foundOutcome
              ].alls.findIndex(
                (it) => it.influenceId === outcomeCreateData.influenceId
              );
              if (foundIndex > -1) {
                if (
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .influenceResponses &&
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .influenceResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[
                    foundIndex
                  ].influenceResponses.findIndex(
                    (it) => it.date === outcomeCreateData.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData,
                    };
                  } else {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].influenceResponses.push(outcomeCreateData);
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[
                    foundIndex
                  ].influenceResponses = [outcomeCreateData];
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                // if (props.callToGetData) {
                //   props?.callService(false);
                // }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(
              (it) => it.name === "Other"
            );
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[
                foundOther
              ].influences.findIndex(
                (it) => it.influenceId === outcomeCreateData.influenceId
              );
              if (outcomeFound > -1) {
                if (
                  journeyCategoryList[foundOther].influences[outcomeFound]
                    .influenceResponses &&
                  journeyCategoryList[foundOther].influences[outcomeFound]
                    .influenceResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[
                    foundOther
                  ].influences[outcomeFound].influenceResponses.findIndex(
                    (it) => it.date === outcomeCreateData.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[
                      outcomeFound
                    ].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData,
                    };
                  } else {
                    journeyCategoryList[foundOther].influences[
                      outcomeFound
                    ].influenceResponses.push(outcomeCreateData);
                  }
                } else {
                  journeyCategoryList[foundOther].influences[
                    outcomeFound
                  ].influenceResponses = [outcomeCreateData];
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                // if (props.callToGetData) {
                //   props?.callService(false);
                // }
              }
            }
          }
          if (isMobile) {
            const foundOutcome = (outcomeState?.influenceList || []).find(
              (it) => it.influenceId === outcomeCreateData.influenceId
            );
            if (foundOutcome) {
              const journyList = {
                ...(outcomeState?.journeyList || {}),
              } as any;
              const foundDateItemIcon = journyList.influence_data.findIndex(
                (it) =>
                  it.influenceId === outcomeCreateData.influenceId &&
                  it?.date === outcomeCreateData.date
              );
              if (foundDateItemIcon > -1) {
                journyList.influence_data[foundDateItemIcon] = {
                  ...foundOutcome,
                  ...outcomeCreateData,
                };
              } else {
                journyList.influence_data.push({
                  ...foundOutcome,
                  ...outcomeCreateData,
                } as any);
              }
              UserTypeDispatch(addJourneyAction(journyList as any));
              // if (props.callToGetData) {
              //   props?.callService(false);
              // }
              if (!editData?.influenceId && !isMobile) {
                props?.renderToNextInput();
              }
            }
          }
          if (!editData?.influenceId && !isMobile) {
            setTimeout(() => {
              props?.renderToNextInput();
            }, 500);
          }
        }
        if (
          data.showMessage &&
          props?.headerDate === moment().format("MM-DD-YYYY")
        ) {
          trackingDispatch(
            setDayCompleteMessage({
              open: data.showMessage,
              message: data.message,
            })
          );
        }
        setShowLoadingBtn(false);
        // props.setShowSnakbar(true);
        props.setSnakbarMsg(data?.message);
        props.setOpenTimeOfDayModal(false);
        // props?.callService(false);
        // props?.setInfluenceId("");
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey;
    if (e.keyCode === 13 || (keyPress && [65, 85].includes(e.keyCode))) {
      if (!textAreaFocused) {
        createTimeOfDayLevel();
      }
    }
  };
  const focusUsernameInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  const previousData = () => {
    const outcomeGetData = {
      influenceId: props.item?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .getLastInfluenceRes(outcomeState.token, outcomeGetData)
      .then((data) => {
        console.log("data", data);
        if (data.data) {
          const time = data.data.timeOfDay.split(":");
          setHour(time[0] ?? "");
          setMinute(time[1] ?? "");
          setEndTimeValuegroup({
            hour: time[0] ?? moment(new Date()).format("HH"),
            minutus: time[1] ?? moment(new Date()).format("mm"),
            AMPM: moment.utc(new Date()).format("A"),
          });
        }
      })
      .catch((error) => {
        console.log("outcome response get error");
      });
    return null;
  };
  console.log(`props.setOpenTimeOfDayModal`, editData, props);

  return (
    <Grid>
      <AddUpdateEventModal
        onClose={
          editData?.influenceId &&
            editData?.hour === hour &&
            editData?.minute === minute &&
            (notes === editData?.note[0] || (!notes && !editData?.note[0]))
            ? handleCloseStartModal
            : hour !== "" || notes !== "" || minute !== ""
              ? () => {
                setopenInfluenceclosePopup(true);
              }
              : handleCloseStartModal
        }
        onSave={createTimeOfDayLevel}
        showTitle={!props.isMobile}
        isMobile={props.isMobile}
        paperProps={
          isMobile
            ? {
              height: "calc(100vh - 27%)",
            }
            : {}
        }
        title={
          <>
            {props?.isEdit ? "Update Tracking Event" : "Add Tracking Event"}
            {editData?.influenceId && (
              <IconButton
                onClick={() => {
                  setopenInfluenceDeletePopup(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        }
        showLoadingBtn={showLoadingBtn}
        visible={openStartModal}
        buttonText={
          props?.isEdit ? (
            <span>
              <span style={{ textDecoration: "underline" }}>U</span>pdate
            </span>
          ) : (
            <span>
              <span style={{ textDecoration: "underline" }}>A</span>dd
            </span>
          )
        }
        saveDisabled={
          !(hour !== "" && Boolean(hour) && minute !== "" && Boolean(minute))
        }
      >
        {isMobile && editData?.influenceId && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
        <form onKeyDown={onKeyDownHandler}>
          <div
            style={{
              display: "flex",
              padding: props.isMobile ? "0px" : "24px 32px 0px 32px",
              flexDirection: "column",
              alignItems: props.isMobile ? "flex-start" : "center",
              gap: props.isMobile ? "24px" : "16px",
              justifyContent: "center",
              width: "100%",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={
                isMobile
                  ? {
                    color: palette.primary.light,
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "125% !important",
                    // textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(20)} !important`,
                        lineHeight: "125% !important",
                      },
                    },
                  }
                  : {
                    fontWeight: 600,
                    fontSize: `${pxToRem(24)} !important`,
                    lineHeight: "135%  !important",
                  }
              }
              className={classes.registrationHeading}
            >
              {props?.item?.name || "Name"}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              padding: props.isMobile ? "0px" : "32px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                alignSelf: "stretch",
              }}
            >
              {/* <Typography
                variant="caption"
                component="p"
                className={classes.pageTitle}
              >
                When did this happen last?
              </Typography> */}
              {props.isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                    marginTop: "32px",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.pageTitle}
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "125% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "125% !important",
                        },
                      },
                    }}
                  >
                    When did this happen today?
                  </Typography>
                  <Picker
                    optionGroups={optionGroups}
                    valueGroups={endTimeValuegroup}
                    onChange={(name, value) => {
                      setEndTimeValuegroup({
                        ...endTimeValuegroup,
                        [name]: value,
                      });
                      if (name === "hour") {
                        setHour(value);
                      }
                      if (name === "minutus") {
                        setMinute(value);
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    alignSelf: "stretch",
                    width: "100%",
                  }}
                >
                  <TextField
                    // className={`${classes.marginRight} textField`}
                    id="outlined-basic"
                    type="number"
                    InputProps={{
                      inputProps: { min: "0", max: "23", step: "1" },
                    }}
                    variant="outlined"
                    sx={{
                      display: "flex",
                      padding: "18px",
                      alignItems: "center",
                      gap: "12px",
                      flex: "1 0 0",
                      borderRadius: "12px",
                      border: "1px solid #EDEDED",
                      background: "#FFF",
                      marginBottom: `0 !important`,
                      "& .MuiInputBase-root": {
                        width: "100%",
                        "& .MuiInputBase-input": {
                          padding: `0px !important`,
                        },
                      },
                    }}
                    autoFocus
                    inputRef={(input) => {
                      if (input && !isFocused) {
                        input.focus();
                        setTimeout(() => {
                          setIsFocused(true);
                        }, 500);
                      }
                      // input && input.focus()
                    }}
                    size="small"
                    value={hour || ""}
                    onChange={(e) => {
                      setHour(e.target.value);
                    }}
                  />
                  <Typography
                    variant="caption"
                    component="div"
                    className={`${classes.influenceCaption} ${classes.padding}`}
                  >
                    :
                  </Typography>
                  <TextField
                    // className={`${classes.marginLeft} textField`}
                    id="outlined-basic"
                    type="number"
                    sx={{
                      display: "flex",
                      padding: "18px",
                      alignItems: "center",
                      gap: "12px",
                      flex: "1 0 0",
                      borderRadius: "12px",
                      border: "1px solid #EDEDED",
                      background: "#FFF",
                      marginBottom: `0 !important`,
                      "& .MuiInputBase-root": {
                        width: "100%",
                        "& .MuiInputBase-input": {
                          padding: `0px !important`,
                        },
                      },
                    }}
                    InputProps={{
                      inputProps: { min: "0", max: "59", step: "1" },
                    }}
                    variant="outlined"
                    value={minute || ""}
                    onChange={(e) => {
                      setMinute(e.target.value);
                    }}
                  />
                </div>
              )}

              <div
                style={
                  isMobile
                    ? {
                      width: "100%",
                      marginTop: "16px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }
                    : { width: "100%", marginTop: "32px" }
                }
              >
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                  sx={
                    isMobile
                      ? {
                        color: palette.primary.light,
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "125% !important",
                        // textAlign: "center !important",
                        fontWeight: `500 !important`,
                        "&.MuiTypography-root": {
                          "@media  (max-width: 600px) and (min-width: 100px)":
                          {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "125% !important",
                          },
                        },
                      }
                      : {}
                  }
                >
                  {isMobile
                    ? "Note for today’s entry"
                    : "Add a note for today’s entry"}
                </Typography>
                {isMobile ? (
                  <TextField
                    variant="outlined"
                    className="textarea"
                    sx={
                      isMobile
                        ? {
                          backgroundColor: `#fff`,
                          border: `1px solid #e3e3e3 !important`,
                          borderRadius: `10px`,
                        }
                        : {
                          backgroundColor: `#fff`,
                          border: `1px solid #e3e3e3 !important`,
                          borderRadius: `10px`,
                          marginBottom: "0px !important",
                        }
                    }
                    fullWidth
                    placeholder={
                      /* isMobile ? "What’s worth remembering today?" : */ "Add a note..."
                    }
                    multiline
                    onFocus={() => {
                      setTextAreaFocused(true);
                    }}
                    onBlur={() => {
                      setTextAreaFocused(false);
                    }}
                    minRows={4}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes || ""}
                  />
                ) : (
                  <TextareaAutosize
                    // variant="outlined"
                    className={"textarea " + classes.textarea}
                    // sx={{
                    //   backgroundColor: `#fff`,
                    //   border: `1px solid #e3e3e3 !important`,
                    //   borderRadius: `10px`,
                    //   marginBottom: "40px !important"
                    // }}
                    onFocus={() => {
                      setTextAreaFocused(true);
                    }}
                    onBlur={() => {
                      setTextAreaFocused(false);
                    }}
                    // fullWidth
                    placeholder={
                      /* isMobile ? "What’s worth remembering today?": */ "Add a note..."
                    }
                    // multiline
                    minRows={1}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes || ""}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        <InfluenceclosePopup
          open={openInfluenceDeletePopup}
          isDelete={openInfluenceDeletePopup}
          setopenInfluenceclosePopup={setopenInfluenceDeletePopup}
          handleCloseStartModal={handleDeleteEntry}
          setBackbuttonPress={setBackbuttonPress}
        />
        <InfluenceclosePopup
          open={openInfluenceclosePopup}
          setopenInfluenceclosePopup={setopenInfluenceclosePopup}
          handleCloseStartModal={handleCloseStartModal}
          setBackbuttonPress={setBackbuttonPress}
        />
      </AddUpdateEventModal>
    </Grid>
  );
};

export default AddTimeOfDay;
