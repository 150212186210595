/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import ProgressStepper, { ProgressStepperNew } from "./progressStepper";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { ButtonLoader } from "../../components/buttonLoader";
import OutcomeclosePopup from "./OutcomeclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
import typography, { pxToRem } from "../../theme/typography";
import { TrackingContext } from "../../context/userTracking";
import { setDayCompleteMessage } from "../../store/actions/trackerAction";
import moment from "moment";
import NewButton from "../../components/NewButton";

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.common.black}!important`,
    textAlign: "center",
    width: "100%",
    // marginBottom: "20px!important",
  },
  registrationHeadingMobile: {
    color: `${palette.primary.light}!important`,
    width: "100%",
    // marginBottom: "20px!important",
  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  bottomButton: {
    display: "flex",
    padding: "32px",
    gap: "12px",
    alignSelf: "stretch",
    alignItems: "center",
    borderTop: "1px solid #F3F3F3",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: "0px !important",
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    resize: "vertical",
    "&:focus-visible": {
      outline: "red",
    },
  },
}));

interface AddTrackingEvent {
  setOpenTrackingEventModal: (openTrackingEventModal: boolean) => void;
  index: number;
  setOutcomeId: (outcomeId: string) => void;
  getBackgroudColor: any;
  setSelectedOption: (selectedOption: any) => void;
  selectedOption: any;
  setShowInput?: (showInput: boolean) => void;
  outcomeId?: string;
  item: any;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  setOptionIndex: (optionIndex: string) => void;
  optionIndex: string;
  setDisplayBorder: (displayBorder: boolean) => void;
  displayBorder: boolean;
  callService: any;
  isEdit: boolean;
  journeyData: any;
  setIsEdit: any;
  headerDate: any;
  callToGetData?: boolean;
  isMobile?: boolean;
  renderToNextInput?: () => void;
}

const AddTrackingEventModal = (props: AddTrackingEvent) => {
  const classes = useStyles();

  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);
  const { isMobile = false } = props;
  // const {history} = useHistory();
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.optionIndex, outcomeNotes, isBackButtonClicked]);
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey;
    if (e.keyCode === 13 || (keyPress && [65, 85].includes(e.keyCode))) {
      if (!textAreaFocused) {
        trackingOutcomeCreate();
      }
    } else if(keyPress && [75].includes(e.keyCode) && !(item?.score !== "" && Number(item?.score) >= 0)) {
      setShowLoadingBtn(false);
      props.setOpenTrackingEventModal(false);
      props.renderToNextInput && !isMobile && props.renderToNextInput();
    }
  };
  // useEffect(() => {
  //   const close = (e) => {
  //     let keyPress = e.ctrlKey || e.metaKey
  //     if (keyPress && e.key === 65) {
  //       trackingOutcomeCreate()
  //     }
  //   }
  //   window.addEventListener('keydown', close)
  //   return () => window.removeEventListener('keydown', close)
  // }, [])
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true);
      if (
        editData?.outcomeId &&
        editData?.index === props.optionIndex &&
        (outcomeNotes === editData?.note[0] ||
          (!outcomeNotes && !editData?.note[0]))
      ) {
        handleCloseStartModal();
      } else if (props.optionIndex !== "" || outcomeNotes !== "") {
        setopenOutcomeclosePopup(true);
      } else {
        handleCloseStartModal();
      }
    }
  };
  const handleCloseStartModal = () => {
    setStartModal(false);
    props.setOpenTrackingEventModal(false);
    props.setOutcomeId("");
    // props.setShowInput(false);
  };
  // useEffect(() => {
  //   return () => {
  //     // && history.location.pathname === "any specific path")
  //     if (history.action === "POP") {
  //       history.replace(history.location.pathname, /* the new state */);
  //     }
  //   };
  // }, [history])
  const handleDeleteEntry = () => {
    let payload = {
      outcomeId: editData?.outcomeId,
      date: props?.headerDate,
    };
    trackingService
      .deleteOutcomeRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.outcomeList || []).find(
            (it) => it.outcomeId === payload.outcomeId
          );
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any;
            const foundDateItemIcon = journyList.outcome_data.findIndex(
              (it) =>
                it.outcomeId === payload.outcomeId && it?.date === payload.date
            );
            if (foundDateItemIcon > -1) {
              journyList.outcome_data.splice(foundDateItemIcon, 1);
              UserTypeDispatch(addJourneyAction(journyList as any));
              if (props.callToGetData) {
                props?.callService(false);
              }
            }
          }
        } else {
          const journeyCategoryList = [
            ...(outcomeState?.journeyCategoryList || []),
          ];
          const foundOutcome = (journeyCategoryList || []).findIndex(
            (it) => it.outcomeIds && it.outcomeIds.includes(payload.outcomeId)
          );
          if (foundOutcome > -1) {
            if (
              journeyCategoryList[foundOutcome].alls &&
              journeyCategoryList[foundOutcome].alls.length
            ) {
              const foundIndex = journeyCategoryList[
                foundOutcome
              ].alls.findIndex((it) => it.outcomeId === payload.outcomeId);
              if (foundIndex > -1) {
                if (
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .outcomesResponses &&
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .outcomesResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[
                    foundIndex
                  ].outcomesResponses.findIndex(
                    (it) => it.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].outcomesResponses.splice(foundDateValue, 1);
                    UserTypeDispatch(
                      addCategoryJourneyAction(journeyCategoryList)
                    );
                    if (props.callToGetData) {
                      props?.callService(false);
                    }
                  }
                }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(
              (it) => it.name === "Other"
            );
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[
                foundOther
              ].outcomes.findIndex((it) => it.outcomeId === payload.outcomeId);
              if (outcomeFound > -1) {
                if (
                  journeyCategoryList[foundOther].outcomes[outcomeFound]
                    .outcomesResponses &&
                  journeyCategoryList[foundOther].outcomes[outcomeFound]
                    .outcomesResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[
                    foundOther
                  ].outcomes[outcomeFound].outcomesResponses.findIndex(
                    (it) => it.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].outcomes[
                      outcomeFound
                    ].outcomesResponses.splice(foundDateValue, 1);
                    UserTypeDispatch(
                      addCategoryJourneyAction(journeyCategoryList)
                    );
                    if (props.callToGetData) {
                      props?.callService(false);
                    }
                  }
                }
              }
            }
          }
        }
        setShowLoadingBtn(false);
        props.setShowSnakbar(false);
        props.setSnakbarMsg(data?.message);
        props.setOpenTrackingEventModal(false);
        // props?.callService(false);
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };

  const trackingOutcomeCreate = () => {
    if (item?.score !== "" && Number(item?.score) >= 0) {
      setShowLoadingBtn(true);
      let outcomeCreateData;
      if (props?.isEdit) {
        outcomeCreateData = {
          date: props?.headerDate,
          outcomeId: editData?.outcomeId,
          answerId: item?.score === 0 ? 0 : item?.answerId,
          score: item?.score === 0 ? 0 : item?.score,
          index: props.optionIndex,
          note:
            outcomeNotes === undefined || outcomeNotes === ""
              ? []
              : [outcomeNotes],
        };
      } else {
        outcomeCreateData = {
          date: props?.headerDate,
          outcomeId: props.item?.outcomeId,
          answerId: item?.score === 0 ? 0 : item?.answerId || 0,
          score: item?.score === 0 ? 0 : item?.score,
          index: parseInt(props.optionIndex),
          note:
            outcomeNotes === undefined || outcomeNotes === ""
              ? []
              : [outcomeNotes],
        };
      }
      trackingService
        .outcomeCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          if (!outcomeState?.isPersonal) {
            const foundOutcome = (outcomeState?.outcomeList || []).find(
              (it) => it.outcomeId === outcomeCreateData.outcomeId
            );
            if (foundOutcome) {
              const journyList = {
                ...(outcomeState?.journeyList || {}),
              } as any;
              const foundDateItemIcon = journyList.outcome_data.findIndex(
                (it) =>
                  it.outcomeId === outcomeCreateData.outcomeId &&
                  it?.date === outcomeCreateData.date
              );
              if (foundDateItemIcon > -1) {
                journyList.outcome_data[foundDateItemIcon] = {
                  ...foundOutcome,
                  ...outcomeCreateData,
                };
              } else {
                journyList.outcome_data.push({
                  ...foundOutcome,
                  ...outcomeCreateData,
                } as any);
              }
              if (props.callToGetData) {
                props?.callService(false);
              }
              UserTypeDispatch(addJourneyAction(journyList as any));
            }
          } else {
            const journeyCategoryList = [
              ...(outcomeState?.journeyCategoryList || []),
            ];
            const foundOutcome = (journeyCategoryList || []).findIndex(
              (it) =>
                it.outcomeIds &&
                it.outcomeIds.includes(outcomeCreateData.outcomeId)
            );
            if (foundOutcome > -1) {
              if (
                journeyCategoryList[foundOutcome].alls &&
                journeyCategoryList[foundOutcome].alls.length
              ) {
                const foundIndex = journeyCategoryList[
                  foundOutcome
                ].alls.findIndex(
                  (it) => it.outcomeId === outcomeCreateData.outcomeId
                );
                if (foundIndex > -1) {
                  if (
                    journeyCategoryList[foundOutcome].alls[foundIndex]
                      .outcomesResponses &&
                    journeyCategoryList[foundOutcome].alls[foundIndex]
                      .outcomesResponses.length
                  ) {
                    const foundDateValue = journeyCategoryList[
                      foundOutcome
                    ].alls[foundIndex].outcomesResponses.findIndex(
                      (it) => it.date === outcomeCreateData.date
                    );
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOutcome].alls[
                        foundIndex
                      ].outcomesResponses[foundDateValue] = {
                        ...outcomeCreateData,
                      };
                    } else {
                      journeyCategoryList[foundOutcome].alls[
                        foundIndex
                      ].outcomesResponses.push(outcomeCreateData);
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].outcomesResponses = [outcomeCreateData];
                  }
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                  UserTypeDispatch(
                    addCategoryJourneyAction(journeyCategoryList)
                  );
                }
              }
            } else {
              const foundOther = (journeyCategoryList || []).findIndex(
                (it) => it.name === "Other"
              );
              if (foundOther > -1) {
                const outcomeFound = journeyCategoryList[
                  foundOther
                ].outcomes.findIndex(
                  (it) => it.outcomeId === outcomeCreateData.outcomeId
                );
                if (outcomeFound > -1) {
                  if (
                    journeyCategoryList[foundOther].outcomes[outcomeFound]
                      .outcomesResponses &&
                    journeyCategoryList[foundOther].outcomes[outcomeFound]
                      .outcomesResponses.length
                  ) {
                    const foundDateValue = journeyCategoryList[
                      foundOther
                    ].outcomes[outcomeFound].outcomesResponses.findIndex(
                      (it) => it.date === outcomeCreateData.date
                    );
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOther].outcomes[
                        outcomeFound
                      ].outcomesResponses[foundDateValue] = {
                        ...outcomeCreateData,
                      };
                    } else {
                      journeyCategoryList[foundOther].outcomes[
                        outcomeFound
                      ].outcomesResponses.push(outcomeCreateData);
                    }
                  } else {
                    journeyCategoryList[foundOther].outcomes[
                      outcomeFound
                    ].outcomesResponses = [outcomeCreateData];
                  }
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                  UserTypeDispatch(
                    addCategoryJourneyAction(journeyCategoryList)
                  );
                }
              }
            }
          }

          if (
            data.showMessage &&
            props?.headerDate === moment().format("MM-DD-YYYY")
          ) {
            trackingDispatch(
              setDayCompleteMessage({
                open: data.showMessage,
                message: data.message,
              })
            );
          }
          setShowLoadingBtn(false);
          // props.setShowSnakbar(true);
          // props.setSnakbarMsg(data?.message);
          props.setOpenTrackingEventModal(false);
          // props?.callService(false);
          // props.setMsgType("success");
          props.renderToNextInput && !isMobile && props.renderToNextInput();
        })
        .catch((error) => {
          setShowLoadingBtn(false);
          props.setShowSnakbar(true);
          props.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error?.message
          );
          props.setMsgType("error");
        });
    } else {
      setShowLoadingBtn(false);
      props.setOpenTrackingEventModal(false);
      props.renderToNextInput && !isMobile && props.renderToNextInput();
    }
  };
  useEffect(() => {
    if (props?.item) {
      const outcomeGetData = {
        outcomeId: props.item?.outcomeId,
        date: props?.headerDate,
      };
      trackingService
        .getOutcomeRes(outcomeState.token, outcomeGetData)
        .then((data) => {
          setEditData(data?.data);
          if (data?.data === null) {
            props?.setIsEdit(false);
          } else {
            props?.setIsEdit(true);
          }
          setOutcomeNotes(data?.data?.note[0] ?? "");
          props?.setOptionIndex(data?.data?.index ?? "");
          setItem(data?.data);
        })
        .catch((error) => {
          console.log("outcome response get error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.item]);
  if (props?.item) {
    return (
      <Grid>
        <form onKeyDown={onKeyDownHandler}>
          <Dialog
            onClose={
              editData?.outcomeId &&
                editData?.index === props.optionIndex &&
                (outcomeNotes === editData?.note[0] ||
                  (!outcomeNotes && !editData?.note[0]))
                ? handleCloseStartModal
                : props.optionIndex !== "" || outcomeNotes !== ""
                  ? () => {
                    setopenOutcomeclosePopup(true);
                  }
                  : handleCloseStartModal
            }
            sx={
              isMobile
                ? {
                  "& .MuiPaper-root": {
                    padding: "0",
                    borderRadius: "24px",
                    background: "#FFF",
                    "@media  (max-width: 726px) and (min-width: 100px)": {
                      height: "calc(100vh - 40%)",
                    },
                    // position: 'relative'
                  },
                }
                : {
                  "& .MuiPaper-root": {
                    padding: "0",
                    borderRadius: "24px",
                    background: "#FFF",
                    // position: 'relative'
                  },
                }
            }
            open={openStartModal}
            className="dialogWrapper dialogWrapper-tracking dialogWrapper-mobileScreen hide-scrollbar"
            fullWidth
          >
            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  padding: "20px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  alignSelf: "stretch",
                  borderBottom: "1px solid #F3F3F3",
                  background: "#FFF",
                  position: "relative",
                  borderTopLeftRadius: "24px",
                  borderTopRightRadius: "24px",
                }}
              >
                {props?.isEdit ? "Update Tracking Event" : "Add Tracking Event"}

                {editData?.outcomeId && (
                  <IconButton
                    onClick={() => {
                      setopenOutcomeDeletePopup(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                {
                  <IconButton
                    sx={{ position: "absolute", top: 5, right: 5 }}
                    // onClick={handleCloseStartModal}
                    onClick={
                      editData?.outcomeId &&
                        editData?.index === props.optionIndex &&
                        (outcomeNotes === editData?.note[0] ||
                          (!outcomeNotes && !editData?.note[0]))
                        ? handleCloseStartModal
                        : props.optionIndex !== "" || outcomeNotes !== ""
                          ? () => {
                            setopenOutcomeclosePopup(true);
                          }
                          : handleCloseStartModal
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15 15L10 10M10 10L5 5M10 10L15 5M10 10L5 15"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton>
                }
              </div>
            )}
            <DialogContent
              className="hide-scrollbar"
              sx={
                isMobile
                  ? {
                    position: "relative",
                  }
                  : {}
              }
            >
              {isMobile && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                >
                  {editData?.outcomeId && (
                    <IconButton
                      onClick={() => {
                        setopenOutcomeDeletePopup(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  padding: isMobile ? "0px" : "24px 32px 0px 32px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: isMobile ? "24px" : "16px",
                  justifyContent: "flex-start",
                  width: "100%",
                  alignSelf: "stretch",
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={
                    isMobile
                      ? {
                        color: palette.primary.light,
                        fontSize: `${pxToRem(20)} !important`,
                        lineHeight: "125% !important",
                        // textAlign: "center !important",
                        fontWeight: `500 !important`,
                        "&.MuiTypography-root": {
                          "@media  (max-width: 600px) and (min-width: 100px)":
                          {
                            fontSize: `${pxToRem(20)} !important`,
                            lineHeight: "125% !important",
                          },
                        },
                      }
                      : {
                        fontWeight: 600,
                        fontSize: `${pxToRem(24)} !important`,
                        lineHeight: "135%  !important",
                      }
                  }
                  className={
                    isMobile
                      ? classes.registrationHeadingMobile
                      : classes.registrationHeading
                  }
                >
                  {props?.item?.name || "Name"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: isMobile ? "24px 0px 0px 0px" : "32px 32px 0px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "32px",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                  }}
                >
                  {!isMobile && (
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.pageTitle}
                    >
                      Allocate the rating that best describes how you feel about
                      this influence today
                    </Typography>
                  )}
                  <ProgressStepperNew
                    index={props.index}
                    getBackgroudColor={props.getBackgroudColor}
                    setSelectedOption={props.setSelectedOption}
                    selectedOption={props.selectedOption}
                    setOptionIndex={props.setOptionIndex}
                    optionIndex={props.optionIndex}
                    setItem={setItem}
                    setDisplayBorder={props.setDisplayBorder}
                    displayBorder={props.displayBorder}
                    editData={editData}
                    absenceToggle={props?.item?.absenceToggle}
                    journeyData={props?.journeyData}
                    isMobile={props.isMobile}
                  />
                  <div
                    style={
                      isMobile
                        ? { width: "100%", marginTop: "24px" }
                        : { width: "100%", marginTop: "32px" }
                    }
                  >
                    <Typography
                      variant="caption"
                      component="p"
                      className={classes.pageTitle}
                      sx={
                        isMobile
                          ? {
                            color: palette.primary.light,
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "100% !important",
                            // textAlign: "center !important",
                            fontWeight: `500 !important`,
                            "&.MuiTypography-root": {
                              "@media  (max-width: 600px) and (min-width: 100px)":
                              {
                                fontSize: `${pxToRem(16)} !important`,
                                lineHeight: "100% !important",
                              },
                            },
                          }
                          : {}
                      }
                    >
                      {isMobile ? "Note for today’s entry" : "Add note"}
                    </Typography>
                    <TextareaAutosize
                      // variant="outlined"
                      className={"textarea " + classes.textarea}
                      // sx={{
                      //   backgroundColor: `#fff`,
                      //   border: `1px solid #e3e3e3 !important`,
                      //   borderRadius: `10px`,
                      //   marginBottom: "40px !important"
                      // }}
                      onFocus={() => {
                        setTextAreaFocused(true);
                      }}
                      onBlur={() => {
                        setTextAreaFocused(false);
                      }}
                      // fullWidth
                      placeholder={
                        /* isMobile ? "What’s worth remembering today?": */ "Add a note..."
                      }
                      // multiline
                      minRows={1}
                      onChange={(e) => setOutcomeNotes(e.target.value)}
                      value={outcomeNotes}
                    />
                  </div>
                </div>
              </div>
              <Box
                className={classes.bottomButton}
                sx={
                  isMobile
                    ? {
                      padding: "0px 0px 0px !important",
                      borderTop: "none",
                    }
                    : {}
                }
              >
                <NewButton
                  buttonText="Cancel"
                  variant="text"
                  textColor="var(--button-active-text)"
                  onClick={
                    editData?.outcomeId &&
                      editData?.index === props.optionIndex &&
                      (outcomeNotes === editData?.note[0] ||
                        (!outcomeNotes && !editData?.note[0]))
                      ? handleCloseStartModal
                      : props.optionIndex !== "" || outcomeNotes !== ""
                        ? () => {
                          setopenOutcomeclosePopup(true);
                        }
                        : handleCloseStartModal
                  }
                />
                <NewButton
                  buttonText={
                    props?.isEdit ? (
                      <span>
                        <span style={{ textDecoration: "underline" }}>U</span>
                        pdate
                      </span>
                    ) : (
                      <span>
                        {!(
                          props.optionIndex !== "" &&
                          Number(props.optionIndex) >= 0
                        ) ? (
                          <>S<span style={{ textDecoration: "underline" }}>k</span>ip</>
                        ) : <><span style={{ textDecoration: "underline" }}>A</span>dd</>}

                      </span>
                    )
                  }
                  isDisabled={props?.isEdit ? !(
                    props.optionIndex !== "" &&
                    Number(props.optionIndex) >= 0
                  ) : false}
                  variant="contained"
                  onClick={trackingOutcomeCreate}
                  type="submit"
                  isLoadong={showLoadingBtn}
                />
              </Box>
            </DialogContent>

            <OutcomeclosePopup
              open={openOutcomeDeletePopup}
              isDelete={openOutcomeDeletePopup}
              setopenOutcomeclosePopup={setopenOutcomeDeletePopup}
              handleCloseStartModal={handleDeleteEntry}
              setBackbuttonPress={setBackbuttonPress}
            />
            <OutcomeclosePopup
              open={openOutcomeclosePopup}
              setopenOutcomeclosePopup={setopenOutcomeclosePopup}
              handleCloseStartModal={handleCloseStartModal}
              setBackbuttonPress={setBackbuttonPress}
            />
          </Dialog>
        </form>
      </Grid>
    );
  } else {
    return null;
  }
};
export default AddTrackingEventModal;
