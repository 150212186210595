import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");
const updateKeyRescueTime = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.RESCUE_TIME.UPDATE_RESCUE_TIME_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getKeyRescueTime = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.RESCUE_TIME.GET_RESCUE_TIME_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const disconnectRescueTime = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.RESCUE_TIME.DISCONNECT}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getDataRescueData = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.RESCUE_TIME.GET_RESCUE_TIME_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const updateRescueData = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.RESCUE_TIME.CREATE_RESCUE_TIME_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const disconnectToggl = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.TOGGL.DISCONNECT}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const updateKeyToogl = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.TOGGL.UPDATE_TOGGL_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getKeyToggl = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.TOGGL.GET_TOGGL_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getDataToggl = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.TOGGL.GET_TOGGL_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const updateTogglData = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.TOGGL.CREATE_TOGGL_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

// ------- OURA -----------------
const updateKeyOURA = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.OURA.UPDATE_OURA_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getKeyOURA = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.OURA.GET_OURA_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const disconnectOURA = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.OURA.DISCONNECT}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const getDataOURA = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.OURA.GET_OURA_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const updateOURAData = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.OURA.CREATE_OURA_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const garminRequestToken = async (token: string | undefined) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.GET_REQUEST_TOKEN}`, {}, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
}


const getKeyGarmin = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.GET_GARMIN_KEY}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};

const getGarminAccessToken = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.ACCESS_TOKEN}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const getDataGarmin = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.GET_GARMIN_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const updateGarminData = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.UPDATE_GARMIN_DATA}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
const disconnectGarmin = async (body: any, token) => {
    const { data } = await axios
        .post(`${baseEndpoints.INTEGRATINS.GARMIN.DISCONNECT}`, body, {
            headers: {
                "x-access-token": token ? token : headerToken,
            },
        })
        .then((response) => {
            return response;
        });
    return data;
};
export const integrationsService = {
    updateKeyRescueTime,
    getKeyRescueTime,
    getDataRescueData,
    updateRescueData,
    disconnectRescueTime,
    // ------- TOGGL ----------------
    updateKeyToogl,
    getKeyToggl,
    getDataToggl,
    updateTogglData,
    disconnectToggl,
    // ------- OURA -----------------
    updateOURAData,
    getDataOURA,
    getKeyOURA,
    updateKeyOURA,
    disconnectOURA,
    // ---------- GARMIN ----------
    garminRequestToken,
    getKeyGarmin,
    getGarminAccessToken,
    getDataGarmin,
    updateGarminData,
    disconnectGarmin
}