import React from "react"

const RescueTime = ({ size = 44 }) => {
    return <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_38_914)">
            <mask id="mask0_38_914" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6557 10.2077H25.6744V2.21901C25.6744 1.32974 24.9494 0.617584 24.0749 0.617584H11.2654C10.3761 0.617584 9.66578 1.3445 9.66578 2.21901V10.2077H1.68271C0.793436 10.2077 0.0831299 10.9346 0.0831299 11.809V24.6297C0.0831299 25.5189 0.808196 26.2311 1.68271 26.2311H9.66393V34.2197C9.66393 35.109 10.389 35.8211 11.2636 35.8211H24.0731C24.9624 35.8211 25.6726 35.0943 25.6726 34.2197V26.2311H33.6539C34.5432 26.2311 35.2535 25.5041 35.2535 24.6297V11.809C35.2535 10.9198 34.5284 10.2077 33.6557 10.2077Z" fill="white" />
            </mask>
            <g mask="url(#mask0_38_914)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00939941 35.9226H35.2535V0.499481H0.00939941V35.9226Z" fill="url(#paint0_linear_38_914)" />
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6557 10.1929H25.6744V2.20423C25.6744 1.31497 24.9494 0.602814 24.0749 0.602814H11.2654C10.3761 0.602814 9.66578 1.32973 9.66578 2.20423V10.1929H1.68271C0.793436 10.1929 0.0831299 10.9198 0.0831299 11.7943V24.6149C0.0831299 25.5042 0.808196 26.2164 1.68271 26.2164H9.66393V34.2049C9.66393 35.0942 10.389 35.8064 11.2636 35.8064H24.0731C24.9624 35.8064 25.6726 35.0795 25.6726 34.2049V26.2164H33.6539C34.5432 26.2164 35.2535 25.4894 35.2535 24.6149V11.7943C35.2535 10.9198 34.5284 10.1929 33.6557 10.1929ZM33.6705 23.7108C33.6705 24.1997 33.2702 24.6149 32.7665 24.6149H24.0288V33.36C24.0288 33.8489 23.6284 34.264 23.1248 34.264H12.1529C11.6639 34.264 11.2488 33.8636 11.2488 33.36V24.6149H2.51293C2.02402 24.6149 1.60891 24.2146 1.60891 23.7108V12.7131C1.60891 12.2242 2.00926 11.8091 2.51293 11.8091H11.2507V3.06398C11.2507 2.57507 11.651 2.15995 12.1547 2.15995H23.1432C23.6321 2.15995 24.0472 2.56031 24.0472 3.06398V11.8091H32.785C33.2739 11.8091 33.6889 12.2095 33.6889 12.7131V23.7108H33.6705Z" fill="#DA3F1F" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.383 18.152L27.1691 15.7369L27.658 17.2627L20.4904 16.759C20.7117 17.2037 20.8464 17.6926 20.8464 18.211C20.8464 18.7146 20.7283 19.1888 20.5346 19.6039L27.6728 19.0708L27.1986 20.567L30.383 18.152Z" fill="#161A3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2468 18.0929V18.019L18.5513 6.47333L20.0475 6.94748L17.6325 3.74649L15.2192 6.96224L16.745 6.47333L16.079 18.0338V18.0633V18.1814V18.1961C16.079 19.0707 16.7893 19.7976 17.6786 19.7976C18.5531 19.7976 19.2781 19.0854 19.2781 18.1961V18.1814L19.2468 18.0929Z" fill="#161A3B" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_38_914" x1="7.57613" y1="8.1484" x2="29.9903" y2="30.3548" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" />
                <stop offset="1" stop-color="#BBC7D1" />
            </linearGradient>
            <clipPath id="clip0_38_914">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>

}

export default RescueTime