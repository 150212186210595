import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import typography, { pxToRem } from "../../theme/typography";

const useStyles = makeStyles((theme) => ({
  trackBodyGrid: {
    display: "flex",
    backgroundColor: "white",
    zIndex: 1,
    flexShrink: 0,
    height: "100vh",
    overflow: "auto",
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  drawerTitle: {
    display: "flex",
    padding: "20px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--border-color)",
    background: "var(--white-background)",
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--border-color)",
    background: "var(--white-background)",
    justifyContent: "space-between",
  },
  categoryDialogAdd: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "501px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogActionAdd: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  // limit reach modal
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "24px",
        padding: "0",
        display: "flex",
        // width: '400px',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    gap: "12px",
    alignSelf: "stretch !important",
    padding: "32px !important",
    borderTop: `1px solid var(--border-color)`,
  },

  // tutorial modal
  commentBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
  },
  contentBoxMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    alignSelf: "stretch",
  },
  // // ADD CATEGIRy MODAL
  // categoryDialog: {
  //     "& .MuiDialog-container": {
  //       "& .MuiPaper-root": {
  //         borderRadius: "20px",
  //         padding: "48px 40px",
  //         width: "501px",

  //         "& .MuiDialogContent-root": {
  //           padding: "0",
  //           overflowY: "unset",
  //         },

  //         "&. MuiDialogActions-root": {},
  //       },
  //     },
  //   },
  //   categoryDialogAction: {
  //     display: "flex !important",
  //     alignItems: "center !important",
  //     justifyContent: "start !important",
  //   },
  trackBody: {
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
    overflowY: "hidden",
    background: "var(--white-background)",
    // borderRadius: "20px 20px 0px 0px",
  },
  tableClass: {
    display: "block",
    width: "100%",
    height: "calc(100vh - 110px)",
    overflow: "auto",
  },
  header: {
    display: "table-cell",
    color: palette.common.black,
  },
  headerSwitch: {
    color: palette.common.black,
    minWidth: "30vw",
    maxWidth: "30vw",
  },
  headerDate: {
    width: "100%",
    color: palette.common.black,
    textAlign: "left",
  },
  row: {},
  cell: {
    display: "table-cell",
    height: "65px",
    color: palette.common.black,
    // borderBottom: "1px solid var(--border-color)"
  },

  //calendar
  iconButtonCalender: {
    // color: `${palette.primary.light}!important`,
    // fontSize: "26px!important",
    // lineHeight: "26px!important",
    // fontWeight: 600,
    // border: `1px solid var(--border-color)!important`,
    // borderRadius: "4px!important",
    // fontFamily: typography.fontFamily,
    // visibility: "hidden",
    display: "flex!important",
    alignItems: "center!important",
    justifyContent: "center!important",
    gap: "10px!important",
    borderRadius: "100%!important",
    border: `none !important`,
    padding: "6px !important",
    "&.Mui-disabled": {
      border: `none !important`,
      cursor: "not-allowed",
      pointerEvents: "visible",
    },
  },
  monthTitle: {
    color: "var(--color-black)",
    marginBottom: "0px!important",
    fontSize: "1.5rem!important",
    fontWeight: 700,
    letterSpacing: "0.02em",
    lineHeight: "100%!important",
    padding: "0 10px",
  },
  monthControls: {
    display: "flex",
    alignItems: "flex-start",
    gap: "12px",
  },
  headerBlocksExpand: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8px 0",
    height: "56px",
    width: "100%",
    // marginBottom: "11px",
    "& p": {
      height: "15px",
      width: "30px",
      textAlign: "center",
      margin: "0",
      opacity: "1",
    },
    "& span": {
      padding: "0px 5px",
    },
  },
  headerBlocks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "8px 8px",
    height: "56px",
    width: "100%",
    minWidth: "48px",
    gap: "8px",
    "& p": {
      margin: "0",
    },
    "&:before": {
      content: '""',
      display: "flex",
      position: "absolute",
    },
  },
  background: {
    backgroundColor: "transparent",
    borderRadius: "8px",
    border: `1px solid var(--color-svg)`,
    color: "var(--sidebar-active-color)",
    "& p": {
      color: "var(--sidebar-active-color) !important",
      fontWeight: 500,
      opacity: "1",
    },
    "& span": {
      color: "var(--sidebar-active-color) !important",
      fontWeight: 500,
    },
  },
  commonBackground: {
    backgroundColor: "white",
  },
  weekday: {
    color: "var(--weekdays)  !important",
    fontSize: "10px!important",
    lineHeight: "100%!important",
    fontWeight: 400,
    opacity: "0.4",
  },
  listTitle: {
    fontSize: "22px!important",
    lineHeight: "22px!important",
  },
  boxPlan: {
    display: "flex",
    padding: "4px 8px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "6px",
    background: "transparent",
    border: "2px solid var(--color-svg)"
  },
  dropMenu: {
    "& .MuiPaper-root": {
      border: "1px solid #DCE6E0",
      overflow: "visible",
      marginTop: "4px",
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: "42%",
        width: 15,
        height: 15,
        backgroundColor: "#f7f9fa",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 1,
        borderTop: "1px solid #DCE6E0",
        borderLeft: "1px solid #DCE6E0",
      },
    },
  },
  stickeyClass: {
    position: "sticky",
    top: "0",
    left: "0",
    zIndex: 5,
    background: "var(--white-background)",
  },
  stickeyClassDate: {
    position: "sticky",
    top: "0",
    left: "460px",
    zIndex: 3,
    background: "var(--white-background)",
  },
  stickyCell: {
    position: "sticky",
    top: "85px",
    zIndex: 3,
    background: "var(--white-background)",
  },
  stickyLeftCell: {
    position: "sticky",
    left: "0px",
    // top: "92px",
    zIndex: 4,
    padding: "0px 24px",
    background: "var(--white-background)",
    boxShadow: "50px 0px 48px -15px rgba(18, 18, 19, 0.05)",
  },
  iconExpand: {
    height: "auto",
    position: "relative",
    padding: "14px 0",
    verticalAlign: "top",
    "&:after": {
      width: "100%",
    },
  },
  iconInnerWithoutAfter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "35px",
    flexBasis: "100%",
  },
  iconInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
    height: "35px",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "flex",
      left: "28px",
      border: "1px dashed #E7E7E7",
      width: "100%",
      alignItems: "center",
      zIndex: 0,
      justifyContent: "center",
    },
  },
  icon: {
    position: "relative",
    borderBottom: "1px solid var(--border-color)",
    padding: "14px 0",
    height: "65px",
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  addOutlineIcon: {
    zIndex: 1,
    "& span": {
      color: "rgb(227, 227, 227)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: typography.fontFamily,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "28.5px",
      height: "28.5px",
      border: "1px solid rgb(227, 227, 227)",
      borderRadius: "50%",
      backgroundColor: palette.common.white,
    },
  },
  addOutlineIconInput: {
    border: "1px solid rgb(227, 227, 227)",
    borderRadius: "50%",
    backgroundColor: palette.common.white,
    textAlign: "center",
    width: "28px",
    height: "28px",
    "& input": {
      width: "18px",
      height: "19px",
      fontFamily: typography.fontFamily,
    },
  },
  borderOutline: {
    width: "34px!important",
    height: "34px!important",
  },
  clock: {
    // position: "absolute",
    margin: "auto",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    // height: "20px",
    // width: "20px",
    fontSize: "8px",
    color: "var(--color-black)"
  },
  hand: {
    position: "absolute",
    width: "3px",
    margin: "auto",
    left: "0",
    bottom: "0",
    right: "0",
    backgroundColor: "var(--white-background)",
    transformOrigin: "bottom",
    borderRadius: "4px",
  },
  minute: {
    height: "11px",
    top: "-45%",
  },
  hour: {
    height: "8px",
    top: "-30%",
  },
  borderBottom: {
    borderBottom: "1px solid var(--border-color)",
  },
  mainDivClasExpand: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-start",
    height: "103px",
    background: "var(--white-background)",
    flexBasis: "100%",
  },
  mainDivClas: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  fullTexttextClass: {
    color: "var(--black-color) !important",
    overflow: "hidden",
    display: "-webkit-box",
    lineClamp: 8,
    boxOrient: "vertical",
    textOverflow: "ellipsis",
    margin: "20px 0px !important",
    fontSize: `${pxToRem(16)} !important`,
    lineHeight: "125% !important",
  },
  textClass: {
    color: "var(--black-color) !important",
    overflow: "hidden",
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
    textOverflow: "ellipsis",
    margin: "20px 0px !important",
    wordBreak: "break-all",
    fontSize: `${pxToRem(16)} !important`,
    lineHeight: "125% !important",
  },
  innerDiVClass: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
    height: "100%",
  },
  notesItem: {
    margin: "0px",
    padding: "0px",
    // marginLeft: '5px',
    listStyleType: "none",
    // paddingLeft: "0",
    // maxHeight: "50px",
    // overflowY: "auto",
    // maxWidth: "170px",
    listStylePosition: "inside",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& li": {
      color: palette.primary.light,
      fontSize: "12px",
      lineHeight: "15px",
      position: "relative",
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'flex-start',
      margin: "3px 0",
      "&:before": {
        content: "''",
        backgroundColor: palette.primary.main,
        width: "4px",
        height: "4px",
        display: "inline-block",
        marginRight: "5px",
        marginBottom: "2px",
        borderRadius: "50%",
        //     alignSelf: 'flex-start',
        // marginTop: '5px',
      },
      "&::::marker": {
        color: palette.primary.main,
      },
    },
  },
}));

export default useStyles;
