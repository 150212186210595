/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";
import { ButtonLoader } from "../../components/buttonLoader";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import InfluenceclosePopup from "./InfluenceclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
import { AddUpdateEventModal } from "./addTrackingEventInfluenceModal";
import typography, { pxToRem } from "../../theme/typography";

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  pageTitle: {
    marginBottom: "16px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "40px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: '0px !important'
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    resize: "vertical",
    "&:focus-visible": {
      outline: "red"
    }
  }
}));

interface AddHours {
  setOpenHoursModal: (openHoursModal: boolean) => void;
  item: any;
  isEdit: Boolean;
  setIsEdit: (isEdit: boolean) => void;
  journeyData: any;
  headerDate: any;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  callService: any;
  setInfluenceId: (influenceId: string) => void;
  callToGetData?: boolean
  isMobile?: boolean
  renderToNextInput?: () => void
}
const ariaLabel = { "aria-label": "description" };
const AddHoursModal = (props: AddHours) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [inputScore, setInputScore] = useState("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [notes, setNotes] = useState("");
  const [editData, setEditData] = useState<any>();
  const [item, setItem] = useState<any>();
  const [openInfluenceclosePopup, setopenInfluenceclosePopup] = useState(false);
  const [openInfluenceDeletePopup, setopenInfluenceDeletePopup] = useState(false);
  const { isMobile = false } = props
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputScore, notes, isBackButtonClicked]);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      if (editData?.influenceId && editData?.score === inputScore && (notes === editData?.note[0] || (!notes && !editData?.note[0]))) {
        handleCloseStartModal()
      } else if (inputScore !== "" || notes !== "") {
        setopenInfluenceclosePopup(true)
      } else {
        handleCloseStartModal()
      }

    }
  }
  useEffect(() => {
    const outcomeGetData = {
      influenceId: props.item?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .getInfluenceRes(outcomeState.token, outcomeGetData)
      .then((data) => {
        setEditData(data?.data);
        if (data?.data == null) {
          props?.setIsEdit(false);
        } else {
          props?.setIsEdit(true);
        }
        setNotes(data?.data?.note[0] ?? "");
        setInputScore(data?.data?.score ?? "");
        setItem(data?.data);
      })
      .catch((error) => {
        console.log("outcome response get error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey
    console.log("keyPress",keyPress && [75].includes(e.keyCode) && !(inputScore !== "" && Number(inputScore) >= 0));
    
    if ((keyPress && (([65, 85].includes(e.keyCode))))) {
      createInfluenceUnit()
    } else if(keyPress && [75].includes(e.keyCode) && !(inputScore !== "" && Number(inputScore) >= 0)) {
      props.setOpenHoursModal(false);
      props.renderToNextInput && !isMobile && props.renderToNextInput()
      setShowLoadingBtn(false);
      setStartModal(false)
      props.setShowSnakbar(false);
      props.setInfluenceId("");
    }
  }
  const createInfluenceUnit = () => {
    if (inputScore !== "" && Number(inputScore) >= 0) {
      setShowLoadingBtn(true);
      let outcomeCreateData;
      outcomeCreateData = {
        influenceId: props.item?.influenceId,
        score: inputScore,
        note: notes === undefined || notes === "" ? [] : [notes],
        date: props?.headerDate,
        type: "TYPE12",
      };

      trackingService
        .influenceCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          if (!outcomeState?.isPersonal) {
            const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
            if (foundOutcome) {
              const journyList = { ...(outcomeState?.journeyList || {}) } as any
              const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
              if (foundDateItemIcon > -1) {
                journyList.influence_data[foundDateItemIcon] = {
                  ...foundOutcome,
                  ...outcomeCreateData,
                }
              } else {
                journyList.influence_data.push({
                  ...foundOutcome,
                  ...outcomeCreateData,
                } as any)
              }
              UserTypeDispatch(addJourneyAction(journyList as any))
              if (props.callToGetData) {
                props?.callService(false);
              }
            }
          } else {
            const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
            const foundOutcome = (journeyCategoryList
              || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
            if (foundOutcome > -1) {
              if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
                const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
                if (foundIndex > -1) {
                  if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                    const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                        ...outcomeCreateData
                      }
                    } else {
                      journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                  }
                  UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                }
              }
            } else {
              const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
              if (foundOther > -1) {
                const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
                if (outcomeFound > -1) {
                  if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                    const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                        ...outcomeCreateData
                      }
                    } else {
                      journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                    }
                  } else {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                  }
                  UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                }
              }
            }
          }
          props.renderToNextInput && !isMobile && props.renderToNextInput()
          setShowLoadingBtn(false);
          props.setShowSnakbar(false);
          props.setSnakbarMsg(data?.message);
          props.setOpenHoursModal(false);
          // props?.callService(false);
          props.setMsgType("success");
        })
        .catch((error) => {
          setShowLoadingBtn(false);
          props.setShowSnakbar(true);
          props.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error?.message
          );
          props.setMsgType("error");
        });
    } else {
      props.renderToNextInput && !isMobile && props.renderToNextInput()
      setShowLoadingBtn(false);
      setStartModal(false)
      props.setShowSnakbar(false);
      props.setOpenHoursModal(false);
    }

  };

  const handleDeleteEntry = () => {
    let payload = {
      influenceId: editData?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .deleteInfluenceRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
            if (foundDateItemIcon > -1) {
              journyList.influence_data.splice(foundDateItemIcon, 1)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
            if (props.callToGetData) {
              props?.callService(false);
            }
          }
        } else {
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(payload.influenceId));
          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === payload.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === payload.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          }
        }
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(data?.message);
        props.setOpenHoursModal(false);
        // props?.callService(false);
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };

  const handleCloseStartModal = async () => {
    setStartModal(false);
    await props.setOpenHoursModal(false);
    editData?.influenceId && props.setInfluenceId("");
  };
  console.log("props?.item?.unitValue", props?.item?.unitValue);
  let unitName = props?.item?.unitValue
  if(props?.item?.unitValue === "SCALE_NOT_DEFINED") {
    unitName = "define unit"
  }
  console.log("props?.headerDate", props?.headerDate);
  
  return (
    <Grid>
      <form onKeyDown={onKeyDownHandler}>
        <AddUpdateEventModal
          onClose={
            editData?.influenceId && editData?.score === inputScore && (notes === editData?.note[0] || (!notes && !editData?.note[0])) ? handleCloseStartModal :
              inputScore !== "" || notes !== ""
                ? () => {
                  setopenInfluenceclosePopup(true);
                }
                : handleCloseStartModal}
          onSave={createInfluenceUnit}
          showTitle={!props.isMobile}
          paperProps={isMobile ? {
            height: "calc(100vh - 50%)"
          } : {}}
          isMobile={props.isMobile}
          title={!isMobile && <>
            {props?.isEdit ? "Update Tracking Event" + props?.headerDate : "Add Tracking Event" + props?.headerDate}
            {editData?.influenceId &&
              <IconButton
                onClick={() => {
                  setopenInfluenceDeletePopup(true);
                }}
              >
                <DeleteIcon />
              </IconButton>}
          </>}
          showLoadingBtn={showLoadingBtn}
          visible={openStartModal}
          buttonText={props?.isEdit ? <span><span style={{ textDecoration: "underline" }}>U</span>pdate</span> : inputScore === undefined || inputScore === "" ? <span>S<span style={{ textDecoration: "underline" }}>k</span>ip</span> :<span><span style={{ textDecoration: "underline" }}>A</span>dd</span>}
          saveDisabled={props?.isEdit ? inputScore === undefined || inputScore === "" : false}
        >
          {isMobile && editData?.influenceId && <div
            style={{
              position: "absolute",
              right: 0,
              top: 0
            }}
          >
            <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton></div>}
          <div
            style={{
              display: 'flex',
              padding: props.isMobile ? "0px" : '24px 32px 0px 32px',
              flexDirection: 'column',
              alignItems: props.isMobile ? 'flex-start' : 'center',
              gap: props.isMobile ? '32px' : '16px',
              justifyContent: "center",
              width: "100%",
              alignSelf: 'stretch',

            }}>
            <Typography
              variant="body1"
              component="div"
              sx={isMobile ? {
                color: palette.primary.light,
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "125% !important",
                // textAlign: "center !important",
                fontWeight: `500 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "125% !important"
                  },
                }
              } : { fontWeight: 600, fontSize: `${pxToRem(24)} !important`, lineHeight: "135%" }}
              className={classes.registrationHeading}
            >
              {props?.item?.name || "Name"}
            </Typography>
            <div style={{
              display: 'flex',
              padding: isMobile ? '0px 0px 0px 0px' : '32px 32px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              alignSelf: 'stretch',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}>
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                  sx={isMobile ? {
                    color: palette.primary.light,
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                    // textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "100% !important"
                      },
                    }
                  } : {}}
                >
                  Value
                </Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: "flex-start",
                  alignSelf: 'stretch',
                  gap: "8px"
                }}>
                  <TextField
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      padding: '18px',
                      alignItems: 'center',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRadius: '12px',
                      border: '1px solid #EDEDED',
                      background: '#FFF',
                      marginBottom: `0 !important`,
                      '& .MuiInputBase-root': {
                        width: "100%",
                        '& .MuiInputBase-input': {
                          padding: `0px !important`
                        }
                      },
                    }}
                    size={"small"}
                    fullWidth
                    autoFocus
                    inputRef={(input) => {
                      if (input && !isFocused) {
                        input.focus()
                        setTimeout(() => {
                          setIsFocused(true)

                        }, 500);
                      }
                      // input && input.focus()
                    }}
                    placeholder={"Add " + (unitName || "") + " value"}
                    onChange={(e) => setInputScore(e.target.value)}
                    value={inputScore || ""}
                  />
                  {unitName && !isMobile && (
                    // <TextField
                    //   variant="outlined"
                    //   sx={{
                    //     display: 'flex',
                    //     padding: '18px',
                    //     alignItems: 'center',
                    //     gap: '12px',
                    //     flex: '0 0 0.6',
                    //     borderRadius: '12px',
                    //     border: '1px solid #EDEDED',
                    //     background: '#FFF',
                    //     marginBottom: `0 !important`,
                    //     '& .MuiInputBase-root': {
                    //       width: "100%",
                    //       '& .MuiInputBase-input': {
                    //         padding: `0px !important`
                    //       }
                    //     },
                    //   }}
                    //   size={"small"}
                    //   fullWidth
                    //   // placeholder={"Add " + props?.item?.unitValue + " value"}
                    //   // onChange={(e) => setInputScore(e.target.value)}
                    //   value={props?.item?.unitValue}
                    // />
                    <Box sx={{
                      display: 'flex',
                      padding: '16px 20px',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '12px',
                      border: '1px solid #EDEDED',
                      background: '#FFF',
                    }}>
                      <Typography sx={{
                        color: '#000',
                        fontSize: `${pxToRem(16)}!important`,
                        fontWeight: 400,
                        lineHeight: '100%', /* 16px */
                      }}>{unitName}</Typography>
                    </Box>
                  )}
                </Box>

                <div style={isMobile ? { width: "100%", marginTop: "16px", display: "flex", flexDirection: "column", gap: "16px" } : { width: "100%", marginTop: "32px" }}>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.pageTitle}
                    sx={isMobile ? {
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important"
                        },
                      }
                    } : {}}
                  >
                    {isMobile ? "Note for today’s entry" : "Add a note for today’s entry"}
                  </Typography>
                  <TextareaAutosize
                    // variant="outlined"
                    className={"textarea " + classes.textarea}
                    // sx={{
                    //   backgroundColor: `#fff`,
                    //   border: `1px solid #e3e3e3 !important`,
                    //   borderRadius: `10px`,
                    //   marginBottom: "40px !important"
                    // }}
                    onFocus={() => {
                      // setTextAreaFocused(true)
                    }}
                    onBlur={() => {
                      // setTextAreaFocused(false)
                    }}
                    // fullWidth
                    placeholder={/* isMobile ? "What’s worth remembering today?": */"Add a note..."}
                    // multiline
                    minRows={1}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes || ""}
                  />
                </div>
              </div>
            </div>
          </div>

        </AddUpdateEventModal>
      </form>
      <InfluenceclosePopup
        open={openInfluenceDeletePopup}
        isDelete={openInfluenceDeletePopup}
        setopenInfluenceclosePopup={setopenInfluenceDeletePopup}
        handleCloseStartModal={handleDeleteEntry}
      />
      <InfluenceclosePopup
        open={openInfluenceclosePopup}
        setopenInfluenceclosePopup={setopenInfluenceclosePopup}
        handleCloseStartModal={handleCloseStartModal}
      />
      <Dialog
        onClose={
          inputScore !== "" || notes !== ""
            ? () => {
              setopenInfluenceclosePopup(true);
            }
            : handleCloseStartModal
        }
        open={false}
        className="dialogWrapper dialogWrapper-addHoursModal"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle} sx={{ fontWeight: 700 }}>
          Add Tracking Event
          {editData?.influenceId &&
            <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton>}
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={
              inputScore !== "" || notes !== ""
                ? () => {
                  setopenInfluenceclosePopup(true);
                }
                : handleCloseStartModal
            }
          >
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        <div
          style={{ fontSize: "20px", fontWeight: 600, marginBottom: "30px" }}
        >
          {props?.item?.name}
        </div>
        <div style={{ fontSize: "16px", fontWeight: 500, marginBottom: "5px" }}>
          Value
        </div>
        <DialogContent>
          <InfluenceclosePopup
            open={openInfluenceDeletePopup}
            isDelete={openInfluenceDeletePopup}
            setopenInfluenceclosePopup={setopenInfluenceDeletePopup}
            handleCloseStartModal={handleDeleteEntry}
            setBackbuttonPress={setBackbuttonPress}

          />
          <InfluenceclosePopup
            open={openInfluenceclosePopup}
            setopenInfluenceclosePopup={setopenInfluenceclosePopup}
            handleCloseStartModal={handleCloseStartModal}
            setBackbuttonPress={setBackbuttonPress}
          />
          <form>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                className="fillTextInput add-hours"
                placeholder={"Add " + props?.item?.unitValue + " value"}
                inputProps={ariaLabel}
                onChange={(e) => setInputScore(e.target.value)}
                value={inputScore || ""}
              />
              <div
                style={{ fontSize: "16px", fontWeight: 500, marginBottom: "5px" }}
              >
                Add a note for today’s entry
              </div>
              <TextField
                variant="outlined"
                className="textarea"
                fullWidth
                placeholder="Add a note..."
                multiline
                minRows={4}
                onChange={(e) => setNotes(e.target.value)}
                value={notes || ""}
              />

              <Box className={classes.bottomButton}>
                {!showLoadingBtn ? (
                  <Button
                    disabled={inputScore === undefined || inputScore === ""}
                    onClick={createInfluenceUnit}
                    variant="contained"
                    type="submit"
                    className="button-after tracking-button"
                  >
                    {props?.isEdit ? "Update" : "Add"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="button-after tracking-button"
                  >
                    <ButtonLoader />
                  </Button>
                )}
                <Button
                  variant="outlined"
                  className="button-after tracking-button"
                  // onClick={handleCloseStartModal}
                  onClick={
                    inputScore !== "" || notes !== ""
                      ? () => {
                        setopenInfluenceclosePopup(true);
                      }
                      : handleCloseStartModal
                  }
                  sx={{ color: palette.primary.light }}
                >
                  Cancel
                </Button>
              </Box>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default AddHoursModal;
