import React, { useContext, useState } from "react"
import useStyles from "./styles";
import { UserTypeContext } from "../../context/userStatus";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import { setDayCompleteMessage } from "../../store/actions/trackerAction";
import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
import palette from "../../theme/palette";
import { Box, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx"
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from "../../assets/images/icon/EditIcon";
import IconYesNo2 from "./IconYesNo2";
import CommonTypo from "../../components/CommonTypo";
const DetailCard = (props) => {
    let noData = false
    const classes = useStyles();
    const [inputValue, setInputValue] = useState("");
    const [isInFullView, setIsInFullView] = useState(false);
    const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
    const [showInput, setShowInput] = useState(false);
    const [visibleYesnotSwitch, setVisibleSwitch] = useState(false);
    const [visibleFullStepper, setVisibleFullStepper] = useState(false);
    const { trackingDispatch } = props
    const clickCondition = moment(props.betweenDate).isBetween(
        moment().subtract(8, "year").format("yyyy-MM-DD"),
        moment().add(1, "day").format("yyyy-MM-DD")
    );

    const handleBlurInput = (id: any, index: any, date: any, e: any, mouseOut: boolean) => {
        if (mouseOut) {
            setShowInput(false);
            setNoData(true);
        }
        if (
            e.key === "Enter" ||
            e.keyCode == "37" ||
            e.keyCode == "38" ||
            e.keyCode == "39" ||
            e.keyCode == "40" ||
            mouseOut
        ) {
            if (props?.item?.absenceToggle) {
                if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
                    setShowInput(false);
                    props?.outcomeCreate(inputValue, props?.headerDate);
                } else {
                    props?.setShowSnakbar(true);
                    props?.setMsgType("error");
                    props?.setSnakbarMsg("Please enter value between 0 to 5");
                }
            } else {
                if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
                    setShowInput(false);
                    props?.outcomeCreate(inputValue, props?.headerDate);
                } else {
                    props?.setShowSnakbar(true);
                    props?.setMsgType("error");
                    props?.setSnakbarMsg("Please enter value between 1 to 5");
                }
            }


            // down arrow key
            if (e.keyCode == "40" && index <= outcomeState?.outcomeList?.length - 1) {
                let nextId = "";
                let nextItem;
                for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
                    if (outcomeState?.outcomeList[i]._id === id) {
                        nextId = outcomeState?.outcomeList[i + 1]?._id;
                        nextItem = outcomeState?.outcomeList[i + 1];
                    }
                }
                props.setOutcomeId(nextId);
                props?.setItem(nextItem);
                setShowInput(true);
                setInputValue("");
            }

            // up arrow key
            if (e.keyCode == "38" && index <= outcomeState?.outcomeList?.length - 1) {
                let prevId = "";
                let prevItem;
                for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
                    if (outcomeState?.outcomeList[i]._id === id) {
                        prevId = outcomeState?.outcomeList[i - 1]?._id;
                        prevItem = outcomeState?.outcomeList[i - 1];
                    }
                }
                props.setOutcomeId(prevId);
                props?.setItem(prevItem);
                setShowInput(true);
                setInputValue("");
            }
            setNoData(true)
        }
    };

    const handleBlurInputInfluence = (type, e: any, mouseOut: boolean) => {
        if (mouseOut) {
            setShowInput(false);
            setNoData(true);
        }
        if (
            e.key === "Enter" ||
            e.keyCode === "37" ||
            e.keyCode === "38" ||
            e.keyCode === "39" ||
            e.keyCode === "40" ||
            mouseOut
        ) {
            console.log("type", type);

            if (type === "TYPE2" && inputValue) {
                if (props?.item?.absenceToggle) {
                    if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
                        setShowInput(false);
                        // setDisplayInput(true);
                        props?.influenceCreate(inputValue, props?.headerDate);
                    } else {
                        props?.setShowSnakbar(true);
                        props?.setMsgType("error");
                        props?.setSnakbarMsg("Please enter value between 0 to 5");
                    }
                } else {
                    if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
                        setShowInput(false);
                        // setDisplayInput(true);
                        props?.influenceCreate(inputValue, props?.headerDate);
                    } else {
                        props?.setShowSnakbar(true);
                        props?.setMsgType("error");
                        props?.setSnakbarMsg("Please enter value between 1 to 5");
                    }
                }

            } else if (type === "TYPE12") {
                console.log("inputValue", inputValue);

                if (inputValue !== "" && !Number.isNaN(Number(inputValue))) {
                    props?.unitCreate(inputValue, props?.headerDate);
                } else if (inputValue === "") { } else {
                    props?.setShowSnakbar(true);
                    props?.setMsgType("error");
                    props?.setSnakbarMsg("Please enter value in number");
                }

            } else {
                setShowInput(false);
                // setDisplayInput(true);
                inputValue && props?.unitCreate(inputValue, props?.headerDate);
            }
        }
    };
    const journeyDataOutcome: any = outcomeState?.journeyList?.outcome_data;
    const journeyDataInfluence: any = outcomeState?.journeyList?.influence_data;
    const journeyDataReflective: any = outcomeState?.journeyList?.reflective_data;
    const [NoData, setNoData] = useState(true);
    let foundData = false
    if (props?.item?.outcomeId && journeyDataOutcome && journeyDataOutcome.length) {
        foundData = journeyDataOutcome.find(journey => journey?.outcomeId === props?.item?.outcomeId && journey?.date === props.headerDate)
    }
    if (props?.item?.influenceId && journeyDataInfluence && journeyDataInfluence.length) {
        foundData = journeyDataInfluence.find(journey => journey?.influenceId === props?.item?.influenceId &&
            journey?.date === props.headerDate)
    }
    if (props?.item?.reflectiveId && journeyDataReflective && journeyDataReflective.length) {
        foundData = journeyDataReflective.find(journey => journey?.reflectiveId === props?.item?.reflectiveId &&
            journey?.date === props.headerDate)
    }
    const isToday = moment(props.headerDate, "mm-DD-yyyy").format("mm-DD-yyyy") === moment(new Date()).format("MM-DD-yyyy")
    const nearestValueOutcome = (val, influenceItem) => {
        let arr = [];

        if (influenceItem.outcomeId && influenceItem?.options?.length && influenceItem?.options[val - 1]) {
            return influenceItem.options[val - 1].colorCode
        }
        if (influenceItem?.options && influenceItem?.options.length) {
            arr = [...influenceItem?.options].map(it => it.value)
        }
        const returnVal = arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val
        if (influenceItem?.options && influenceItem?.options.length) {
            const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === returnVal)
            if (foundIndexOfValue > -1) {
                return influenceItem?.options[foundIndexOfValue]?.colorCode
            }
        }
        return false
    }
    const closest = (needle, haystack = []) => {
        if (haystack && haystack.length) {
            return haystack.reduce((a, b) => {
                let aDiff = Math.abs(a - needle);
                let bDiff = Math.abs(b - needle);

                if (aDiff == bDiff) {
                    return a > b ? a : b;
                } else {
                    return bDiff < aDiff ? b : a;
                }
            });
        }
        return -1

    }

    const nearestValue = (val, influenceItem) => {

        let arr = [];
        if (influenceItem?.options && influenceItem?.options.length) {
            arr = [...influenceItem?.options].map(it => it.value)
        }
        if (influenceItem.type === "TYPE2" && influenceItem.options.length && influenceItem.options[val - 1]) {
            return influenceItem.options[val - 1].colorCode
        }
        const returnVal = closest(val, arr)
        if (influenceItem?.options && influenceItem?.options.length && returnVal > -1) {
            const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === returnVal)
            if (foundIndexOfValue > -1) {
                return influenceItem?.options[foundIndexOfValue]?.colorCode
            }
        }
        return false

    }
    const createInfluence = (isEdit, answerId, score, outcomeNotes, optionIndex, type) => {
        let outcomeCreateData
        if (isEdit) {
            outcomeCreateData = {
                date: props?.headerDate,
                influenceId: props.item?.influenceId,
                answerId: answerId,
                score: score,
                index: optionIndex,
                note:
                    outcomeNotes,
                Type: type,
            };
        } else {
            outcomeCreateData = {
                date: props?.headerDate,
                influenceId: props.item?.influenceId,
                answerId: answerId,
                score: score,
                index: parseInt(optionIndex),
                note:
                    outcomeNotes,
                Type: type,
            };
        }
        const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
        if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
            if (foundDateItemIcon > -1) {
                journyList.influence_data[foundDateItemIcon] = {
                    ...foundOutcome,
                    ...outcomeCreateData,
                }
            } else {
                journyList.influence_data.push({
                    ...foundOutcome,
                    ...outcomeCreateData,
                } as any)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
            // if (props.callToGetData) {
            //   props?.callService();
            // }
        }
        trackingService
            .influenceCreate(outcomeState.token, outcomeCreateData).then((data) => {
                if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
                    trackingDispatch(setDayCompleteMessage({
                        open: data.showMessage,
                        message: data.message
                    }))
                }
                setIsInFullView(false)
                // props?.callService();
            })
    }
    const createOutcome = (isEdit, answerId, score, outcomeNotes, optionIndex) => {
        let outcomeCreateData;
        if (isEdit) {
            outcomeCreateData = {
                date: props?.headerDate,
                outcomeId: props.item?.outcomeId,
                answerId: answerId,
                score: score,
                index: optionIndex,
                note:
                    outcomeNotes,
            };
        } else {
            outcomeCreateData = {
                date: props?.headerDate,
                outcomeId: props.item?.outcomeId,
                answerId: answerId,
                score: score,
                index: parseInt(optionIndex),
                note:
                    outcomeNotes,
            };
        }
        if (!outcomeState?.isPersonal) {
            const foundOutcome = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
            if (foundOutcome) {
                const journyList = { ...(outcomeState?.journeyList || {}) } as any
                const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
                if (foundDateItemIcon > -1) {
                    journyList.outcome_data[foundDateItemIcon] = {
                        ...foundOutcome,
                        ...outcomeCreateData,
                    }
                } else {
                    journyList.outcome_data.push({
                        ...foundOutcome,
                        ...outcomeCreateData,
                    } as any)
                }
                UserTypeDispatch(addJourneyAction(journyList as any))
            }
        } else {
            const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
            const foundOutcome = (journeyCategoryList
                || []).findIndex(it => it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId));
            if (foundOutcome > -1) {

                const journyList = { ...(outcomeState?.journeyList || {}) } as any
                const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
                const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
                if (foundDateItemIcon > -1) {
                    journyList.outcome_data[foundDateItemIcon] = {
                        ...foundOutcomeNew,
                        ...outcomeCreateData,
                    }
                } else {
                    journyList.outcome_data.push({
                        ...foundOutcomeNew,
                        ...outcomeCreateData,
                    } as any)
                }
                UserTypeDispatch(addJourneyAction(journyList as any))
            } else {
                const foundOther = (journeyCategoryList
                    || []).findIndex(it => it.name === "Other")
                if (foundOther > -1) {
                    const journyList = { ...(outcomeState?.journeyList || {}) } as any
                    const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
                    const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
                    if (foundDateItemIcon > -1) {
                        journyList.outcome_data[foundDateItemIcon] = {
                            ...foundOutcomeNew,
                            ...outcomeCreateData,
                        }
                    } else {
                        journyList.outcome_data.push({
                            ...foundOutcomeNew,
                            ...outcomeCreateData,
                        } as any)
                    }
                    UserTypeDispatch(addJourneyAction(journyList as any))
                }
            }
        }
        trackingService
            .outcomeCreate(outcomeState.token, outcomeCreateData)
            .then((data) => {
                if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
                    trackingDispatch(setDayCompleteMessage({
                        open: true,
                        message: data.message
                    }))
                }
                setIsInFullView(false)
                // props?.callService();
            })
    }
    const createInfluenceLevel = (inputLevel, notes) => {
        let outcomeCreateData;

        outcomeCreateData = {
            influenceId: props.item?.influenceId,
            levelReach: inputLevel,
            note: notes,
            date: props?.headerDate,
            flag: props?.item?.flag,
            type: props?.item?.type,
            inverse: props?.item?.inverse,
            colorCode: (props?.item?.type === "TYPE3" &&
                inputLevel === "No") ||
                inputLevel === "No"
                ? props?.item?.inverse === "true"
                    ? palette.primary.main
                    : "#F17F79"
                : props?.item?.inverse === "true"
                    ? "#F17F79"
                    : palette.primary.main
        };
        const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
        if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
            if (foundDateItemIcon > -1) {
                journyList.influence_data[foundDateItemIcon] = {
                    ...foundOutcome,
                    ...outcomeCreateData,
                }
            } else {
                journyList.influence_data.push({
                    ...foundOutcome,
                    ...outcomeCreateData,
                } as any)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
            setVisibleSwitch(false)
            // if (props.callToGetData) {
            //   props?.callService();
            // }
        }
        trackingService
            .influenceCreate(outcomeState.token, outcomeCreateData)
            .then((data) => {
                if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
                    trackingDispatch(setDayCompleteMessage({
                        open: data.showMessage,
                        message: data.message
                    }))
                }

                props.setShowSnakbar(false);
                props.setSnakbarMsg(data?.message);
                props.setOpenYesNoModal(false);
                // props?.callService();
                props.setMsgType("success");
            })
            .catch((error) => {
                props.setShowSnakbar(true);
                props.setSnakbarMsg(
                    error?.response?.data?.description
                        ? error?.response?.data?.description
                        : error?.message
                );
                props.setMsgType("error");
            });

    };

    const handleDeleteEntry = (outcomeId) => {
        let payload = {
            outcomeId: outcomeId,
            date: props?.headerDate,
        };
        console.log("outcomeState?.isPersonal", outcomeState?.isPersonal);

        trackingService
            .deleteOutcomeRes(outcomeState.token, payload)
            .then((data) => {
                if (!outcomeState?.isPersonal) {
                    const foundOutcome = (outcomeState?.outcomeList || []).find(
                        (it) => it.outcomeId === payload.outcomeId
                    );
                    if (foundOutcome) {
                        const journyList = { ...(outcomeState?.journeyList || {}) } as any;
                        const foundDateItemIcon = journyList.outcome_data.findIndex(
                            (it) =>
                                it.outcomeId === payload.outcomeId && it?.date === payload.date
                        );
                        if (foundDateItemIcon > -1) {
                            journyList.outcome_data.splice(foundDateItemIcon, 1);
                            UserTypeDispatch(addJourneyAction(journyList as any));
                            // if (props.callToGetData) {
                            //     props?.callService(false);
                            // }
                        }
                    }
                } else {
                    const journeyCategoryList = [
                        ...(outcomeState?.journeyCategoryList || []),
                    ];
                    console.log("journeyCategoryList", journeyCategoryList);

                    const foundOutcome = (journeyCategoryList || []).findIndex(
                        (it) => it.outcomeIds && it.outcomeIds.includes(payload.outcomeId)
                    );
                    console.log("journeyCategoryList", journeyCategoryList[foundOutcome]);

                    if (foundOutcome > -1) {
                        if (
                            journeyCategoryList[foundOutcome].alls &&
                            journeyCategoryList[foundOutcome].alls.length
                        ) {
                            const foundIndex = journeyCategoryList[
                                foundOutcome
                            ].alls.findIndex((it) => it.outcomeId === payload.outcomeId);
                            console.log("journeyCategoryList foundIndex", foundIndex, journeyCategoryList[foundOutcome].alls[foundIndex]);

                            if (foundIndex > -1) {
                                if (
                                    journeyCategoryList[foundOutcome].alls[foundIndex]
                                        .outcomesResponses &&
                                    journeyCategoryList[foundOutcome].alls[foundIndex]
                                        .outcomesResponses.length
                                ) {
                                    console.log(`journeyCategoryList[foundOutcome].alls[
                                        foundIndex
                                    ].outcomesResponses`, journeyCategoryList[foundOutcome].alls[
                                        foundIndex
                                    ].outcomesResponses, payload);

                                    const foundDateValue = journeyCategoryList[foundOutcome].alls[
                                        foundIndex
                                    ].outcomesResponses.findIndex(
                                        (it) => it.date === payload.date
                                    );
                                    console.log("foundDateValue", foundDateValue);
                                    if (foundDateValue > -1) {
                                        journeyCategoryList[foundOutcome].alls[
                                            foundIndex
                                        ].outcomesResponses.splice(foundDateValue, 1);
                                        console.log("journeyCategoryList[foundOutcome].alls", journeyCategoryList[foundOutcome].alls);

                                        UserTypeDispatch(
                                            addCategoryJourneyAction(journeyCategoryList)
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        const foundOther = (journeyCategoryList || []).findIndex(
                            (it) => it.name === "Other"
                        );
                        if (foundOther > -1) {
                            const outcomeFound = journeyCategoryList[
                                foundOther
                            ].outcomes.findIndex((it) => it.outcomeId === payload.outcomeId);
                            if (outcomeFound > -1) {
                                if (
                                    journeyCategoryList[foundOther].outcomes[outcomeFound]
                                        .outcomesResponses &&
                                    journeyCategoryList[foundOther].outcomes[outcomeFound]
                                        .outcomesResponses.length
                                ) {
                                    const foundDateValue = journeyCategoryList[
                                        foundOther
                                    ].outcomes[outcomeFound].outcomesResponses.findIndex(
                                        (it) => it.date === payload.date
                                    );
                                    if (foundDateValue > -1) {
                                        journeyCategoryList[foundOther].outcomes[
                                            outcomeFound
                                        ].outcomesResponses.splice(foundDateValue, 1);
                                        UserTypeDispatch(
                                            addCategoryJourneyAction(journeyCategoryList)
                                        );
                                    }
                                }
                            }
                        }
                    }
                    if (props.callService) {
                        props?.callService(false);
                    }
                }
                props.setShowSnakbar(false);
                props.setSnakbarMsg(data?.message);
                props.setOpenTrackingEventModal(false);
                // props?.callService(false);
                props.setMsgType("success");
            })
            .catch((error) => {
                props.setShowSnakbar(true);
                props.setSnakbarMsg(
                    error?.response?.data?.description
                        ? error?.response?.data?.description
                        : error?.message
                );
                props.setMsgType("error");
            });
    };
    const handleDeleteEntryInfluence = (influenceId) => {
        let payload = {
            influenceId: influenceId,
            date: props?.headerDate,
        };
        console.log("outcomeState?.isPersonal", outcomeState?.isPersonal);

        trackingService
            .deleteInfluenceRes(outcomeState.token, payload)
            .then((data) => {
                if (!outcomeState?.isPersonal) {
                    const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
                    if (foundOutcome) {
                        const journyList = { ...(outcomeState?.journeyList || {}) } as any
                        const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
                        if (foundDateItemIcon > -1) {
                            journyList.influence_data.splice(foundDateItemIcon, 1)
                        }
                        UserTypeDispatch(addJourneyAction(journyList as any))
                        // if (props.callToGetData) {
                        //   props?.callService(false);
                        // }
                    }
                } else {
                    // if (true) {
                    //     const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
                    //     if (foundOutcome) {
                    //         const journyList = { ...(outcomeState?.journeyList || {}) } as any
                    //         const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
                    //         if (foundDateItemIcon > -1) {
                    //             journyList.influence_data.splice(foundDateItemIcon, 1)
                    //         }
                    //         UserTypeDispatch(addJourneyAction(journyList as any))
                    //         // if (props.callToGetData) {
                    //         //   props?.callService(false);
                    //         // }
                    //     }
                    // }
                    const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
                    const foundOutcome = (journeyCategoryList
                        || []).findIndex(it => it.influenceIds && it.influenceIds.includes(payload.influenceId));

                    if (foundOutcome > -1) {
                        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
                            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === payload.influenceId)
                            if (foundIndex > -1) {
                                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                                    const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === payload.date)
                                    if (foundDateValue > -1) {
                                        journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.splice(foundDateValue, 1)
                                    }
                                }
                                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                            }
                        }
                    } else {
                        const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
                        if (foundOther > -1) {
                            const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === payload.influenceId);
                            if (outcomeFound > -1) {
                                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                                    const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === payload.date)
                                    if (foundDateValue > -1) {
                                        journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.splice(foundDateValue, 1)
                                    }
                                }
                                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                            }
                        }
                    }
                    console.log("props", props);
                    
                    if (props.callService) {
                        props?.callService(false);
                    }
                }
                // setShowLoadingBtn(false);
                // props.setShowSnakbar(true);
                props.setSnakbarMsg(data?.message);
                props.setOpenTrackingEventModal(false);
                // props?.callService(false);
                props.setMsgType("success");
               
            })
            .catch((error) => {
                // setShowLoadingBtn(false);
                props.setShowSnakbar(true);
                props.setSnakbarMsg(
                    error?.response?.data?.description
                        ? error?.response?.data?.description
                        : error?.message
                );
                props.setMsgType("error");
            });
    };
    const { expandedLevelView } = props

    const levelRender = (itemObject, options) => {
        let optionsData = options?.options || []
        if (options.absenceToggle) {
            optionsData = [{
                colorCode: "grey", score: 0, text: "Absence"
            }, ...optionsData]
        }
        console.log("journeyCategoryList rerender after delete", itemObject);
        
        const tickMark = null // <div className={ expandedLevelView? classes.tickMarkNew : classes.tickMark}></div>

        const checkAndReturnFullView = (index) => {
            let fullView = false;
            if (expandedLevelView) {
                if (!isInFullView) {
                    if (Number(itemObject?.index) > -1) {
                        if (options.absenceToggle && itemObject?.index === parseInt(index)) {
                            fullView = true
                        }
                        if (!options.absenceToggle && itemObject?.index === (parseInt(index) + 1)) {
                            fullView = true
                        }
                    } else if (!itemObject?.index) {
                        fullView = true
                    }
                } else {
                    fullView = true
                }
            }
            return fullView
        }

        return <>
            {!expandedLevelView && <div style={{
                width: '100%',
                // height: '1px',
                // transform: 'rotate(-90deg)',
                flexShrink: 0,
                strokeWidth: '100%',
                background: '#DCE5E0',
                position: 'absolute',
                top: '50%'
            }}>

            </div>}
            {!visibleFullStepper && (Number(itemObject?.index) >= 0) ? (
                <div className={expandedLevelView ? classes.selectionExpanded : classes.selection} onClick={(e) => {
                    e.stopPropagation()
                    setVisibleFullStepper(true)
                    setIsInFullView(true)
                }} style={!expandedLevelView ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                } : {}}>
                    {expandedLevelView ? (
                        <div className={expandedLevelView ? classes.rectangleInputFull : classes.rectangleInput} style={expandedLevelView ? {} : {
                            background: '#DCE5E0'
                        }}

                        >
                            <div className={classes.rectangleInput} style={{
                                background: options.absenceToggle && !itemObject?.index ? "grey" : itemObject?.nearValue ? itemObject?.nearValue : '#DCE5E0'  // 
                            }}
                            >
                                <Typography className={classes.counterView} sx={{
                                    color: 'var(--color-text-primary) !important',
                                    textAlign: 'center !important',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '100%',
                                    transform: 'rotate(90deg)',
                                }}>
                                    {itemObject?.index || ""}
                                </Typography>
                            </div>
                            <Typography className={classes.counterView} sx={{
                                color: 'var(--color-text-primary) !important',
                                textAlign: 'left !important',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '135% !important', /* 16px */
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `16px !important`,
                                        lineHeight: "135% !important"
                                    },
                                }
                            }}>
                                {options.absenceToggle && !itemObject?.index ? "Absence" : (optionsData[itemObject?.index - 1] && optionsData[itemObject?.index - 1]?.text ? optionsData[itemObject?.index - 1]?.text : "") || ''}
                            </Typography>
                        </div>
                    ) : (
                        <div className={classes.rectangleInput} style={{
                            background: options.absenceToggle && !itemObject?.index ? "grey" : itemObject?.nearValue ? itemObject?.nearValue : '#DCE5E0'  // 
                        }}
                        >
                            <Typography className={classes.counterView} sx={{
                                color: 'var(--color-text-primary) !important',
                                textAlign: 'center !important',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '100%', /* 16px */
                                transform: 'rotate(90deg)',
                            }}>
                                {options.absenceToggle && !itemObject?.index ? 0 : itemObject?.index}
                            </Typography>
                        </div>
                    )}


                </div>
            ) : (
                <div className={expandedLevelView ? classes.selectionExpanded : classes.selection}>
                    {expandedLevelView ? (
                        <>
                            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} width={"100%"} gap={'7px'}>
                                {optionsData.map((it, index) => checkAndReturnFullView(index) && (
                                    <div className={expandedLevelView ? classes.rectangleInputFull : classes.rectangleInput} style={expandedLevelView ? {} : {
                                        background: '#DCE5E0'
                                    }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (options.influenceId) {
                                                if ((options.absenceToggle && itemObject?.index === parseInt(index)) || itemObject?.index === (parseInt(index) + 1)) {
                                                    handleDeleteEntryInfluence(options.influenceId)
                                                } else {
                                                    createInfluence(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1), options.type)
                                                    setVisibleFullStepper(false)
                                                }
                                            }
                                            if (options?.outcomeId) {
                                                if ((options.absenceToggle && itemObject?.index === parseInt(index)) || itemObject?.index === (parseInt(index) + 1)) {
                                                    handleDeleteEntry(options?.outcomeId)
                                                } else {
                                                    createOutcome(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1))
                                                    setVisibleFullStepper(false)
                                                }
                                            }
                                        }}
                                    >
                                        {options.absenceToggle ? itemObject?.index === parseInt(index) && tickMark : itemObject?.index === (parseInt(index) + 1) && tickMark}
                                        <div className={classes.rectangleInput} style={{
                                            background: options.absenceToggle ? itemObject?.index === parseInt(index) ? it.colorCode : '#DCE5E0' : itemObject?.index === (parseInt(index) + 1) ? it.colorCode : '#DCE5E0'  // 
                                        }}
                                        >
                                            <Typography className={classes.counterView} sx={{
                                                color: (options.absenceToggle && itemObject?.index === parseInt(index)) || (!options.absenceToggle && itemObject?.index === (parseInt(index)) + 1) ? 'var(--color-text-primary) !important' : "var(--color-text-secondary) !important",
                                                textAlign: 'center !important',
                                                fontSize: '16px',
                                                fontWeight: (options.absenceToggle && itemObject?.index === parseInt(index)) || (!options.absenceToggle && itemObject?.index === (parseInt(index)) + 1) ? 600 : 400,
                                                lineHeight: '100%', /* 16px */
                                                transform: 'rotate(90deg)',
                                            }}>
                                                {options.absenceToggle ? index : index + 1}
                                            </Typography>
                                        </div>
                                        <Typography className={classes.counterView} sx={{
                                            color: (options.absenceToggle && itemObject?.index === parseInt(index)) || (!options.absenceToggle && itemObject?.index === (parseInt(index)) + 1) ? 'var(--color-text-primary) !important' : "var(--color-text-secondary) !important",
                                            textAlign: 'left !important',
                                            fontSize: '16px',
                                            fontWeight: (options.absenceToggle && itemObject?.index === parseInt(index)) || (!options.absenceToggle && itemObject?.index === (parseInt(index)) + 1) ? 600 : 400,
                                            lineHeight: '135% !important', /* 16px */
                                            "&.MuiTypography-root": {
                                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                                    fontSize: `16px !important`,
                                                    lineHeight: "135% !important"
                                                },
                                            }
                                        }}>
                                            {it.text || ''}
                                        </Typography>
                                    </div>
                                ))}
                            </Box>
                            {!isInFullView && Number(itemObject?.index) > -1 && (
                                <div onClick={(e) => {
                                    e.stopPropagation()
                                    setIsInFullView(true)
                                }}>
                                    {/* <EditIcon /> */}
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3838 3.40881L10.5913 0.615685C10.4752 0.499558 10.3374 0.407439 10.1857 0.344589C10.034 0.281739 9.87139 0.24939 9.70719 0.24939C9.543 0.24939 9.38041 0.281739 9.22872 0.344589C9.07703 0.407439 8.93921 0.499558 8.82313 0.615685L1.11626 8.32318C0.999757 8.43892 0.907394 8.57663 0.844523 8.72834C0.781653 8.88004 0.749526 9.04272 0.750005 9.20693V12.0001C0.750005 12.3316 0.881701 12.6495 1.11612 12.8839C1.35054 13.1184 1.66848 13.2501 2.00001 13.2501H4.79313C4.95734 13.2505 5.12001 13.2184 5.27171 13.1555C5.42341 13.0926 5.56112 13.0003 5.67688 12.8838L13.3838 5.17631C13.6181 4.9419 13.7497 4.62402 13.7497 4.29256C13.7497 3.9611 13.6181 3.64322 13.3838 3.40881ZM4.68751 11.7501H2.25001V9.31256L7.50001 4.06256L9.93751 6.50006L4.68751 11.7501ZM11 5.43756L8.56251 3.00006L9.70876 1.85381L12.1463 4.29131L11 5.43756Z" fill="#656565" />
                                    </svg>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {optionsData.map((it, index) => (
                                <div className={classes.rectangleInput} style={{
                                    background: options.absenceToggle ? Number(itemObject?.index) === parseInt(index) ? it.colorCode : '#DCE5E0' : Number(itemObject?.index) === (parseInt(index) + 1) ? it.colorCode : '#DCE5E0'  // 
                                }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (options.influenceId) {
                                            if ((options.absenceToggle && itemObject?.index === parseInt(index)) || itemObject?.index === (parseInt(index) + 1)) {
                                                handleDeleteEntryInfluence(options.influenceId)
                                            } else {
                                                createInfluence(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1), options.type)
                                                setVisibleFullStepper(false)
                                            }
                                        }
                                        if (options?.outcomeId) {
                                            if ((options.absenceToggle && itemObject?.index === parseInt(index)) || itemObject?.index === (parseInt(index) + 1)) {
                                                handleDeleteEntry(options?.outcomeId)
                                            } else {
                                                createOutcome(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1))
                                                setVisibleFullStepper(false)
                                            }
                                        }
                                    }}
                                >
                                    {options.absenceToggle ? Number(itemObject?.index) === parseInt(index) && tickMark : Number(itemObject?.index) === (parseInt(index) + 1) && tickMark}
                                    <Typography className={classes.counterView} sx={{
                                        color: (options.absenceToggle && itemObject?.index === parseInt(index)) || itemObject?.index === (parseInt(index) + 1) ? 'var(--color-text-primary) !important' : "var(--color-text-secondary) !important",
                                        textAlign: 'center !important',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '100%', /* 16px */
                                        transform: 'rotate(90deg)',
                                    }}>
                                        {options.absenceToggle ? index : index + 1}
                                    </Typography>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

        </>
    }
    let showNewView = props?.type === "outcome" || ((props?.item?.type === "TYPE2") && (
        props?.item?.unitValue !== "timeofday" &&
        props?.item?.metric_type !== "NEUTRAL"
    )) ? true : false
    const classNaaame = showNewView ? classes.cardBodyNew : classes.cardBody
    return (
        <div className={classNaaame} key={props?.index + '_' + props.headerDate} onClick={() => {
            if (clickCondition && !props?.item?.lockMatrix) {
                props?.setItem(props?.item);
                if (props?.type === "outcome") {
                    setShowInput(false);
                    props?.setOpenTrackingEventModal(true);
                    props?.setDisplayBorder(true);
                    props?.setItem(props?.item);
                    props?.setOutcomeId(props?.item?._id);
                    props?.setSelectedOption(parseInt(props?.optionIndex + 1));
                    let i = outcomeState.outcomeList.findIndex(
                        (outcome) => outcome._id === props?.item?._id
                    );
                    props?.setOutcomeIndex(i);
                } else if (props?.type === "influence") {
                    if (
                        (clickCondition && props?.item?.type === "TYPE3") ||
                        (clickCondition &&
                            props?.item?.type === "TYPE1" &&
                            props?.item?.flag === "yes")
                    ) {
                        props?.setOpenYesNoModal(true);
                        props.setInfluenceId(props?.item._id);
                        return
                    }
                    if (
                        clickCondition &&
                        props?.item?.type === "TYPE12" &&
                        props?.item?.unitValue === "timeofday" &&
                        props?.item?.metric_type === "NEUTRAL"
                    ) {
                        props?.setOpenTimeOfDayModal(true);
                        // props?.setCategoryId(props?.category?.categoryId)
                        props.setInfluenceId(props?.item._id);
                        return
                    } else if (
                        clickCondition &&
                        props?.item?.type === "TYPE12" &&
                        props?.item?.flag === "no"
                    ) {
                        setShowInput(false);
                        props?.setOpenHoursModal(true);
                        props?.setInfluenceId(props?.item._id);
                        props?.setItem(props?.item);
                    }
                    if (showNewView) {
                        setShowInput(false);
                        props?.setOpenTrackingEventModal(true);
                        props?.setDisplayBorder(true);
                        props.setInfluenceId(props?.item._id);
                        props?.setItem(props?.item);
                        props?.setSelectedOption(parseInt(props?.optionIndex + 1));
                        let i = outcomeState.influenceList.findIndex(
                            (influence) => influence._id === props?.item?._id
                        );
                        props?.setOutcomeIndex(i);
                    }
                    if (!showNewView) {
                        setShowInput(true);
                    }
                    setNoData(false);
                    props.setInfluenceId(props?.item._id);
                } else {
                    props?.setRefId(props?.item.reflectiveId);
                    props?.setOpenRefQuesModal(true);
                }
            }
        }} onDoubleClick={() => {
            setNoData(true);
            if (props?.type === "outcome") {
                if (clickCondition && !props?.item?.lockMatrix) {
                    setShowInput(false);
                    props?.setOpenTrackingEventModal(true);
                    props?.setDisplayBorder(true);
                    props?.setItem(props?.item);
                    props?.setOutcomeId(props?.item?._id);
                    props?.setSelectedOption(parseInt(props?.optionIndex + 1));
                    let i = outcomeState.outcomeList.findIndex(
                        (outcome) => outcome._id === props?.outcomeId
                    );
                    props?.setOutcomeIndex(i);
                }
            } else if (props?.type == "influence") {
                if (clickCondition && props?.item?.type === "TYPE2" && !props?.item?.lockMatrix) {
                    setShowInput(false);
                    props?.setOpenTrackingEventModal(true);
                    props?.setDisplayBorder(true);
                    props.setInfluenceId(props?.item._id);
                    props?.setItem(props?.item);
                    props?.setSelectedOption(parseInt(props?.optionIndex + 1));
                    let i = outcomeState.influenceList.findIndex(
                        (influence) => influence._id === props?.influenceId
                    );
                    props?.setOutcomeIndex(i);
                } else if (
                    ((clickCondition && props?.item?.type === "TYPE3") ||
                        (clickCondition &&
                            props?.item?.type === "TYPE1" &&
                            props?.item?.flag === "yes")) && !props?.item?.lockMatrix
                ) {
                    props?.setOpenYesNoModal(true);
                } else if (
                    clickCondition &&
                    props?.item?.type === "TYPE12" &&
                    props?.item?.flag === "no" && !props?.item?.lockMatrix
                ) {
                    setShowInput(false);
                    props?.setOpenHoursModal(true);
                    props?.setInfluenceId(props?.item._id);
                    props?.setItem(props?.item);
                }
            }
        }} style={{
            position: "relative"
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Typography variant="h4" component="h4" style={{ fontWeight: 500, wordBreak: "break-all" }}>
                    {props?.title}
                </Typography>
                <div style={{
                }} onClick={(e) => {
                    e.stopPropagation()
                    if (props?.type === "outcome") {
                        props?.setAddOutcomeTracking(true);
                        props?.setStartModal(true);
                        props?.setOutcomeValue(props?.item);
                    }
                    if (props?.type === "influence") {
                        props?.setAddInfluenceTracking(true);
                        props?.setStartModalInfluence(true);
                        props?.setInfluenceData(props?.item)
                    }
                    if (props?.type === "reflective") {
                        props?.setAddQuesTracking(true);
                        props?.setStartModalQues(true);
                        props?.setQuestionData(props?.item)
                    }
                }}><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3838 3.40881L10.5913 0.615685C10.4752 0.499558 10.3374 0.407439 10.1857 0.344589C10.034 0.281739 9.87139 0.24939 9.70719 0.24939C9.543 0.24939 9.38041 0.281739 9.22872 0.344589C9.07703 0.407439 8.93921 0.499558 8.82313 0.615685L1.11626 8.32318C0.999757 8.43892 0.907394 8.57663 0.844523 8.72834C0.781653 8.88004 0.749526 9.04272 0.750005 9.20693V12.0001C0.750005 12.3316 0.881701 12.6495 1.11612 12.8839C1.35054 13.1184 1.66848 13.2501 2.00001 13.2501H4.79313C4.95734 13.2505 5.12001 13.2184 5.27171 13.1555C5.42341 13.0926 5.56112 13.0003 5.67688 12.8838L13.3838 5.17631C13.6181 4.9419 13.7497 4.62402 13.7497 4.29256C13.7497 3.9611 13.6181 3.64322 13.3838 3.40881ZM4.68751 11.7501H2.25001V9.31256L7.50001 4.06256L9.93751 6.50006L4.68751 11.7501ZM11 5.43756L8.56251 3.00006L9.70876 1.85381L12.1463 4.29131L11 5.43756Z" fill="#656565" />
                    </svg>
                </div>
                {/* <img src={LineGraph} className={classes.lineGraph} /> */}
            </div>
            <div
                className={(props?.item?.type === "TYPE3") ||
                    (
                        props?.item?.type === "TYPE1" &&
                        props?.item?.flag === "yes") ? "" : showNewView ? classes.inputContainerFullWidth : classes.inputContainer}

                key={props.headerDate}
                style={foundData ? {
                    borderColor: "transparent"
                } : {}}
                id={`${props.headerDate}`}
            >
                {showInput ? (
                    <input
                        className={classes.rateInput}
                        autoFocus
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        onBlur={() => {
                            props?.type === "outcome"
                                ? handleBlurInput(
                                    props?.item?._id,
                                    props?.index,
                                    props.headerDate,
                                    { key: "kdksjdks", keyCode: "hdj" },
                                    true
                                )
                                : handleBlurInputInfluence(props?.item?.type, { key: "kdksjdks", keyCode: "hdj" }, true)
                        }}
                        onKeyDown={(e) =>
                            props?.type === "outcome"
                                ? handleBlurInput(
                                    props?.item?._id,
                                    props?.index,
                                    props.headerDate,
                                    e,
                                    false
                                )
                                : handleBlurInputInfluence(props?.item?.type, e, false)
                        }

                    />
                ) : foundData && (
                    <>
                        {journeyDataOutcome?.map((journey, index) => {
                            if (
                                journey?.outcomeId === props?.item?.outcomeId &&
                                journey?.date === props.headerDate
                            ) {
                                let nearValue = nearestValueOutcome(journey?.index, journey)

                                if (journey?.type === "TYPE2") {
                                    nearValue = null
                                }
                                if (props?.item?.outcomeId && props?.item?.options?.length && props?.item?.options[journey?.index - 1]) {
                                    nearValue = props.item.options[journey?.index - 1].colorCode
                                }
                                if (Number(journey?.score) === 0) {
                                    nearValue = '#C7C7C7'
                                }
                                return levelRender({
                                    ...journey,
                                    nearValue
                                }, props?.item)
                            }
                        })}
                        {journeyDataInfluence?.map((journey: any, index: number) => {
                            if (
                                journey?.influenceId === props?.item?.influenceId &&
                                journey?.date === props.headerDate
                            ) {
                                noData = false
                                NoData && setNoData(false);
                                let nearValue = nearestValue(
                                    journey?.type === "TYPE1" ||
                                        journey?.type === "TYPE12"
                                        ? journey?.score
                                        : journey?.index, props?.item)
                                if (journey?.type === "TYPE2") {
                                    if (props?.item?.type === "TYPE2" && props?.item.options.length && props?.item.options[journey?.index - 1]) {
                                        nearValue = props?.item.options[journey?.index - 1].colorCode
                                    }
                                }
                                if ((props?.item?.type === "TYPE3") ||
                                    (
                                        props?.item?.type === "TYPE1" &&
                                        props?.item?.flag === "yes")) {
                                    if (!journey?.levelReach || !visibleYesnotSwitch) {
                                        if (journey?.inverse === "true") {
                                            if (journey?.levelReach === "No") {
                                                return <div onClick={(e) => {
                                                    e.stopPropagation()
                                                    setVisibleSwitch(true)
                                                }} style={{
                                                    height: "40px"
                                                }}>
                                                    <div className={clsx(classes.backgroundYesNo, classes.corssActiveInverse)} style={{
                                                        width: "144px",
                                                        alignSelf: "center",
                                                        justifySelf: "center"
                                                    }}>
                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5306 11.9694C13.6715 12.1103 13.7506 12.3014 13.7506 12.5006C13.7506 12.6999 13.6715 12.891 13.5306 13.0319C13.3897 13.1728 13.1986 13.2519 12.9993 13.2519C12.8001 13.2519 12.609 13.1728 12.4681 13.0319L8.49997 9.06249L4.5306 13.0306C4.3897 13.1715 4.19861 13.2507 3.99935 13.2507C3.80009 13.2507 3.60899 13.1715 3.4681 13.0306C3.3272 12.8897 3.24805 12.6986 3.24805 12.4994C3.24805 12.3001 3.3272 12.109 3.4681 11.9681L7.43747 7.99999L3.46935 4.03061C3.32845 3.88972 3.2493 3.69862 3.2493 3.49936C3.2493 3.30011 3.32845 3.10901 3.46935 2.96811C3.61024 2.82722 3.80134 2.74806 4.0006 2.74806C4.19986 2.74806 4.39095 2.82722 4.53185 2.96811L8.49997 6.93749L12.4693 2.96749C12.6102 2.82659 12.8013 2.74744 13.0006 2.74744C13.1999 2.74744 13.3909 2.82659 13.5318 2.96749C13.6727 3.10838 13.7519 3.29948 13.7519 3.49874C13.7519 3.69799 13.6727 3.88909 13.5318 4.02999L9.56247 7.99999L13.5306 11.9694Z" fill="var(--color-text-primary)" />
                                                        </svg>
                                                        <CommonTypo fontWeight={600} color="var(--color-text-primary)">No</CommonTypo>
                                                    </div>
                                                </div>
                                            }
                                            if (journey?.levelReach === "Yes") {
                                                return <div onClick={(e) => {
                                                    e.stopPropagation()
                                                    setVisibleSwitch(true)
                                                }} style={{
                                                    height: "40px"
                                                }}>
                                                    <div className={clsx(classes.backgroundYesNo, classes.rightActiveInverse)} style={{
                                                        width: "144px",
                                                        alignSelf: "center",
                                                        justifySelf: "center"
                                                    }}>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.5306 5.03057L6.5306 13.0306C6.46092 13.1005 6.37813 13.156 6.28696 13.1938C6.1958 13.2317 6.09806 13.2512 5.99935 13.2512C5.90064 13.2512 5.8029 13.2317 5.71173 13.1938C5.62057 13.156 5.53778 13.1005 5.4681 13.0306L1.9681 9.53057C1.89833 9.4608 1.84299 9.37798 1.80524 9.28683C1.76748 9.19568 1.74805 9.09798 1.74805 8.99932C1.74805 8.90066 1.76748 8.80296 1.80524 8.71181C1.84299 8.62066 1.89833 8.53783 1.9681 8.46807C2.03786 8.3983 2.12069 8.34296 2.21184 8.30521C2.30299 8.26745 2.40069 8.24802 2.49935 8.24802C2.59801 8.24802 2.69571 8.26745 2.78686 8.30521C2.87801 8.34296 2.96083 8.3983 3.0306 8.46807L5.99997 11.4374L13.4693 3.96932C13.6102 3.82842 13.8013 3.74927 14.0006 3.74927C14.1999 3.74927 14.391 3.82842 14.5318 3.96932C14.6727 4.11021 14.7519 4.30131 14.7519 4.50057C14.7519 4.69983 14.6727 4.89092 14.5318 5.03182L14.5306 5.03057Z" fill="var(--color-text-primary)" />
                                                        </svg>
                                                        <CommonTypo fontWeight={600} color="var(--color-text-primary)">Yes</CommonTypo>
                                                    </div>
                                                </div>
                                            }
                                        } else {
                                            if (journey?.levelReach === "Yes") {
                                                return <div onClick={(e) => {
                                                    e.stopPropagation()
                                                    setVisibleSwitch(true)
                                                }} style={{
                                                    height: "40px"
                                                }}>
                                                    <div className={clsx(classes.backgroundYesNo, classes.rightActive)} style={{
                                                        width: "144px",
                                                        alignSelf: "center",
                                                        justifySelf: "center"
                                                    }}>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.5306 5.03057L6.5306 13.0306C6.46092 13.1005 6.37813 13.156 6.28696 13.1938C6.1958 13.2317 6.09806 13.2512 5.99935 13.2512C5.90064 13.2512 5.8029 13.2317 5.71173 13.1938C5.62057 13.156 5.53778 13.1005 5.4681 13.0306L1.9681 9.53057C1.89833 9.4608 1.84299 9.37798 1.80524 9.28683C1.76748 9.19568 1.74805 9.09798 1.74805 8.99932C1.74805 8.90066 1.76748 8.80296 1.80524 8.71181C1.84299 8.62066 1.89833 8.53783 1.9681 8.46807C2.03786 8.3983 2.12069 8.34296 2.21184 8.30521C2.30299 8.26745 2.40069 8.24802 2.49935 8.24802C2.59801 8.24802 2.69571 8.26745 2.78686 8.30521C2.87801 8.34296 2.96083 8.3983 3.0306 8.46807L5.99997 11.4374L13.4693 3.96932C13.6102 3.82842 13.8013 3.74927 14.0006 3.74927C14.1999 3.74927 14.391 3.82842 14.5318 3.96932C14.6727 4.11021 14.7519 4.30131 14.7519 4.50057C14.7519 4.69983 14.6727 4.89092 14.5318 5.03182L14.5306 5.03057Z" fill="var(--color-text-primary)" />
                                                        </svg>
                                                        <CommonTypo fontWeight={600} color="var(--color-text-primary)">Yes</CommonTypo>
                                                    </div>
                                                </div>
                                            }
                                            if (journey?.levelReach === "No") {
                                                return <div onClick={(e) => {
                                                    e.stopPropagation()
                                                    setVisibleSwitch(true)
                                                }} style={{
                                                    height: "40px"
                                                }}>
                                                    <div className={clsx(classes.backgroundYesNo, classes.corssActive)} style={{
                                                        width: "144px",
                                                        alignSelf: "center",
                                                        justifySelf: "center"
                                                    }}>
                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5306 11.9694C13.6715 12.1103 13.7506 12.3014 13.7506 12.5006C13.7506 12.6999 13.6715 12.891 13.5306 13.0319C13.3897 13.1728 13.1986 13.2519 12.9993 13.2519C12.8001 13.2519 12.609 13.1728 12.4681 13.0319L8.49997 9.06249L4.5306 13.0306C4.3897 13.1715 4.19861 13.2507 3.99935 13.2507C3.80009 13.2507 3.60899 13.1715 3.4681 13.0306C3.3272 12.8897 3.24805 12.6986 3.24805 12.4994C3.24805 12.3001 3.3272 12.109 3.4681 11.9681L7.43747 7.99999L3.46935 4.03061C3.32845 3.88972 3.2493 3.69862 3.2493 3.49936C3.2493 3.30011 3.32845 3.10901 3.46935 2.96811C3.61024 2.82722 3.80134 2.74806 4.0006 2.74806C4.19986 2.74806 4.39095 2.82722 4.53185 2.96811L8.49997 6.93749L12.4693 2.96749C12.6102 2.82659 12.8013 2.74744 13.0006 2.74744C13.1999 2.74744 13.3909 2.82659 13.5318 2.96749C13.6727 3.10838 13.7519 3.29948 13.7519 3.49874C13.7519 3.69799 13.6727 3.88909 13.5318 4.02999L9.56247 7.99999L13.5306 11.9694Z" fill="var(--color-text-primary)" />
                                                        </svg>
                                                        <CommonTypo fontWeight={600} color="var(--color-text-primary)">No</CommonTypo>
                                                    </div>
                                                </div>
                                            }
                                        }

                                    }
                                    return <IconYesNo2 matricValue={journey} journalItem={props?.item} onClick={createInfluenceLevel} onDelete={() => handleDeleteEntryInfluence(props?.item?.influenceId)} />
                                }
                                if (Number(journey?.score) === 0 && journey?.type === "TYPE2") {
                                    nearValue = '#C7C7C7'
                                }
                                if (showNewView) {
                                    return levelRender({
                                        ...journey,
                                        nearValue
                                    }, props?.item)
                                }
                                let fontStyling = {

                                }
                                if (journey?.type === "TYPE1" || journey?.type === "TYPE12") {
                                    if (journey?.score && journey?.score?.toString()?.length === 2) {
                                        fontStyling = {
                                            fontSize: "14px"
                                        }
                                    }
                                    if (journey?.score?.toString()?.length >= 3) {
                                        fontStyling = {
                                            fontSize: "12px"
                                        }
                                    }
                                }
                                return (
                                    <div
                                        key={index}
                                        style={{ width: props?.item?.type === "TYPE12" ? "40px" : "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                                        onClick={(e) => {
                                            if (
                                                clickCondition &&
                                                props?.item?.type === "TYPE12" &&
                                                props?.item?.flag === "no" &&
                                                !props?.item?.lockMatrix &&
                                                props?.item?.unitValue !== "timeofday"
                                            ) {
                                                e.stopPropagation()
                                                setShowInput(true);
                                                props?.setInfluenceId(props?.item._id);
                                                props?.setItem(props?.item);
                                            }
                                        }}
                                    >
                                        <span
                                            style={
                                                journey?.note?.length > 0
                                                    ? {
                                                        padding: "2px",
                                                        border: `1px solid ${journey?.timeOfDay ? "var(--color-border)" : nearValue ? nearValue : journey.colorCode
                                                            ? journey.colorCode
                                                            : (journey?.type === "TYPE3" &&
                                                                journey?.levelReach === "No") ||
                                                                journey?.levelReach === "No"
                                                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                                                            }`,
                                                        borderRadius: "50%",
                                                        width: "100%",
                                                        display: "flex",
                                                        height: "100%",
                                                        position: "relative",
                                                    }
                                                    : {
                                                        border: `1px solid ${journey?.timeOfDay ? "var(--color-border)" : nearValue ? nearValue : journey.colorCode
                                                            ? journey.colorCode
                                                            : (journey?.type === "TYPE3" &&
                                                                journey?.levelReach === "No") ||
                                                                journey?.levelReach === "No"
                                                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                                                            }`,
                                                        backgroundColor: journey?.timeOfDay ? "var(--color-border)" : nearValue ? nearValue : journey.colorCode
                                                            ? journey.colorCode
                                                            : (journey?.type === "TYPE3" &&
                                                                journey?.levelReach === "No") ||
                                                                journey?.levelReach === "No"
                                                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)"),
                                                        width: "100%",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        height: "100%",
                                                        position: "relative",
                                                        fontWeight: 600,
                                                        fontSize: "23px",
                                                        lineHeight: "100%",
                                                    }
                                            }
                                        >
                                            <span
                                                style={{
                                                    backgroundColor: journey?.timeOfDay ? "var(--color-border)" : nearValue ? nearValue : journey.colorCode
                                                        ? journey.colorCode
                                                        : (journey?.type === "TYPE3" &&
                                                            journey?.levelReach === "No") ||
                                                            journey?.levelReach === "No"
                                                            ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                                            : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)"),
                                                    borderRadius: "50%",
                                                    color: "var(--color-text-primary) !important",
                                                    border: `1px solid ${journey?.timeOfDay ? "var(--color-border)" : nearValue ? nearValue : journey.colorCode
                                                        ? journey.colorCode
                                                        : (journey?.type === "TYPE3" &&
                                                            journey?.levelReach === "No") ||
                                                            journey?.levelReach === "No"
                                                            ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                                            : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                                                        }`,
                                                    width: "100%",
                                                    display: "flex",
                                                    fontWeight: 400,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    ...fontStyling
                                                }}
                                            >
                                                {journey?.timeOfDay ? (
                                                    <Tooltip
                                                        title={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "10px !important",
                                                                    lineHeight: "1.5",
                                                                }}
                                                            >
                                                                {journey?.timeOfDay}
                                                            </Typography>
                                                        }
                                                    >
                                                        <div className={classes.clock}>
                                                            {journey?.timeOfDay}
                                                        </div>
                                                    </Tooltip>
                                                ) : journey?.type === "TYPE1" ||
                                                    journey?.type === "TYPE12" ? (
                                                    journey?.score
                                                ) : (
                                                    journey?.index
                                                )}
                                            </span>
                                        </span>
                                    </div>
                                );
                            }
                        })}
                        {journeyDataReflective?.map((journey: any, index: number) => {
                            if (
                                journey?.reflectiveId === props?.item?.reflectiveId &&
                                journey?.date === props.headerDate
                            ) {
                                NoData && setNoData(false);
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <span className={classes.plusIcon} style={{
                                            justifyContent: "center"
                                        }}>
                                            <CommonTypo color="var(--color-text-primary)" fontWeight={600}>{journey.answers[0]} {journey.answers.length > 0 ? "..." : ""}</CommonTypo>
                                        </span>
                                        {/* <span
                                            style={{
                                                backgroundColor: "var(--color-new-main)",
                                                border: `1px solid ${"var(--color-new-main)"}`,
                                                display: "flex",
                                                width: "54px",
                                                height: "100%",
                                            }}
                                        > */}
                                        {/* <span
                                                style={{
                                                    backgroundColor: "var(--color-new-main)",
                                                    borderRadius: "50%",
                                                    border: `1px solid ${"var(--color-new-main)"}`,
                                                }}
                                            >{JSON.stringify(journey.answers)}</span> */}

                                        {/* </span> */}
                                    </div>
                                );
                            }
                        })}
                    </>
                )}
                {!foundData && !showInput ?
                    showNewView ? levelRender({}, props?.item) : (
                        (props?.item?.type === "TYPE3") ||
                            (
                                props?.item?.type === "TYPE1" &&
                                props?.item?.flag === "yes") ? (
                            <IconYesNo2 matricValue={null} journalItem={props?.item} onClick={createInfluenceLevel} />
                        ) :
                            <span className={classes.plusIcon} onClick={(e) => {
                                if (
                                    clickCondition &&
                                    props?.item?.type === "TYPE12" &&
                                    props?.item?.flag === "no" &&
                                    !props?.item?.lockMatrix &&
                                    props?.item?.unitValue !== "timeofday"
                                ) {
                                    e.stopPropagation()
                                    setShowInput(true);
                                    props?.setInfluenceId(props?.item._id);
                                    props?.setItem(props?.item);
                                } /* else if (
                                    clickCondition &&
                                    props?.item?.type === "TYPE12" &&
                                    props?.item?.unitValue === "timeofday" &&
                                    props?.item?.metric_type === "NEUTRAL"
                                ) {
                                    props?.setOpenTimeOfDayModal(true);
                                    props?.setCategoryId(props?.category?.categoryId)
                                    props.setInfluenceId(props?.item._id);
                                } */
                            }}>
                                {!props?.item?.lockMatrix && <CommonTypo color="var(--color-text-secondary)">{props?.type === "reflective" ? "Add answer..." : "Add value..."}</CommonTypo>}
                                {props?.item?.lockMatrix && isToday && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.2 2.00004V5.60004C15.2 5.75917 15.1368 5.91178 15.0243 6.0243C14.9117 6.13683 14.7591 6.20004 14.6 6.20004H11C10.8813 6.20013 10.7652 6.165 10.6664 6.09907C10.5676 6.03315 10.4907 5.93941 10.4452 5.82972C10.3998 5.72002 10.3879 5.59931 10.4111 5.48286C10.4343 5.36641 10.4915 5.25945 10.5755 5.17554L11.9487 3.80004C10.844 2.7416 9.37466 2.14842 7.84474 2.14329H7.81099C6.2433 2.14022 4.73744 2.75452 3.61924 3.85329C3.50465 3.96026 3.35276 4.01813 3.19605 4.01454C3.03933 4.01094 2.89025 3.94616 2.78069 3.83406C2.67113 3.72195 2.6098 3.57142 2.60981 3.41466C2.60981 3.25791 2.67117 3.10739 2.78074 2.99529C4.11827 1.68847 5.91176 0.953296 7.78171 0.945317C9.65165 0.937338 11.4514 1.65719 12.8 2.95254L14.177 1.57554C14.261 1.49205 14.3678 1.43528 14.484 1.41238C14.6002 1.38949 14.7206 1.40149 14.83 1.44688C14.9393 1.49227 15.0328 1.56902 15.0987 1.66745C15.1645 1.76589 15.1998 1.88161 15.2 2.00004ZM12.3807 12.1468C11.2694 13.2323 9.78025 13.8444 8.22673 13.8543C6.67322 13.8643 5.17641 13.2712 4.05124 12.2L5.42449 10.8268C5.50927 10.743 5.56718 10.6359 5.59082 10.519C5.61445 10.4022 5.60275 10.281 5.55721 10.1708C5.51167 10.0606 5.43434 9.96653 5.3351 9.9005C5.23585 9.83447 5.11919 9.7995 4.99999 9.80004H1.39999C1.24086 9.80004 1.08825 9.86325 0.975724 9.97578C0.863202 10.0883 0.799988 10.2409 0.799988 10.4V14C0.799895 14.1188 0.835033 14.2349 0.900956 14.3336C0.966878 14.4324 1.06062 14.5094 1.17031 14.5548C1.28001 14.6003 1.40072 14.6121 1.51717 14.589C1.63362 14.5658 1.74057 14.5085 1.82449 14.4245L3.19999 13.0475C4.52914 14.3303 6.30281 15.0494 8.14999 15.0545H8.18974C10.0708 15.0594 11.878 14.323 13.22 13.0048C13.3296 12.8927 13.3909 12.7422 13.3909 12.5854C13.3909 12.4287 13.3296 12.2781 13.22 12.166C13.1105 12.0539 12.9614 11.9891 12.8047 11.9855C12.648 11.9819 12.4961 12.0398 12.3815 12.1468H12.3807Z" fill="#000000" />
                                </svg>}
                            </span>
                    ) : null}
            </div>
        </div>
    );
};

export default DetailCard