import { Box, Checkbox, Dialog, IconButton, InputAdornment, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import CloseButton from "../../../components/CloseButton"
import CommonTypo from "../../../components/CommonTypo"
import { Input } from "../../../components/inputGroup";
import Search from "../../../assets/images/icon/Search"
import NewButton from "../../../components/NewButton";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import palette from "../../../theme/palette";
import useStyles from "../settingclasses";
import { INTEGRATINOS_TYPES } from "../integrationsConstants";
import clsx from "clsx";
import { styled } from "@material-ui/core";
import ArrowDownNew, { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew";

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
    color: "#49454F",
    '& .MuiSvgIcon-root': {
        fontSize: 16, borderRadius: "2px",
    },
    '&.Mui-checked': {
        color: "#65558F",
    },
    "&.MuiCheckbox-root": {
        padding: "0px !important"
    }
}));
function updateArrayWithGroupPid(data, newArr) {
    // Extract all 'pid' values from the items array
    const itemPids = data.items.map(item => item.pid);

    // Check if all item PIDs exist in the newArr
    const allItemsInNewArr = itemPids.every(pid => newArr.includes(pid));

    // If all items are in the newArr, add the group's PID
    if (allItemsInNewArr && !newArr.includes(data.pid)) {
        newArr.push(data.pid);
    }

    return newArr;
}

const updateAllDataArr = (data, newArr) => {
    if (data && data.length) {
        data.map(it => updateArrayWithGroupPid(it, newArr))
    }
    return newArr
}
const SelectionDialogue = ({
    openModal,
    isMobile,
    onClose,
    rescueTimePercents,
    tooglData,
    modalType,
    updateRescueTime,
    updateOura,
    updateGarmin,
    updateToggl,
    rescueResponse,
    garminResponse,
    togglResponse,
    isEditMode,
    garminData,
    setEditMode,
    ouraData,
    ouraResponse
}) => {
    console.log("garminData", garminData)
    const [selectionKeys, setSelectionKeys] = useState([])
    const [multiLevelSelectionKeys, setMultiLevelSelectionKey] = useState([])
    const [searchMetricString, setSearchMetricString] = useState("")
    const [multiLevelFilteredSelectionKeys, setMultiLevelFilteredSelectionKey] = useState([])
    const [selectedOutInflu, setSelectedOutInflu] = useState([]);
    const [expandedIds, setExpandedIds] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([])
    const [viewBelowMetrics, setViewBelowMetrics] = useState(true)
    const [selectedTab, setSelectedTab] = useState("hours");
    const [openTooltip, setOpenTooltip] = useState(false)
    console.log("selectedOutInflu", selectedOutInflu);
    console.log("ouraData", ouraData);
    useEffect(() => {
        if (rescueTimePercents && rescueTimePercents.length && modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
            const arr = []
            const obj = rescueTimePercents[0]
            Object.keys(obj).map((key) => {
                if (key !== "date") {
                    // Transform the key
                    const formattedKey = key
                        .replace(/_/g, " ")                 // Replace underscores with spaces
                        .split(" ")                        // Split into words
                        .map((word) => {
                            if (word.toLowerCase() === "and") {
                                return "&";                    // Replace "and" with "&"
                            }
                            return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter
                        })
                        .join(" ");                        // Join the words back into a single string

                    // Ensure correct capitalization after "&"
                    const finalKey = formattedKey.replace(/&\s*(\w)/g, (match, char) => `& ${char.toUpperCase()}`);
                    arr.push({
                        value: key,
                        label: finalKey
                    })
                }
                return key
            })
            setSelectionKeys(arr)
        }
        if (tooglData && tooglData.length && modalType === INTEGRATINOS_TYPES.TOGGL) {
            const arr = []
            for (let index = 0; index < tooglData.length; index++) {
                const element = tooglData[index];
                const items = []
                if (element.projectsDetails && element.projectsDetails.length) {
                    for (let i = 0; i < element.projectsDetails.length; i++) {
                        const ele = element.projectsDetails[i];
                        items.push({
                            name: ele.name,
                            pid: ele.id,
                            parent: element.client.name
                        })
                    }
                }
                arr.push({
                    groupName: element.client.name,
                    pid: element.client.id,
                    items: items
                })
            }
            setMultiLevelSelectionKey(arr)
        }
        if (ouraData && ouraData.length && modalType === INTEGRATINOS_TYPES.OURA) {
            const arr = []
            console.log("ouraData", ouraData);
            for (let index = 0; index < ouraData.length; index++) {
                const element = ouraData[index];
                const items = []
                if (element.keys && element.keys.length) {
                    for (let i = 0; i < element.keys.length; i++) {
                        const ele = element.keys[i];
                        items.push({
                            name: ele.key.replace(/_/g, " "),
                            pid: ele.key.toLowerCase(),
                            original: ele.key,
                            value: ele.value,
                            parent: element.group
                        })
                    }
                }
                arr.push({
                    groupName: element.group.replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
                        .replace(/_/g, ' ')                 // Replace underscores with spaces (optional)
                        .replace(/\b\w/g, char => char.toUpperCase()),
                    pid: element.group,
                    items: items
                })
            }
            setMultiLevelSelectionKey(arr)
            setMultiLevelFilteredSelectionKey(arr)
        }
        if (garminData && Object.keys(garminData).length && modalType === INTEGRATINOS_TYPES.GARMIN) {
            const arr = []
            function camelCaseToTitleCase(text) {
                return text
                    .replace(/([A-Z])/g, ' $1')  // Insert a space before each uppercase letter
                    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
            }
            console.log("garminData", garminData);
            Object.keys(garminData).map(key => {
                const items = []
                if (garminData[key] && garminData[key].length) {
                    for (let i = 0; i < garminData[key].length; i++) {
                        const ele = garminData[key][i];
                        items.push({
                            name: camelCaseToTitleCase(ele),
                            pid: ele,
                            original: ele,
                            value: ele.value,
                            parent: key
                        })
                    }
                }
                arr.push({
                    groupName: key.replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
                        .replace(/_/g, ' ')                 // Replace underscores with spaces (optional)
                        .replace(/\b\w/g, char => char.toUpperCase()),
                    pid: key,
                    items: items
                })
            })
            console.log("arr", arr);

            setMultiLevelSelectionKey(arr)
            setMultiLevelFilteredSelectionKey(arr)
        }
    }, [rescueTimePercents, tooglData, ouraData, garminData])
    useEffect(() => {
        if (isEditMode && modalType === INTEGRATINOS_TYPES.RESCUE_TIME && rescueResponse) {
            setCheckedKeys(rescueResponse.keysArray)
        }
        if (isEditMode && modalType === INTEGRATINOS_TYPES.TOGGL && togglResponse) {
            console.log("togglResponse", togglResponse);
            let arr = []
            for (let index = 0; index < togglResponse.keysArray.length; index++) {
                const element = togglResponse.keysArray[index];
                arr.push(Number(element.pid))
            }
            const selectedArr = updateAllDataArr(multiLevelSelectionKeys, [...arr])
            setSelectedOutInflu(selectedArr)
        }
        if (isEditMode && modalType === INTEGRATINOS_TYPES.OURA && ouraResponse) {
            const selectedArr = updateAllDataArr(multiLevelSelectionKeys, [...ouraResponse.keysArray])
            setSelectedOutInflu(selectedArr)
        }
        console.log("garminResponse", garminResponse);

        if (isEditMode && modalType === INTEGRATINOS_TYPES.GARMIN && garminResponse && garminResponse.keysArray) {
            const selectedArr = updateAllDataArr(multiLevelSelectionKeys, [...garminResponse.keysArray])
            console.log("selectedArr", selectedArr, multiLevelSelectionKeys);

            setSelectedOutInflu(selectedArr)
        }
    }, [isEditMode, rescueResponse, togglResponse, garminResponse, ouraResponse, multiLevelSelectionKeys])
    useEffect(() => {
        if (searchMetricString && searchMetricString.trim().length) {
            setMultiLevelFilteredSelectionKey([...multiLevelSelectionKeys].map(obj => {
                // Check if groupName matches the search term
                const groupNameMatches = obj.groupName
                    .toLowerCase()
                    .includes(searchMetricString.toLowerCase());
                // Filter items based on the search term
                const filteredItems = obj.items.filter(item =>
                    item.name.toLowerCase().includes(searchMetricString.toLowerCase())
                );

                // Include the group if the groupName matches or filteredItems has a length
                if (groupNameMatches || filteredItems.length > 0) {
                    const objNew = { ...obj }
                    if (filteredItems && filteredItems.length) {
                        objNew.items = filteredItems
                    }

                    return { ...objNew };
                }

                return null; // Exclude objects with no matching items
            })
                .filter(obj => obj !== null)); // Remove nulls from the result
        } else {
            setMultiLevelFilteredSelectionKey(multiLevelSelectionKeys)
        }
    }, [searchMetricString, multiLevelSelectionKeys])
    let paperStyles: any = {
        display: 'flex !important',
        padding: '16px 24px 32px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '32px !important',
        minWidth: "579px",
        maxWidth: "max-content",
        borderRadius: "12px",
        overflowY: "auto"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    const renderIcon = (icon, onClick = () => { }) => (
        <div onClick={onClick}>
            {icon}
        </div>
    );
    const closemodal = () => {
        setCheckedKeys([])
        setOpenTooltip(false)
        onClose()
    }
    const classes = useStyles()
    const renderTree = (nodes, key, onNodeSleect, selectedNode, isParent, showCloseIcon = false, visibleIds = [], setExpandedIds = (ids: any[]) => { }) => (
        <TreeItem
            key={nodes.pid}
            nodeId={nodes.pid}
            // onClick={handleExpandClick}
            style={{
                display: "flex",
                flexDirection: "column",
                // gap: "16px",

                borderBottom: "none"
            }}
            className={"classes-labelClass"}
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: !isParent ? "1px solid var(--color-border)" : "none",
                        gap: "8px",
                        padding: "16px",
                    }}
                    onClick={() => {
                        if (visibleIds.includes(nodes.pid)) {
                            const filtered = [...visibleIds].filter(it => it !== nodes.pid)
                            setExpandedIds(filtered)
                        } else {
                            const filtered = [...visibleIds]
                            filtered.push(nodes.pid)
                            setExpandedIds(filtered)
                        }
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} gap={"8px"} onClick={event => {
                        onNodeSleect(event, nodes)
                    }}>
                        <CustomCheckBox
                            checked={selectedNode.indexOf(nodes.pid) !== -1}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                                padding: "0px !important"
                            }}
                            icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            } checkedIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.96938 9.03063C4.89961 8.96086 4.84427 8.87804 4.80651 8.78689C4.76876 8.69573 4.74932 8.59804 4.74932 8.49938C4.74932 8.40071 4.76876 8.30302 4.80651 8.21186C4.84427 8.12071 4.89961 8.03789 4.96938 7.96812C5.03914 7.89836 5.12196 7.84302 5.21311 7.80526C5.30427 7.76751 5.40196 7.74807 5.50063 7.74807C5.59929 7.74807 5.69698 7.76751 5.78814 7.80526C5.87929 7.84302 5.96211 7.89836 6.03188 7.96812L7 8.9375L9.96937 5.9675C10.1103 5.8266 10.3014 5.74745 10.5006 5.74745C10.6999 5.74745 10.891 5.8266 11.0319 5.9675C11.1728 6.1084 11.2519 6.29949 11.2519 6.49875C11.2519 6.69801 11.1728 6.8891 11.0319 7.03L7.53188 10.53C7.4622 10.5999 7.3794 10.6554 7.28824 10.6933C7.19708 10.7311 7.09934 10.7506 7.00063 10.7506C6.90191 10.7506 6.80417 10.7311 6.71301 10.6933C6.62185 10.6554 6.53905 10.5999 6.46938 10.53L4.96938 9.03063ZM14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            }
                            // onClick={(event) => onNodeSleect(event, nodes)}
                            disabled={isParent && (!Array.isArray(nodes[key]) || !nodes[key].length)}
                        />
                        <CommonTypo size={14} lineHeight={18.2} fontWeight={600} color="var(--color-text-primary)">
                            {nodes.groupName || nodes.name}{" "}
                            {nodes[key] ? "(" + nodes[key].length + ")" : null}
                        </CommonTypo>
                    </Box>

                    {nodes[key] && showCloseIcon && <Box>{visibleIds.includes(nodes.pid) ? <ArrowDownNew color="var(--color-text-primary)" /> : <ArrowUpNew color="var(--color-text-primary)" />}</Box>}
                </Box>
            }
        >
            {showCloseIcon ? (
                Array.isArray(nodes[key]) && visibleIds.includes(nodes.pid)
                    ? nodes[key].map((node) =>
                        renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                    )
                    : null
            ) : Array.isArray(nodes[key])
                ? nodes[key].map((node) =>
                    renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                )
                : null}
            { }
        </TreeItem>
    );
    const bfsSearch = (graph, key, targetId) => {
        const queue = [...graph];

        while (queue.length > 0) {
            const currNode = queue.shift();
            if (currNode.pid === targetId) {
                return currNode;
            }
            if (currNode[key]) {
                queue.push(...currNode[key]);
            }
        }
        return []; // Target node not found
    };
    function getAllIds(node, key, idList = []) {
        idList.push(node.pid);
        if (node[key]) {
            node[key].forEach((child) => getAllIds(child, key, idList));
        }
        return idList;
    }
    // Get IDs of all children from specific node
    const getAllChild = (array, key, id) => {
        return getAllIds(bfsSearch(array, key, id), key);
    };

    // Get all father IDs from specific node
    const getAllFathers = (id, array, key, list = []) => {
        const node = bfsSearch(array, key, id);
        if (node.parent) {
            list.push(node.parent);

            return getAllFathers(node.parent, array, key, list);
        }

        return list;
    };
    const handleOutInfluSelect = (event, nodeId) => {
        event.stopPropagation();
        const allChild = getAllChild(
            multiLevelSelectionKeys,
            "items",
            nodeId
        );
        const fathers = getAllFathers(
            nodeId,
            multiLevelSelectionKeys,
            "items"
        );
        if (selectedOutInflu.includes(nodeId)) {
            // Need to de-check
            setSelectedOutInflu((prevSelectedNodes) =>
                prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
            );
        } else {
            // Need to check
            const ToBeChecked = allChild;
            for (let i = 0; i < fathers.length; ++i) {
                if (
                    isAllChildrenChecked(
                        selectedOutInflu,
                        multiLevelSelectionKeys,
                        "items",
                        bfsSearch(
                            multiLevelSelectionKeys,
                            "items",
                            fathers[i]
                        ),
                        ToBeChecked
                    )
                ) {
                    ToBeChecked.push(fathers[i]);
                }
            }
            setSelectedOutInflu((prevSelectedNodes) =>
                [...prevSelectedNodes].concat(ToBeChecked)
            );
        }
    };
    function isAllChildrenChecked(selectedArray, array, key, node, list) {
        const allChild = getAllChild(array, key, node.pid);
        const nodeIdIndex = allChild.indexOf(node.pid);
        allChild.splice(nodeIdIndex, 1);

        return allChild.every((nodeId) =>
            selectedArray.concat(list).includes(nodeId)
        );
    }
    console.log("multiLevelSelectionKeys", multiLevelSelectionKeys);
    const allChildrens = []
    if (multiLevelSelectionKeys && multiLevelSelectionKeys.length) {
        for (let index = 0; index < multiLevelSelectionKeys.length; index++) {
            const element = multiLevelSelectionKeys[index];
            allChildrens.push(element)
            allChildrens.push(...element.items)
        }

    }
    console.log("multiLevelFilteredSelectionKeys", allChildrens, multiLevelFilteredSelectionKeys, selectedOutInflu);
    let disabledNextButton = true
    if (checkedKeys.length && modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
        disabledNextButton = false
    }
    if (selectedOutInflu.length && modalType === INTEGRATINOS_TYPES.TOGGL) {
        disabledNextButton = false
    }
    if (selectedOutInflu.length && modalType === INTEGRATINOS_TYPES.OURA) {
        disabledNextButton = false
    }
    if (selectedOutInflu.length && modalType === INTEGRATINOS_TYPES.GARMIN) {
        disabledNextButton = false
    }
    console.log("selectedOutInflu", selectedOutInflu, multiLevelFilteredSelectionKeys);

    return <>
        <Dialog
            onClose={closemodal}
            open={openModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} alignItems={"center"} justifyContent={"flex-end"}>
                <CloseButton onClick={closemodal} boxStyle={{
                    display: "flex"
                }} />
            </Box>
            <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} alignItems={"flex-start"} justifyContent={"flex-start"} flexDirection={"column"} gap={"16px"}>
                <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">Configure integration</CommonTypo>
                {[INTEGRATINOS_TYPES.OURA, INTEGRATINOS_TYPES.GARMIN].includes(modalType) && (
                    <TextField placeholder="Search..." sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px",
                                    fontWeight: 600
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} onChange={e => {
                        setSearchMetricString(e.target.value)
                    }} value={searchMetricString} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                // onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                <Search size={16} color="var(--color-text-primary)" />
                                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            </IconButton>
                        </InputAdornment>
                    }} />
                )}
            </Box>

            <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} flexDirection={"column"} gap={"16px"}>
                <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} alignItems={"center"} justifyContent={"space-between"} gap={"16px"}>
                    <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Which metrics to import?</CommonTypo>
                </Box>
                {modalType === INTEGRATINOS_TYPES.RESCUE_TIME && (
                    <>
                        <Box display={"flex"} flexDirection={"column"} maxWidth={"calc(100vw - 50%)"}>
                            <Box display={"flex"} alignItems={"center"} sx={{
                                cursor: "pointer"
                            }} justifyContent={"space-between"} borderBottom={"1px solid var(--color-border)"} onClick={e => {
                                setViewBelowMetrics(!viewBelowMetrics)
                            }}>
                                <SelectionCard key={"select_all"} onSelect={(key, checked) => {
                                    if (checked) {
                                        const allValues = [...selectionKeys].map(it => it.value)
                                        setCheckedKeys(allValues)
                                    } else {
                                        setCheckedKeys([])

                                    }
                                }} selected={checkedKeys.length === selectionKeys.length} showBorder={false} title={"All metrics"} value={"select_all"} />
                                <Box display={"flex"} alignItems={"center"} padding={"16px"} sx={{
                                    transform: !viewBelowMetrics ? "rotate(180deg)" : ""
                                }}>
                                    <ArrowDownNew color="#292B2A" />
                                </Box>
                            </Box>
                            {viewBelowMetrics && (
                                <Box display={"grid"} sx={{
                                    borderRadius: "8px",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                    columnGap: "16px",
                                    "& > :nth-child(2n-1)": { // Targets the first child
                                        paddingLeft: "48px"
                                    }
                                }}>
                                    {selectionKeys.map(it => <SelectionCard key={it.value} onSelect={(key, checked) => {
                                        if (checked) {
                                            setCheckedKeys([...checkedKeys, key])
                                        } else {
                                            const selectedKey = [...checkedKeys].filter(it => it !== key)
                                            setCheckedKeys(selectedKey)
                                        }
                                    }} selected={checkedKeys.includes(it.value)} title={it.label} value={it.value} />)}
                                </Box>
                            )}
                        </Box>
                    </>
                )}
                {modalType === INTEGRATINOS_TYPES.TOGGL && (
                    <Box display={"flex"} flexDirection={"column"} maxWidth={"calc(100vw - 50%)"}>
                        <Box display={"flex"} alignItems={"center"} sx={{
                            cursor: "pointer"
                        }} justifyContent={"space-between"} borderBottom={"1px solid var(--color-border)"} onClick={e => {
                            setViewBelowMetrics(!viewBelowMetrics)
                        }}>
                            <SelectionCard key={"select_all"} onSelect={(key, checked) => {
                                if (checked) {
                                    const allKeys = []
                                    for (let index = 0; index < multiLevelSelectionKeys.length; index++) {
                                        const element = multiLevelSelectionKeys[index];
                                        allKeys.push(element.pid)
                                        for (let i = 0; i < element.items.length; i++) {
                                            const ele = element.items[i];
                                            allKeys.push(ele.pid)
                                        }
                                    }
                                    setSelectedOutInflu(allKeys)
                                } else {
                                    setSelectedOutInflu([])
                                }
                            }} selected={selectedOutInflu.length === allChildrens.length} showBorder={false} title={"All metrics"} value={"select_all"} />
                            <Box display={"flex"} alignItems={"center"} padding={"16px"} sx={{
                                transform: !viewBelowMetrics ? "rotate(180deg)" : ""
                            }}>
                                <ArrowDownNew color="#292B2A" />
                            </Box>
                        </Box>
                        {viewBelowMetrics && (
                            <TreeView
                                multiSelect
                                expanded={multiLevelSelectionKeys.map((it) => it.pid)}
                                className={clsx(classes.treeClassV2, "hide-scrollbar")}
                                // defaultCollapseIcon={<ExpandMoreIcon />}
                                // defaultExpandIcon={<ChevronRightIcon />}
                                selected={[]}
                            >
                                {multiLevelSelectionKeys.map((node) =>
                                    renderTree(
                                        node,
                                        "items",
                                        (event, nodes) => {
                                            handleOutInfluSelect(
                                                event,
                                                nodes.pid,
                                            );
                                        },
                                        selectedOutInflu,
                                        true, true, expandedIds, setExpandedIds
                                    )
                                )}
                            </TreeView>
                        )}
                    </Box>
                )}
                {modalType === INTEGRATINOS_TYPES.OURA && (
                    <>
                        <Box display={"flex"} flexDirection={"column"} maxWidth={"calc(100vw - 50%)"}>
                            <Box display={"flex"} alignItems={"center"} sx={{
                                cursor: "pointer"
                            }} justifyContent={"space-between"} borderBottom={"1px solid var(--color-border)"} onClick={e => {
                                setViewBelowMetrics(!viewBelowMetrics)
                            }}>
                                <SelectionCard key={"select_all"} onSelect={(key, checked) => {
                                    if (checked) {
                                        const allKeys = []
                                        for (let index = 0; index < multiLevelFilteredSelectionKeys.length; index++) {
                                            const element = multiLevelFilteredSelectionKeys[index];
                                            allKeys.push(element.pid)
                                            for (let i = 0; i < element.items.length; i++) {
                                                const ele = element.items[i];
                                                allKeys.push(ele.pid)
                                            }
                                        }
                                        setSelectedOutInflu(allKeys)
                                    } else {
                                        setSelectedOutInflu([])
                                    }
                                }} selected={selectedOutInflu.length === allChildrens.length} showBorder={false} title={"All metrics"} value={"select_all"} />
                                <Box display={"flex"} alignItems={"center"} padding={"16px"} sx={{
                                    transform: !viewBelowMetrics ? "rotate(180deg)" : ""
                                }}>
                                    <ArrowDownNew color="#292B2A" />
                                </Box>
                            </Box>
                            {viewBelowMetrics && (
                                <TreeView
                                    multiSelect
                                    expanded={multiLevelFilteredSelectionKeys.map((it) => it.pid)}
                                    className={clsx(classes.treeClassV2, "hide-scrollbar")}
                                    // defaultCollapseIcon={<ExpandMoreIcon />}
                                    // defaultExpandIcon={<ChevronRightIcon />}
                                    selected={[]}
                                >
                                    {multiLevelFilteredSelectionKeys.map((node) =>
                                        renderTree(
                                            node,
                                            "items",
                                            (event, nodes) => {
                                                handleOutInfluSelect(
                                                    event,
                                                    nodes.pid,
                                                );
                                            },
                                            selectedOutInflu,
                                            true, true, expandedIds, setExpandedIds
                                        )
                                    )}
                                </TreeView>
                            )}
                        </Box>
                    </>
                )}
                {modalType === INTEGRATINOS_TYPES.GARMIN && (
                    <>
                        <Box display={"flex"} flexDirection={"column"} maxWidth={"calc(100vw - 50%)"}>
                            <Box display={"flex"} alignItems={"center"} sx={{
                                cursor: "pointer"
                            }} justifyContent={"space-between"} borderBottom={"1px solid var(--color-border)"} onClick={e => {
                                setViewBelowMetrics(!viewBelowMetrics)
                            }}>
                                <SelectionCard key={"select_all"} onSelect={(key, checked) => {
                                    if (checked) {
                                        const allKeys = []
                                        for (let index = 0; index < multiLevelFilteredSelectionKeys.length; index++) {
                                            const element = multiLevelFilteredSelectionKeys[index];
                                            allKeys.push(element.pid)
                                            for (let i = 0; i < element.items.length; i++) {
                                                const ele = element.items[i];
                                                allKeys.push(ele.pid)
                                            }
                                        }
                                        setSelectedOutInflu(allKeys)
                                    } else {
                                        setSelectedOutInflu([])
                                    }
                                }} selected={selectedOutInflu.length === allChildrens.length} showBorder={false} title={"All metrics"} value={"select_all"} />
                                <Box display={"flex"} alignItems={"center"} padding={"16px"} sx={{
                                    transform: !viewBelowMetrics ? "rotate(180deg)" : ""
                                }}>
                                    <ArrowDownNew color="#292B2A" />
                                </Box>
                            </Box>
                            {viewBelowMetrics && (
                                <TreeView
                                    multiSelect
                                    expanded={multiLevelFilteredSelectionKeys.map((it) => it.pid)}
                                    className={clsx(classes.treeClassV2, "hide-scrollbar")}
                                    // defaultCollapseIcon={<ExpandMoreIcon />}
                                    // defaultExpandIcon={<ChevronRightIcon />}
                                    selected={[]}
                                >
                                    {multiLevelFilteredSelectionKeys.map((node) =>
                                        renderTree(
                                            node,
                                            "items",
                                            (event, nodes) => {
                                                handleOutInfluSelect(
                                                    event,
                                                    nodes.pid,
                                                );
                                            },
                                            selectedOutInflu,
                                            true, true, expandedIds, setExpandedIds
                                        )
                                    )}
                                </TreeView>
                            )}
                        </Box>
                    </>
                )}
            </Box>
            {/* {modalType === INTEGRATINOS_TYPES.RESCUE_TIME && (
                <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} flexDirection={"column"} gap={"16px"}>
                    <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} alignItems={"center"} justifyContent={"space-between"} gap={"16px"}>
                        <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Show metrics in</CommonTypo>
                    </Box>
                    <div className={classes.tabBox} style={{
                        width: "100%"
                    }}>
                        <Box className={classes.tabClasses} width={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => {
                            setSelectedTab("percentage")
                        }} sx={{
                            background: selectedTab === "percentage" ? palette.common.white : "transparent",
                        }}>
                            <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "percentage" ? 600 : 400}>Percents</CommonTypo>
                        </Box>
                        <Box className={classes.tabClasses} width={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => {
                            setSelectedTab("hours")
                        }} sx={{
                            background: selectedTab === "hours" ? palette.common.white : "transparent"
                        }}>
                            <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "hours" ? 600 : 400}>Time</CommonTypo>
                        </Box>
                    </div>
                </Box>
            )} */}
            {modalType === INTEGRATINOS_TYPES.TOGGL && (
                <Box display={"flex"} flexBasis={1} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} padding={"16px"} sx={{
                    background: "var(--color-border)",
                    borderRadius: "12px"
                }}>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5625 16.125C11.5625 16.434 11.4709 16.7361 11.2992 16.9931C11.1275 17.25 10.8835 17.4503 10.5979 17.5686C10.3124 17.6868 9.99827 17.7178 9.69517 17.6575C9.39208 17.5972 9.11367 17.4484 8.89515 17.2299C8.67663 17.0113 8.52781 16.7329 8.46752 16.4298C8.40723 16.1267 8.43818 15.8126 8.55644 15.5271C8.6747 15.2415 8.87497 14.9975 9.13192 14.8258C9.38887 14.6541 9.69097 14.5625 10 14.5625C10.4144 14.5625 10.8118 14.7271 11.1049 15.0201C11.3979 15.3132 11.5625 15.7106 11.5625 16.125ZM10 13C10.2486 13 10.4871 12.9012 10.6629 12.7254C10.8387 12.5496 10.9375 12.3111 10.9375 12.0625V4.25C10.9375 4.00136 10.8387 3.7629 10.6629 3.58709C10.4871 3.41127 10.2486 3.3125 10 3.3125C9.75136 3.3125 9.5129 3.41127 9.33709 3.58709C9.16127 3.7629 9.0625 4.00136 9.0625 4.25V12.0625C9.0625 12.3111 9.16127 12.5496 9.33709 12.7254C9.5129 12.9012 9.75136 13 10 13Z" fill="#656565" />
                    </svg>
                    <CommonTypo size={12} lineHeight={14.4} fontWeight={400} color="var(--color-text-secondary)">We will import “Clients” as categories and “Projects” as influences</CommonTypo>
                </Box>
            )}
            <Box display={"flex"} flex={1} width={"100%"} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                    <NewButton
                        buttonText="Next"
                        onClick={() => {
                            if (modalType === INTEGRATINOS_TYPES.RESCUE_TIME) updateRescueTime(selectedTab, checkedKeys, closemodal)
                            if (modalType === INTEGRATINOS_TYPES.TOGGL) {
                                const selectedArr = []
                                if (selectedOutInflu && selectedOutInflu.length) {
                                    const allChinds = []

                                    for (let index = 0; index < multiLevelSelectionKeys.length; index++) {
                                        const element = multiLevelSelectionKeys[index];
                                        console.log("selectedArr element", element);

                                        allChinds.push(...element.items)
                                    }
                                    console.log(" selectedArr allChinds", allChinds);
                                    for (let index = 0; index < allChinds.length; index++) {
                                        const element = allChinds[index];
                                        if (selectedOutInflu.includes(element.pid)) {
                                            selectedArr.push({
                                                pid: `${element.pid}`,
                                                project_name: element.name
                                            })
                                        }
                                    }
                                }
                                updateToggl(selectedArr, closemodal)
                            }

                            if (modalType === INTEGRATINOS_TYPES.OURA) {
                                const selectedArr = []
                                if (selectedOutInflu && selectedOutInflu.length) {
                                    for (let index = 0; index < multiLevelSelectionKeys.length; index++) {
                                        const element = multiLevelSelectionKeys[index];
                                        const filteredItems = [...element.items].filter(it => selectedOutInflu.includes(it.pid))
                                        if (filteredItems && filteredItems.length) {
                                            selectedArr.push({
                                                group: element.pid,
                                                keys: filteredItems.map(it => ({
                                                    key: it.original,
                                                    value: it.value
                                                }))
                                            })
                                        }
                                    }
                                }
                                updateOura(selectedArr, closemodal)
                            }
                            if (modalType === INTEGRATINOS_TYPES.GARMIN) {
                                const selectedArr = []
                                if (selectedOutInflu && selectedOutInflu.length) {
                                    for (let index = 0; index < multiLevelSelectionKeys.length; index++) {
                                        const element = multiLevelSelectionKeys[index];
                                        const filteredItems = [...element.items].filter(it => selectedOutInflu.includes(it.pid))
                                        if (filteredItems && filteredItems.length) {
                                            selectedArr.push(...filteredItems.map(it => it.original))
                                        }
                                    }
                                }
                                updateGarmin(selectedArr, closemodal)
                            }

                        }}
                        extraSx={{
                            background: "var(--color-new-main)",
                            border: "none",
                            width: "102px",
                            height: "43px",
                            flex: 1,
                            marginBottom: "0px !important",
                            "&:hover": {
                                background: "var(--color-new-main)"
                            },
                            "&.MuiButton-outlined": {
                                border: `none`,
                            },
                            "&.MuiButton-contained": {
                                border: `none`,
                            },
                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                            fontSize: "16px !important",
                            lineHeight: "16px !important",
                            fontWeight: `600 !important`,
                            letterSpacing: "2% important"
                        }}
                        boxSx={{
                            padding: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        isDisabled={disabledNextButton}
                    />
                </Box>


            </Box>
        </Dialog>
    </>
}

export default SelectionDialogue

const SelectionCard = ({
    selected = false,
    title = "",
    value = null,
    showBorder = true,
    onSelect = (key, checked) => { }
}) => {
    return <Box display={"flex"} borderRadius={"0px"} padding={"16px"} sx={{
        minWidth: "261.5px",
        height: "48px",
    }} alignItems={"center"} borderBottom={showBorder ? "1px solid var(--color-border)" : ""}>
        <Box display={"flex"} alignItems={"center"} gap={"12px"} sx={{
            borderRadius: "8px",
            height: "36px",
            cursor: "pointer"
        }} onClick={event => {
            onSelect(value, !selected)
        }}>
            <Box width={"16px"} height={"16px"} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={event => {
                event.stopPropagation()
            }}>
                <Checkbox
                    sx={{
                        padding: 0
                    }}
                    icon={
                        <svg width="14" height="14" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.5625 3.75V26.25C28.5625 26.9959 28.2662 27.7113 27.7387 28.2387C27.2113 28.7662 26.4959 29.0625 25.75 29.0625H3.25C2.50408 29.0625 1.78871 28.7662 1.26126 28.2387C0.733816 27.7113 0.4375 26.9959 0.4375 26.25V3.75C0.4375 3.00408 0.733816 2.28871 1.26126 1.76126C1.78871 1.23382 2.50408 0.9375 3.25 0.9375H25.75C26.4959 0.9375 27.2113 1.23382 27.7387 1.76126C28.2662 2.28871 28.5625 3.00408 28.5625 3.75ZM25.1875 4.3125H3.8125V25.6875H25.1875V4.3125Z" fill="#292B2A" />
                        </svg>
                    }
                    checkedIcon={
                        <svg width="14" height="14" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.18109 17.3189C8.02412 17.1619 7.89961 16.9756 7.81465 16.7705C7.7297 16.5654 7.68598 16.3456 7.68598 16.1236C7.68598 15.9016 7.7297 15.6818 7.81465 15.4767C7.89961 15.2716 8.02412 15.0853 8.18109 14.9283C8.33806 14.7713 8.52442 14.6468 8.72951 14.5618C8.9346 14.4769 9.15442 14.4332 9.37641 14.4332C9.5984 14.4332 9.81821 14.4769 10.0233 14.5618C10.2284 14.6468 10.4147 14.7713 10.5717 14.9283L12.75 17.1094L19.4311 10.4269C19.7481 10.1099 20.1781 9.93176 20.6264 9.93176C21.0747 9.93176 21.5047 10.1099 21.8217 10.4269C22.1387 10.7439 22.3168 11.1739 22.3168 11.6222C22.3168 12.0705 22.1387 12.5005 21.8217 12.8175L13.9467 20.6925C13.7899 20.8498 13.6037 20.9746 13.3985 21.0598C13.1934 21.145 12.9735 21.1888 12.7514 21.1888C12.5293 21.1888 12.3094 21.145 12.1043 21.0598C11.8992 20.9746 11.7129 20.8498 11.5561 20.6925L8.18109 17.3189ZM29.0625 3.75V26.25C29.0625 26.9959 28.7662 27.7113 28.2387 28.2387C27.7113 28.7662 26.9959 29.0625 26.25 29.0625H3.75C3.00408 29.0625 2.28871 28.7662 1.76126 28.2387C1.23382 27.7113 0.9375 26.9959 0.9375 26.25V3.75C0.9375 3.00408 1.23382 2.28871 1.76126 1.76126C2.28871 1.23382 3.00408 0.9375 3.75 0.9375H26.25C26.9959 0.9375 27.7113 1.23382 28.2387 1.76126C28.7662 2.28871 29.0625 3.00408 29.0625 3.75ZM25.6875 4.3125H4.3125V25.6875H25.6875V4.3125Z" fill="#292B2A" />
                        </svg>

                    }
                    checked={selected}
                    onChange={event => {
                        onSelect(value, event.target.checked)
                    }}
                />
            </Box>
            <CommonTypo size={14} lineHeight={18.2} color="#292B2A" fontWeight={400}>{title}</CommonTypo>
        </Box>
    </Box>
}