import React, { useState } from "react"
import { Box, Dialog, Radio } from "@mui/material"
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import CommonTypo from "../../../components/CommonTypo"
import CloseButton from "../../../components/CloseButton"
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Exclamation from "../../../assets/images/icon/Exclamation";
import NewButton from "../../../components/NewButton";
import { INTEGRATINOS_TYPES } from "../integrationsConstants";
import { integrationsService } from "../../../services/integrations";
import SuccessDialogue from "./successDialogue";
import ConnectingIcon from "../../../assets/images/icon/integration/connecting";

const StyledFormControlLabel = styled((props) => <FormControlLabel control={undefined} label={""} {...props} />)(
    ({ theme }) => ({
        variants: [
            {
                props: { checked: true },
                style: {
                    '.MuiFormControlLabel-label': {
                        color: "#292B2A",
                    },
                },
            },
        ],
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel color="#292B2A" checked={checked} {...props} />;
}

const DisconnectModal = ({
    isMobile,
    onClose,
    openModal,
    modalType,
    getRescueTime,
    getTogglTime,
    outcomeState,
    setModalType,
    getOURATime,
    getGarminTime,
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType,
    setmsgIcon,
    setPreventAutoHide
}) => {
    const [selectedValue, setSelectedValue] = useState("DELETE_ALL")
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const closemodal = () => {
        onClose()
    }
    const onClickDisconnect = () => {
        if (modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
            closemodal()
            setShowSnakbar(true)
            setPreventAutoHide(true)
            setmsgIcon(<ConnectingIcon />)
            setSnakbarMsg("Removing Rescue Time")
            setMsgType("info")
            integrationsService.disconnectRescueTime({
                actionType: selectedValue
            }, outcomeState.token).then(data => {
                integrationsService.updateKeyRescueTime({
                    apiKey: null
                }, outcomeState.token).then(() => {
                    setShowSnakbar(false)
                    setPreventAutoHide(false)
                    setmsgIcon()
                    setSnakbarMsg(null)
                    setMsgType("")
                    setOpenSuccessModal(true)
                }).catch(() => { })

            }).catch(err => {
                console.log("err", err)
            })
        }
        if (modalType === INTEGRATINOS_TYPES.TOGGL) {
            closemodal()
            setShowSnakbar(true)
            setPreventAutoHide(true)
            setmsgIcon(<ConnectingIcon />)
            setSnakbarMsg("Removing Toggl")
            setMsgType("info")
            integrationsService.disconnectToggl({
                actionType: selectedValue
            }, outcomeState.token).then(data => {
                integrationsService.updateKeyToogl({
                    apiKey: null
                }, outcomeState.token).then(() => {
                    setShowSnakbar(false)
                    setPreventAutoHide(false)
                    setmsgIcon()
                    setSnakbarMsg(null)
                    setMsgType("")
                    setOpenSuccessModal(true)
                }).catch(() => { })

            }).catch(err => {
                console.log("err", err)
            })
        }
        if (modalType === INTEGRATINOS_TYPES.OURA) {
            closemodal()
            setShowSnakbar(true)
            setPreventAutoHide(true)
            setmsgIcon(<ConnectingIcon />)
            setSnakbarMsg("Removing Oura")
            setMsgType("info")
            integrationsService.disconnectOURA({
                actionType: selectedValue
            }, outcomeState.token).then(data => {
                integrationsService.updateKeyOURA({
                    apiKey: null
                }, outcomeState.token).then(() => {
                    setShowSnakbar(false)
                    setPreventAutoHide(false)
                    setmsgIcon()
                    setSnakbarMsg(null)
                    setMsgType("")
                    setOpenSuccessModal(true)
                }).catch(() => { })

            }).catch(err => {
                console.log("err", err)
            })
        }
        if (modalType === INTEGRATINOS_TYPES.GARMIN) {
            closemodal()
            setShowSnakbar(true)
            setPreventAutoHide(true)
            setmsgIcon(<ConnectingIcon />)
            setSnakbarMsg("Removing Garmin")
            setMsgType("info")
            integrationsService.disconnectGarmin({
                actionType: selectedValue
            }, outcomeState.token).then(data => {
                setShowSnakbar(false)
                setPreventAutoHide(false)
                setmsgIcon()
                setSnakbarMsg(null)
                setMsgType("")
                setOpenSuccessModal(true)
            }).catch(err => {
                console.log("err", err)
            })
        }
    }
    let paperStyles: any = {
        display: 'flex !important',
        padding: '32px 24px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '24px !important',
        minWidth: "360px",
        maxWidth: "360px",
        borderRadius: "12px"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    return <>
        <Dialog
            onClose={closemodal}
            open={openModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} alignItems={"flex-start"} gap={"8px"} >
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} flexBasis={"90%"}>
                    <CommonTypo size={24} fontWeight={700} lineHeight={28.8} color="var(--color-text-primary)">What exactly do you want to do?</CommonTypo>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} flexBasis={"10%"} position={"relative"}>
                    <CloseButton onClick={closemodal} boxStyle={{
                        display: "flex",
                        position: "absolute"
                    }} />
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} flexBasis={"70%"}>
                <CommonTypo size={16} lineHeight={25.6} color="var(--color-text-secondary)">There's two way of removing the integration:</CommonTypo>
            </Box>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} alignSelf={"stretch"}>
                <Box display={"flex"} alignItems={"center"} gap={"12px"} sx={{
                    borderRadius: "8px",
                }} alignSelf={"stretch"}>
                    <RadioGroup name="use-radio-group" defaultValue="DELETE_ALL" sx={{
                        gap: "8px"
                    }} value={selectedValue} onChange={e => {
                        setSelectedValue(e.target.value)
                    }}>
                        <MyFormControlLabel value="DELETE_ALL" label={<CommonTypo size={16} lineHeight={25.6} color="#292B2A">Stop syncing and <strong>delete</strong> all previously synced data </CommonTypo>} control={<Radio
                            disableRipple
                            color="default"
                            checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM17.25 12C17.25 13.0384 16.9421 14.0534 16.3652 14.9167C15.7883 15.7801 14.9684 16.453 14.0091 16.8504C13.0498 17.2477 11.9942 17.3517 10.9758 17.1491C9.95738 16.9466 9.02192 16.4465 8.28769 15.7123C7.55347 14.9781 7.05345 14.0426 6.85088 13.0242C6.64831 12.0058 6.75228 10.9502 7.14964 9.99091C7.547 9.0316 8.2199 8.21166 9.08326 7.63478C9.94662 7.05791 10.9617 6.75 12 6.75C13.3919 6.75149 14.7264 7.30509 15.7107 8.28933C16.6949 9.27358 17.2485 10.6081 17.25 12Z" fill="#292B2A" />
                            </svg>
                            }
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96452 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46927 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25Z" fill="#292B2A" />
                            </svg>
                            }
                        />} />
                        <MyFormControlLabel value="PAUSE" label={<CommonTypo size={16} lineHeight={25.6} color="#292B2A">Stop syncing from now on but <strong>keep</strong> all previously synced data</CommonTypo>} control={<Radio
                            disableRipple
                            color="default"
                            checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM17.25 12C17.25 13.0384 16.9421 14.0534 16.3652 14.9167C15.7883 15.7801 14.9684 16.453 14.0091 16.8504C13.0498 17.2477 11.9942 17.3517 10.9758 17.1491C9.95738 16.9466 9.02192 16.4465 8.28769 15.7123C7.55347 14.9781 7.05345 14.0426 6.85088 13.0242C6.64831 12.0058 6.75228 10.9502 7.14964 9.99091C7.547 9.0316 8.2199 8.21166 9.08326 7.63478C9.94662 7.05791 10.9617 6.75 12 6.75C13.3919 6.75149 14.7264 7.30509 15.7107 8.28933C16.6949 9.27358 17.2485 10.6081 17.25 12Z" fill="#292B2A" />
                            </svg>
                            }
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96452 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46927 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25Z" fill="#292B2A" />
                            </svg>
                            }
                        />} />
                    </RadioGroup>

                    {/* <CommonTypo size={16} lineHeight={16} color="var(--color-text-primary)" fontWeight={500}>{title}</CommonTypo> */}
                </Box>
            </Box>
            <Box display={"flex"} gap={"8px"} padding={"16px"} borderRadius={"12px"} alignItems={"flex-start"} sx={{
                background: "var(--color-border)"
            }}>
                <Box height={"20px"} width={"20px"}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5625 15.625C11.5625 15.934 11.4709 16.2361 11.2992 16.4931C11.1275 16.75 10.8835 16.9503 10.5979 17.0686C10.3124 17.1868 9.99827 17.2178 9.69517 17.1575C9.39208 17.0972 9.11367 16.9484 8.89515 16.7299C8.67663 16.5113 8.52781 16.2329 8.46752 15.9298C8.40723 15.6267 8.43818 15.3126 8.55644 15.0271C8.6747 14.7415 8.87497 14.4975 9.13192 14.3258C9.38887 14.1541 9.69097 14.0625 10 14.0625C10.4144 14.0625 10.8118 14.2271 11.1049 14.5201C11.3979 14.8132 11.5625 15.2106 11.5625 15.625ZM10 12.5C10.2486 12.5 10.4871 12.4012 10.6629 12.2254C10.8387 12.0496 10.9375 11.8111 10.9375 11.5625V3.75C10.9375 3.50136 10.8387 3.2629 10.6629 3.08709C10.4871 2.91127 10.2486 2.8125 10 2.8125C9.75136 2.8125 9.5129 2.91127 9.33709 3.08709C9.16127 3.2629 9.0625 3.50136 9.0625 3.75V11.5625C9.0625 11.8111 9.16127 12.0496 9.33709 12.2254C9.5129 12.4012 9.75136 12.5 10 12.5Z" fill="#656565" />
                    </svg>
                </Box>

                <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-secondary)">If you want to add or remove some metrics you can click “Edit” instead of removing the whole integration</CommonTypo>
            </Box>
            <Box display={"flex"} flex={1} width={"100%"} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                    <NewButton
                        buttonText="Next"
                        onClick={onClickDisconnect}
                        extraSx={{
                            background: "var(--color-new-main)",
                            border: "none",
                            width: "102px",
                            height: "43px",
                            flex: 1,
                            marginBottom: "0px !important",
                            "&:hover": {
                                background: "var(--color-new-main)"
                            },
                            "&.MuiButton-outlined": {
                                border: `none`,
                            },
                            "&.MuiButton-contained": {
                                border: `none`,
                            },
                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                            fontSize: "16px !important",
                            lineHeight: "16px !important",
                            fontWeight: `600 !important`,
                            letterSpacing: "2% important"
                        }}
                    />
                </Box>


            </Box>
        </Dialog>
        <SuccessDialogue isEditMode={false} isDelete={true}
            isMobile={isMobile} modalType={modalType} openModal={openSuccessModal} onClose={() => {

                setOpenSuccessModal(false)
                if (modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
                    getRescueTime()
                }
                if (modalType === INTEGRATINOS_TYPES.TOGGL) {
                    getTogglTime()
                }
                if (modalType === INTEGRATINOS_TYPES.OURA) {
                    getOURATime()
                }
                if (modalType === INTEGRATINOS_TYPES.GARMIN) {
                    getGarminTime()
                }
            }} setModalType={setModalType} deleteAll={selectedValue === "DELETE_ALL"} />
    </>

}

export default DisconnectModal