import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Typography,
  Grid,
  SvgIcon,
  TextField,
  IconButton,
  Box,
  styled,
  AccordionDetails,
  TextareaAutosize,
} from "@mui/material";
import * as icons from "@fortawesome/free-solid-svg-icons";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import ThreeDotsLarge from "../../assets/images/icon/ThreeDotsLarge";
import { useDrop, useDrag } from "react-dnd";
import shape from "../../theme/shape";
import InfluenceTickIcon from "../../assets/images/icon/influenceTickIcon";
import {
  CategoryGetList,
  InfluenceGetList,
  OutcomeGetList,
  ReflectiveGetList,
} from "../../interfaces/outcomeModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ArrowUp from "../../assets/images/icon/arrow_up";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fixOutcomeIcon } from "../../utils/staticDataJson";

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    border: "1px solid #E3E3E3",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  customIcon: {
    transform: 'scale(1.5, 1.5)',
    "& path": {
      fill: "var(--white-background)"
    }
  },
  textAreaClase: {
    fontSize: "1.375rem !important",
    maxWidth: "327px",
    fontWeight: 600,
    outline: "none",
    border: "none",
    background: "transparent",
    marginTop: "12px !important",
    marginBottom: "50px !important",
    WebkitTextFillColor: "#000 !important",
    // "& .MuiOutlinedInput-input": {
    //   paddingTop: "12px !important",
    //   paddingBottom: "22px !important",
    //   WebkitTextFillColor: "#000 !important",
    // },
  },
  colorCode: {
    width: "36px",
    height: "36px",
    padding: "8px",
    borderRadius: shape.borderRadiusSm,
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
      padding: "3px",
    },
  },
  influenceIcon: {
    verticalAlign: "text-top",
    marginRight: "12px",
    display: "inline-flex",
  },
  dropShadow: {
    border: "1.5px dashed #11734A",
    backgroundColor: "rgba(17, 115, 74, 0.05)",
    borderRadius: "8px",
    height: "62px",
  },
  dropShadowCategory: {
    // "&:hover": {
    border: "1.5px dashed #11734A",
    backgroundColor: "rgba(17, 115, 74, 0.05)",
    borderRadius: "8px",
    height: "fit-content",
    // }
  },
  dropMenu: {
    "& .MuiPaper-root": {
      border: "1px solid #DCE6E0",
      overflow: "visible",
      marginTop: "4px",
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: "42%",
        width: 15,
        height: 15,
        backgroundColor: "#f7f9fa",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 1,
        borderTop: "1px solid #DCE6E0",
        borderLeft: "1px solid #DCE6E0",
      },
    },
  },
  categoryHeading: {
    marginBottom: "0 !important",
    "& .MuiOutlinedInput-input": {
      padding: "0 !important",
      margin: "0",
      fontSize: "1.375rem !important",
      color: "#3E4240 !important",
      fontWeight: "600 !important",
      maxWidth: "327px",
    },
    "& input:disabled": {
      color: "#000 !important",
    },
    "& .Mui-disabled": {
      color: "#000 !important",
    },
  },
}));

interface CategoryItemModel {
  addItem: any;
  category: CategoryGetList;
  onRenameCategory: any;
  onDelete: any;
  onDeleteItem: any;
  // movePetListItem: any;
  setAddCategoryList: any;
  addCategoryList: any;
  index: any;
  moveCategoryItem: any;
  setIsApiCall?: any;
  expandedList?: any[]
  expanded?: boolean
  iconPack?: any[]
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(180deg)",
    marginLeft: "10px",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

const CategoryItem = (props: CategoryItemModel) => {
  const classes = useStyles();
  const { expanded: categoryExpand = false } = props
  const [isRename, setIsRename] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [
        "The Outcomes",
        "The Influences",
        "Your Journaling Questions",
        "All",
      ],
      drop: props.addItem,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.category, props.addItem]
  );

  const movePetListItem = useCallback(
    (dragIndex, hoverIndex, id) => {
      if (
        id === "OUTCOME" &&
        dragIndex !== undefined &&
        hoverIndex !== undefined
      ) {
        const dragItem = props.category.outcomes[dragIndex];
        const hoverItem = props.category.outcomes[hoverIndex];
        // Swap places of dragItem and hoverItem in the pets array
        props.setAddCategoryList((prevState) => {
          const newUpdatedOutcome = prevState?.map((item) => {
            if (item?.categoryId === props?.category?.categoryId) {
              const updatedOutcome = [...item?.outcomes]?.filter(
                (array) => array !== undefined
              );
              if (dragIndex !== hoverIndex) {
                updatedOutcome[dragIndex] = hoverItem;
                updatedOutcome[hoverIndex] = dragItem;
              }
              const updatedOutcomeIds = updatedOutcome
                ?.filter((array) => array !== undefined)
                .map((element) => element?.outcomeId);
              return {
                ...item,
                outcomeIds: updatedOutcomeIds,
                outcomes: updatedOutcome?.filter(
                  (array) => array !== undefined
                ),
              };
            } else {
              return item;
            }
          });
          return newUpdatedOutcome;
        });
      } else if (
        id === "INFLUENCES" &&
        dragIndex !== undefined &&
        hoverIndex !== undefined
      ) {
        const dragItem = props.category.influences[dragIndex];
        const hoverItem = props.category.influences[hoverIndex];
        // Swap places of dragItem and hoverItem in the pets array
        props.setAddCategoryList((prevState) => {
          const newUpdatedInfluences = prevState?.map((item) => {
            if (item?.categoryId === props?.category?.categoryId) {
              const updatedInfluences = [...item?.influences].filter(
                (array) => array !== undefined
              );
              if (dragIndex !== hoverIndex) {
                updatedInfluences[dragIndex] = hoverItem;
                updatedInfluences[hoverIndex] = dragItem;
              }
              const updatedInfluencesIds = updatedInfluences
                ?.filter((array) => array !== undefined)
                .map((element) => element?.influenceId);
              return {
                ...item,
                influenceIds: updatedInfluencesIds,
                influences: updatedInfluences?.filter(
                  (array) => array !== undefined
                ),
              };
            } else {
              return item;
            }
          });
          return newUpdatedInfluences;
        });
      } else if (
        id === "REFLECTIVE" &&
        dragIndex !== undefined &&
        hoverIndex !== undefined
      ) {
        const dragItem = props.category.reflectives[dragIndex];
        const hoverItem = props.category.reflectives[hoverIndex];
        // Swap places of dragItem and hoverItem in the pets array
        props.setAddCategoryList((prevState) => {
          const newUpdatedReflectives = prevState?.map((item) => {
            if (item?.categoryId === props?.category?.categoryId) {
              const updatedReflectives = [...item?.reflectives].filter(
                (array) => array !== undefined
              );
              if (dragIndex !== hoverIndex) {
                updatedReflectives[dragIndex] = hoverItem;
                updatedReflectives[hoverIndex] = dragItem;
              }
              const updatedReflactiveIds = updatedReflectives
                ?.filter((array) => array !== undefined)
                .map((element) => element?.reflectiveId);
              return {
                ...item,
                reflectiveIds: updatedReflactiveIds,
                reflectives: updatedReflectives?.filter(
                  (array) => array !== undefined
                ),
              };
            } else {
              return item;
            }
          });
          return newUpdatedReflectives;
        });
      } else if (
        id === "ALL" &&
        dragIndex !== undefined &&
        hoverIndex !== undefined
      ) {
        const dragItem = props.category.alls[dragIndex];
        const hoverItem = props.category.alls[hoverIndex];
        // Swap places of dragItem and hoverItem in the pets array
        props.setAddCategoryList((prevState) => {
          const newUpdatedAlls = prevState?.map((item) => {
            if (item?.categoryId === props?.category?.categoryId) {
              const updatedAlls = [...item?.alls].filter(
                (array) => array !== undefined
              );
              if (dragIndex !== hoverIndex) {
                updatedAlls[dragIndex] = hoverItem;
                updatedAlls[hoverIndex] = dragItem;
              }
              const updatedAllIds = updatedAlls
                ?.filter((array) => array !== undefined)
                .map(
                  (element) =>
                    element?.outcomeId ??
                    element?.influenceId ??
                    element?.reflectiveId
                );
              return {
                ...item,
                allIds: updatedAllIds,
                alls: updatedAlls?.filter(
                  (array) => array !== undefined && array !== ""
                ),
              };
            } else {
              return item;
            }
          });
          return newUpdatedAlls;
        });
      } else {
        return;
      }
    },
    [props.addCategoryList]
  );

  const [expanded, setExpanded] = useState<boolean>(true);
  const handleChange = (expanded: boolean) => () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    if (props.expandedList.includes(props?.category?.categoryId)) {
      setExpanded(true)
    } else {
      setExpanded(false)

    }
  }, [props.expandedList])
  return (
    <Grid
      item
      ref={drop}
      key={props?.category?.categoryId}
      id={props?.category?.categoryId}
      xs={12}
      sx={{
        pb: categoryExpand ? 0 : 2,
      }}
    >
      <Droppable droppableId={props?.category?.categoryId}>
        {(provided) => (
          <Grid item {...provided.droppableProps} ref={provided.innerRef}>
            <Accordion
              sx={{ marginBottom: categoryExpand ? "0px" : "40px", }}
              elevation={0}
              expanded={expanded}
              onChange={handleChange(expanded)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "inherit",
                      justifyContent: "space-between",
                      pb: isRename ? 2 : 2,
                      pt: "15px",
                    }}
                  >
                    {isRename ? (
                      <TextareaAutosize
                        defaultValue={props?.category?.name}
                        className={`${classes.categoryHeading} ${classes.textAreaClase}`}
                        autoFocus
                        onBlur={(e) =>
                          props.onRenameCategory(
                            e.target.value,
                            props?.category?.categoryId
                          )
                        }
                        minRows={1}
                        maxRows={5}
                      />
                    ) : (
                      <TextareaAutosize
                        defaultValue={props?.category?.name}
                        className={`${classes.categoryHeading} ${classes.textAreaClase}`}
                        autoFocus
                        onBlur={(e) =>
                          props.onRenameCategory(
                            e.target.value,
                            props?.category?.categoryId
                          )
                        }
                        disabled
                        minRows={1}
                        maxRows={5}
                      />
                    )}
                    <div onClick={handleClick} style={{ cursor: "pointer" }}>
                      <ThreeDotsLarge />
                    </div>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      className={classes.dropMenu}
                      PaperProps={{
                        elevation: 0,
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setIsRename(true);
                          handleClose();
                        }}
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          props.onDelete(props?.category?.categoryId);
                          handleClose();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <MuiAccordionSummary
                    expandIcon={<ArrowUp />}
                    {...props}
                    style={{ padding: "0px" }}
                  />
                </div>
              </div>
              {expanded
                ? props?.category?.alls &&
                props?.category?.alls
                  .filter((value, index) => {
                    const _value = JSON.stringify(value);
                    return (
                      index ===
                      props?.category?.alls.findIndex((obj) => {
                        return JSON.stringify(obj) === _value;
                      })
                    );
                  })
                  .map((all: InfluenceGetList, index) => (
                    <DragAll
                      key={index}
                      index={index}
                      categoryId={props?.category?.categoryId}
                      all={all}
                      iconPack={props.iconPack}
                      onDelete={props.onDeleteItem}
                      movePetListItem={movePetListItem}
                    />
                  ))
                : null}
            </Accordion>
            {isOver && (
              <Grid item xs={12} className={classes.dropShadow}></Grid>
            )}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </Grid>
  );
};

const CategoryItemWrapper = (props: CategoryItemModel) => {
  const classes = useStyles();
  const [iconPack, setIconPack] = useState([]);
  useEffect(() => {
    const copyIcon = [];
    Object.keys(icons).map((icon) => {
      copyIcon.push(icons[icon]);
      return icon;
    });
    setIconPack(copyIcon);
  }, [])
  const [dropElementId, setDropElementId] = useState("");
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Category",
      item: props.category,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [props.category]
  );

  // const [, drop] = useDrop(
  //   (): any => ({
  //     accept: ["Category"],
  //     drop: (args) => {console.log(">>>>>>>>>,args",args)},
  //     collect: (monitor) => ({
  //       isOver: !!monitor.isOver(),
  //     }),
  //   }),
  //   [props.category]
  // );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "Category",
    // hover: (item:any,monitor) => {
    //   console.log("hover",item)
    //   // setDropElementId(props?.category?.categoryId)
    //   monitor.isOver({ shallow: true })
    // },
    drop: (item: any, monitor: any) => {
      const dragIndex = item?.arrayIndex;
      const hoverIndex = props?.index;
      // setDropElementId(item?.categoryId)
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      props.moveCategoryItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  let dropClass = "";
  if (isActive) {
    dropClass = "dropShadowCategory";
  } else if (canDrop) {
    dropClass = "";
  }

  const dndRef = useRef(null);
  const dragDropRef: any = drag(drop(dndRef));

  return (
    <>
      <Grid
        item
        ref={dragDropRef}
        key={props?.category?.categoryId}
        xs={5.26}
        className={dropClass}
        // className={ isDragging && (dropElementId === props?.category?.categoryId) && classes.dropShadowCategory}
        sx={{
          pb: props?.expanded ? 0 : 5,
          border: "1.5px solid transparent",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "inherit",
            justifyContent: "space-between",
          }}
        >
          <CategoryItem {...props} iconPack={iconPack} />
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryItemWrapper;

export const DragOutcome = (props: any) => {
  // console.log("hellllllo35436",props?.outcomes)
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "The Outcomes",
      item: props.outcome,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.outcome]
  );

  const [spec, drop] = useDrop({
    accept: "The Outcomes",
    hover: (item: any, monitor: any) => {
      // console.log("deop")
      const dragIndex = item?.index;
      const hoverIndex = props?.index;
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      props.movePetListItem(dragIndex, hoverIndex, "OUTCOME");
      item.index = hoverIndex;
    },
  });

  const dndRef = useRef(null);
  const dragDropRef: any = drag(drop(dndRef));
  return (
    // <p>test</p>
    <Box
      ref={dragDropRef}
      key={props.outcome?._id}
      className={classes.recommText}
    >
      <span
        className={classes.colorCode}
        style={{
          backgroundColor: props?.outcome?.options[0]?.colorCode,
        }}
      >
        <SvgIcon
          color="action"
          fontSize="small"
          sx={{ verticalAlign: "middle" }}
        >
          <path d={props.outcome?.icon} fill={palette.common.white} />
        </SvgIcon>
      </span>
      {props.outcome?.name}
      <IconButton
        component="label"
        size="small"
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          props.onDelete(props.categoryId, props.outcome?.outcomeId, "OUTCOME");
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export const DragInfluence = (props: any) => {
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "The Influences",
      item: props.influence,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.influence]
  );

  const [spec, drop] = useDrop({
    accept: "The Influences",
    hover: (item: any, monitor: any) => {
      // console.log("deop")
      const dragIndex = item?.index;
      const hoverIndex = props?.index;
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      props.movePetListItem(dragIndex, hoverIndex, "INFLUENCES");
      item.index = hoverIndex;
    },
  });

  const dndRef = useRef(null);
  const dragDropRef: any = drag(drop(dndRef));

  return (
    // <p>test</p>
    <Box
      ref={dragDropRef}
      key={props.influence?._id}
      className={classes.recommText}
    >
      <span className={classes.influenceIcon}>
        <InfluenceTickIcon />
      </span>
      {props.influence?.name}
      <IconButton
        component="label"
        size="small"
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          props.onDelete(
            props.categoryId,
            props.influence?.influenceId,
            "INFLUENCE"
          );
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export const DragReflectives = (props: any) => {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Your Journaling Questions",
      item: props.reflective,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.reflective]
  );

  const [spec, drop] = useDrop({
    accept: "Your Journaling Questions",
    hover: (item: any, monitor: any) => {
      // console.log("deop")
      const dragIndex = item?.index;
      const hoverIndex = props?.index;
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      props.movePetListItem(dragIndex, hoverIndex, "REFLECTIVE");
      item.index = hoverIndex;
    },
  });

  const dndRef = useRef(null);
  const dragDropRef: any = drag(drop(dndRef));

  return (
    <Box
      ref={dragDropRef}
      key={props?.reflective?._id}
      className={classes.recommText}
    >
      {props?.reflective?.question}
      <IconButton
        component="label"
        size="small"
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          props.onDelete(
            props.categoryId,
            props?.reflective?.reflectiveId,
            "REFLECTIVE"
          );
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export const DragAll = (props: any) => {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "All",
      item: props.all,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.all]
  );

  const [spec, drop] = useDrop({
    accept: [
      "The Outcomes",
      "The Influences",
      "Your Journaling Questions",
      "All",
    ],
    // hover: (item: any, monitor: any) => {
    //   // console.log("deop")
    //   const dragIndex = item?.index;
    //   const hoverIndex = props?.index;
    //   const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
    //   const hoverMiddleY =
    //     (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    //   const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

    //   // if dragging down, continue only when hover is smaller than middle Y
    //   // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
    //   // // if dragging up, continue only when hover is bigger than middle Y
    //   // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

    //   // props.movePetListItem(dragIndex, hoverIndex, "ALL");
    //   item.index = hoverIndex;
    // },
    // drop: (item: any, monitor: any) => {
    //   // console.log("deop")
    //   const dragIndex = item?.index;
    //   const hoverIndex = props?.index;
    //   const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
    //   const hoverMiddleY =
    //     (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    //   const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

    //   // if dragging down, continue only when hover is smaller than middle Y
    //   // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
    //   // // if dragging up, continue only when hover is bigger than middle Y
    //   // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

    //   props.movePetListItem(dragIndex, hoverIndex, "ALL");
    //   item.index = hoverIndex;
    // }
  });

  const dndRef = useRef(null);
  const dragDropRef: any = drag(drop(dndRef));
  const iconsFiltered = props?.iconPack?.filter((icon) => {
    return !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
      icon.iconName
    );
  });
  console.log("props.all", props.all)
  let foundIcon = null;
  if (props.all?.icon?.length < 50) {
    const found = iconsFiltered.find((it) => it.iconName === props.all?.icon);
    if (found) {
      foundIcon = found;
    }
  }
  const iconisDefault = props.all?.icon === fixOutcomeIcon[0].path
  return (
    <div ref={dragDropRef}>
      <AccordionDetails sx={{ padding: "0px" }}>
        <Draggable
          key={props?.all?._id}
          draggableId={props?.all?._id}
          index={props?.index}
        >
          {(provided) => (
            <Box
              key={props?.all?._id}
              className={classes.recommText}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {props?.all?.outcomeId ? (
                <>
                  <span
                    className={classes.colorCode}
                    style={{
                      backgroundColor: props?.all?.options[0]?.colorCode,
                    }}
                  >
                    {!foundIcon ? <SvgIcon
                      color="action"
                      fontSize="small"
                      sx={iconisDefault ? { verticalAlign: "middle", mt: 0.0 } : { verticalAlign: "middle" }}
                      fill={iconisDefault ? "var(--color-new-main)" : ""}
                    >
                      <path d={props.all?.icon} fill={palette.common.white} />
                    </SvgIcon> : foundIcon && (
                      <FontAwesomeIcon size="2xs" width={24} height={24} fill="var(--white-background)" icon={foundIcon} className={classes.customIcon} />
                    )}
                  </span>
                  {props.all?.name}
                  <IconButton
                    component="label"
                    size="small"
                    sx={{ position: "absolute", right: 2, top: 2 }}
                    onClick={() => {
                      props.onDelete(
                        props.categoryId,
                        props.all?.outcomeId,
                        "OUTCOME"
                      );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              ) : props?.all?.influenceId ? (
                <>
                  <span className={classes.influenceIcon}>
                    <InfluenceTickIcon />
                  </span>
                  {props.all?.name}
                  <IconButton
                    component="label"
                    size="small"
                    sx={{ position: "absolute", right: 2, top: 2 }}
                    onClick={() => {
                      props.onDelete(
                        props.categoryId,
                        props.all?.influenceId,
                        "INFLUENCE"
                      );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              ) : (
                <>
                  {props?.all?.question}
                  <IconButton
                    component="label"
                    size="small"
                    sx={{ position: "absolute", right: 2, top: 2 }}
                    onClick={() => {
                      props.onDelete(
                        props.categoryId,
                        props?.all?.reflectiveId,
                        "REFLECTIVE"
                      );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        </Draggable>
      </AccordionDetails>
    </div>
  );
};
