import { makeStyles } from "@material-ui/core";
import typography from "../../theme/typography";
const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "var(--color-new-main)",
        border: "2px solid var(--color-new-main)",
        color: "#fff !important",
        borderRadius: "8px",
        "& p": {
            color: "black !important"
        },
        "& h4": {
            color: "black !important"
        }
    },
    backgroundYesNo: {
        background: '#fff',
        width: '100%',
        height: '34px',
        borderRadius: '20px',

        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        padding: "8px 24px 8px 16px",
        gap: "4px",
    },
    rightActiveInverse: {
        background: "#FF5555",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    },
    rightActive: {
        background: "var(--color-new-main)",
        "& svg": {
            "& path": {
                strokeWidth: "1px",
                stroke: `var(--color-black) !important`
            }
        },
        "& .MuiTypography-root": {
            fontWeight: `600 !important`
        }
    },
    corssActive: {
        background: "#FF5555",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    },
    corssActiveInverse: {
        background: "var(--color-new-main)",
        "& svg": {
            "& path": {
                stroke: `var(--color-black) !important`
            }
        }
    },
    headerBlocks: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "12px 2px 12px 2px",
        height: "56px",
        gap: "4px",
        width: "44px",
        minWidth: "44px",
        "& p": {
            textAlign: "center",
            verticalAlign: "midle"
        },
        "& span": {
            fontWeight: 500,
        },
    },
    selectionExpanded: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '7px',
        width: '100%',

    },
    selection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
        width: '100%',

    },
    rectangleInputFull: {
        width: '100%',
        borderRadius: '12px',
        padding: '0px',
        display: 'flex',
        // background: '#F3F3F3',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        position: 'relative'
    },
    rectangleInput: {
        width: '36px',
        height: '36px',
        transform: 'rotate(-90deg)',
        flexShrink: 0,
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
    },
    counterView: {
        lineHeight: "125% !important"
    },
    inputContainer: {
        // padding: "5px",
        borderRadius: "999px",
        flexShrink: 0,
        height: "40px",
        width: "100%",
        display: "flex",
        // alignItems: 'center',
        justifyContent: "center",
        // paddingTop: "8px",
    },
    rateInput: {
        width: "100%",
        height: "34px",
        outline: "none",
        textAlign: "center",
        border: "none"
    },
    inputContainerFullWidth: {
        flexShrink: 0,
        width: "100%",
        display: "flex",
        // alignItems: 'center',
        justifyContent: "center",
        position: 'relative'
    },
    cardBody: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: 'column',
        gap: '8px',
        // justifyContent: "space-between",
        padding: "16px",
        borderRadius: "8px",
        // alignItems: "center",
        // marginBottom: "10px",
        // boxShadow: '0px 11px 30px -20px rgba(211, 218, 215, 0.23)',
        borderTop: "1px solid var(--color-border)"
    },
    cardBodyNew: {
        display: "flex",
        padding: "16px",
        flexDirection: 'column',
        gap: '8px',
        borderRadius: '0px',
        borderTop: "1px solid var(--color-border)"
    },
    clock: {
        position: "absolute",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        color: "var(--color-black)",
        fontWeight: 400
    },
    hand: {
        position: "absolute",
        width: "3px",
        margin: "auto",
        left: "0",
        bottom: "0",
        right: "0",
        backgroundColor: "#fff",
        transformOrigin: "bottom",
        borderRadius: "4px",
    },
    minute: {
        height: "14px",
        top: "-60%",
    },
    hour: {
        height: "10px",
        top: "-35%",
    },
    plusIcon: {
        fontSize: "42px",
        color: "#E3E3E3",
        fontFamily: typography.fontFamily,
        // marginTop: "8px",
        display: "flex",
        alignItems: "center",
    },
}))

export default useStyles