import { Box } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import CommonTypo from "../../components/CommonTypo"
import NewButton from "../../components/NewButton"
import RescueTime from "../../assets/images/icon/integration/rescutime"
import IntegrationDialogue from "./integrationDialogue"
import useWidth from "../../utils/useWidth"
import { UserTypeContext } from "../../context/userStatus"
import { integrationsService } from "../../services/integrations"
import InfluenceIcon from "../../assets/images/icon/ProgressInsights/InfluenceIcon"
import { INTEGRATINOS_TYPES } from "./integrationsConstants"
import DisconnectModal from "./integrationDialogue/disconnectModal"
import { IntegrationContext } from "../../context/userIntegrations"
import ConnectingIcon from "../../assets/images/icon/integration/connecting"
import { setConnectionName, setIntegrationEditMode, setIsConnecting } from "../../store/actions/integrationActions"
import OAuthConnectModal from "./integrationDialogue/oAuthConnectionModal"
import { useNavigate } from "react-router-dom"
let iscalled = false
const Integrations = ({
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType,
    setmsgIcon,
    setPreventAutoHide
}) => {
    const navigate = useNavigate()
    const width = useWidth();
    const [rescueResponse, setRescueResponse] = useState<any>({})
    const [togglResponse, setTogglResponse] = useState<any>({})
    const [ouraResponse, setOuraResponse] = useState<any>({})
    const [garminResponse, setGarminResponse] = useState<any>({})
    const [connections, setConnections] = useState<any>([])
    const { state: outcomeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const { state: integrationState, dispatch: integrationDispatch } = useContext(IntegrationContext)
    const [openDialogue, setOpenDialogue] = useState(false)
    const [openOAuthConnectionDialogue, setOpenOAuthConnectionDialogue] = useState(false)
    const [openDisconnectDialogue, setOpenDisconnectDialogue] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [openSelection, setOpenSelection] = useState(false)
    const [garminResponseFetched, setGraminResponseFetched] = useState(false)
    const [isEditMode, setEditMode] = useState(false)
    const getRescueTime = () => {
        integrationsService.getKeyRescueTime({}, outcomeState.token).then(data => {
            console.log("data", data)
            if (data.data && data.data) {
                setRescueResponse(data.data)

            }
        }).catch(err => {
            console.log("err", err)
        })
    }
    let openSelectionBox = localStorage.getItem("openSelectionBox")
    useEffect(() => {
        if (window.location.search && window.location.search.length) {
            const queryString = window.location.search.substring(1); // Remove the "?" at the start
            const queryParams: Record<string, string> = {};

            queryString.split("&").forEach((param) => {
                const [key, value] = param.split("=");
                queryParams[decodeURIComponent(key)] = decodeURIComponent(value || "");
            });
            if (queryParams?.oauth_verifier) {
                if (garminResponse?.oauthToken && garminResponseFetched && !iscalled) {
                    iscalled = true
                    integrationDispatch(setConnectionName(INTEGRATINOS_TYPES.GARMIN))
                    integrationDispatch(setIsConnecting(true))
                    setOpenOAuthConnectionDialogue(true)
                    integrationsService.getGarminAccessToken({
                        oauth_verifier: queryParams?.oauth_verifier
                    }, outcomeState.token).then(data => {
                        iscalled = false
                        // console.log("data", data);
                        // setOpenSelection(true)
                        // setModalType(INTEGRATINOS_TYPES.GARMIN)
                        // setOpenDialogue(false)
                        // integrationDispatch(setIsConnecting(false))
                        // setOpenOAuthConnectionDialogue(false)
                        // iscalled = false
                        localStorage.setItem("openSelectionBox", "true")
                        navigate('/setting')
                    }).catch(err => {
                        console.log("err", err)
                        setShowSnakbar(true)
                        setSnakbarMsg("We couln’t fetch data, try to sign in again")
                        setMsgType("error")
                        iscalled = false
                    })
                }

            }
            console.log("isEditMode garminResponse?.oauthToken", garminResponse?.oauthToken, garminResponseFetched, !garminResponse?.oauthToken && garminResponseFetched);
            
            // if ([1, "1"].includes(queryParams?.garminConnected)) {
            //     console.log("isEditMode garminResponse", garminResponse);
                
            //     if (garminResponse?.oauthToken && garminResponseFetched) {
            //         setOpenSelection(true)
            //         setModalType(INTEGRATINOS_TYPES.GARMIN)
            //         setOpenDialogue(false)
            //         integrationDispatch(setIsConnecting(false))
            //         setOpenOAuthConnectionDialogue(false)
            //         iscalled = false
            //     }

            // }
        } else if(openSelectionBox ===  "true") {
            setOpenSelection(true)
            setModalType(INTEGRATINOS_TYPES.GARMIN)
            setOpenDialogue(false)
            integrationDispatch(setIsConnecting(false))
            setOpenOAuthConnectionDialogue(false)
            localStorage.setItem("openSelectionBox", "false")
        }
    }, [garminResponse, garminResponseFetched, openSelectionBox])
    console.log("integrationState.isConnecting", integrationState.isConnecting);

    useEffect(() => {
        let name = ''
        if (integrationState.connectionName === INTEGRATINOS_TYPES.RESCUE_TIME) {
            name = 'Rescue Time'
        }
        if (integrationState.connectionName === INTEGRATINOS_TYPES.TOGGL) {
            name = 'Toggl'
        }
        if (integrationState.connectionName === INTEGRATINOS_TYPES.OURA) {
            name = 'Oura'
        }
        if (integrationState.connectionName === INTEGRATINOS_TYPES.GARMIN) {
            name = 'Garmin'
        }
        if (integrationState.isConnecting) {
            setShowSnakbar(true)
            setPreventAutoHide(true)
            setmsgIcon(<ConnectingIcon />)
            setSnakbarMsg(integrationState.isEditMode ? "Updating " + name : "Connecting " + name)
            setMsgType("info")
        } else {
            setShowSnakbar(false)
            setPreventAutoHide(false)
            setmsgIcon()
            setSnakbarMsg(null)
            setMsgType("")
        }
    }, [integrationState.isConnecting, integrationState.connectionName])
    const getTogglTime = () => {
        integrationsService.getKeyToggl({}, outcomeState.token).then(data => {
            console.log("getTogglTime data", data)
            if (data.data) {
                setTogglResponse(data.data)
            }
        }).catch(err => {
            console.log("err", err)
        })
    }
    const getOURATime = () => {
        integrationsService.getKeyOURA({}, outcomeState.token).then(data => {
            console.log("getOURATime data", data)
            if (data.data) {
                setOuraResponse(data.data)
            }
        }).catch(err => {
            console.log("err", err)
        })
    }
    const getGarminTime = () => {
        setGraminResponseFetched(false)
        integrationsService.getKeyGarmin({}, outcomeState.token).then(data => {
            console.log("getGarminTime data", data)
            setGraminResponseFetched(true)
            if (data.data) {
                setGarminResponse(data.data)
            }
        }).catch(err => {
            console.log("err", err)
        })
    }

    useEffect(() => {
        getRescueTime()
        getTogglTime()
        getOURATime()
        // getGarminTime()
    }, [])
    useEffect(() => {
        console.log("garminResponse", garminResponse);

        const conne = [
            {
                title: "Rescue Time",
                icon: <RescueTime />,
                onClick: (isConnected) => {
                    if (isConnected) {
                        setEditMode(true)
                        integrationDispatch(setIntegrationEditMode(true))
                        setModalType(INTEGRATINOS_TYPES.RESCUE_TIME)
                        setOpenDialogue(false)
                    } else {
                        integrationDispatch(setIntegrationEditMode(false))
                        setModalType(INTEGRATINOS_TYPES.RESCUE_TIME)
                        setOpenDialogue(true)
                        setEditMode(false)
                    }
                },
                onDisconnect: () => {
                    setModalType(INTEGRATINOS_TYPES.RESCUE_TIME)
                    setOpenDisconnectDialogue(true)
                    // integrationsService.updateKeyRescueTime({
                    //     apiKey: null
                    // }, outcomeState.token).then(() => {
                    //     getRescueTime()
                    // }).catch(() => { })
                },
                isConnected: Boolean(rescueResponse && rescueResponse.apiKey),
                modalType: INTEGRATINOS_TYPES.RESCUE_TIME,
                isConnecting: integrationState.connectionName === INTEGRATINOS_TYPES.RESCUE_TIME && integrationState.isConnecting,
                isEditMode: integrationState.connectionName === INTEGRATINOS_TYPES.RESCUE_TIME && integrationState.isConnecting && integrationState.isEditMode,
                // keysArr: Boolean(rescueResponse && rescueResponse.apiKey) ? rescueResponse.keysArray : []
                keysArr: rescueResponse.keysArray
            },
            {
                title: "Toggl",
                icon: <img width="44" height="44" src={"/toggl.png"} alt="toggl" style={{
                    borderRadius: "10px"
                }} />,
                onClick: (isConnected) => {
                    if (isConnected) {
                        setEditMode(true)
                        integrationDispatch(setIntegrationEditMode(true))
                        setModalType(INTEGRATINOS_TYPES.TOGGL)
                        setOpenDialogue(false)
                    } else {
                        integrationDispatch(setIntegrationEditMode(false))
                        setModalType(INTEGRATINOS_TYPES.TOGGL)
                        setOpenDialogue(true)
                        setEditMode(false)
                    }
                },
                onDisconnect: () => {
                    setModalType(INTEGRATINOS_TYPES.TOGGL)
                    setOpenDisconnectDialogue(true)
                    // integrationsService.updateKeyRescueTime({
                    //     apiKey: null
                    // }, outcomeState.token).then(() => {
                    //     getRescueTime()
                    // }).catch(() => { })
                },
                isConnected: Boolean(togglResponse && togglResponse.apiKey),
                modalType: INTEGRATINOS_TYPES.TOGGL,
                isConnecting: integrationState.connectionName === INTEGRATINOS_TYPES.TOGGL && integrationState.isConnecting,
                isEditMode: integrationState.connectionName === INTEGRATINOS_TYPES.TOGGL && integrationState.isConnecting && integrationState.isEditMode,
                // keysArr: Boolean(togglResponse && togglResponse.apiKey) ? togglResponse.keysArray : []
                keysArr: togglResponse.keysArray
            },
            {
                title: "Oura",
                icon: <img width="44" height="44" src={"/oura.png"} alt="Oura" style={{
                    borderRadius: "10px"
                }} />,
                onClick: (isConnected) => {
                    if (isConnected) {
                        integrationDispatch(setIntegrationEditMode(true))
                        setEditMode(true)
                        setModalType(INTEGRATINOS_TYPES.OURA)
                        setOpenDialogue(false)
                    } else {
                        integrationDispatch(setIntegrationEditMode(false))
                        setModalType(INTEGRATINOS_TYPES.OURA)
                        setOpenDialogue(true)
                        setEditMode(false)
                    }
                },
                onDisconnect: () => {
                    setModalType(INTEGRATINOS_TYPES.OURA)
                    setOpenDisconnectDialogue(true)
                    // integrationsService.updateKeyRescueTime({
                    //     apiKey: null
                    // }, outcomeState.token).then(() => {
                    //     getRescueTime()
                    // }).catch(() => { })
                },
                isConnected: Boolean(ouraResponse && ouraResponse.apiKey),
                modalType: INTEGRATINOS_TYPES.OURA,
                isConnecting: integrationState.connectionName === INTEGRATINOS_TYPES.OURA && integrationState.isConnecting,
                isEditMode: integrationState.connectionName === INTEGRATINOS_TYPES.OURA && integrationState.isConnecting && integrationState.isEditMode,
                // keysArr: Boolean(ouraResponse && ouraResponse.apiKey) ? ouraResponse.keysArray : []
                keysArr: ouraResponse.keysArray
            },
            // {
            //     title: "Garmin",
            //     icon: <img width="44" height="44" src={"/garmin.svg"} alt="Garmin" style={{
            //         borderRadius: "10px"
            //     }} />,
            //     onClick: (isConnected) => {
            //         if (isConnected) {
            //             integrationDispatch(setIntegrationEditMode(true))
            //             setEditMode(true)
            //             setModalType(INTEGRATINOS_TYPES.GARMIN)
            //             setOpenDialogue(false)
            //         } else {
            //             integrationDispatch(setIntegrationEditMode(false))
            //             setModalType(INTEGRATINOS_TYPES.GARMIN)
            //             setOpenOAuthConnectionDialogue(true)
            //             setEditMode(false)
            //             // setOpenDialogue(true)
            //             // setEditMode(false)

            //         }
            //     },
            //     onDisconnect: () => {
            //         setModalType(INTEGRATINOS_TYPES.GARMIN)
            //         setOpenDisconnectDialogue(true)
            //         // integrationsService.updateKeyRescueTime({
            //         //     apiKey: null
            //         // }, outcomeState.token).then(() => {
            //         //     getRescueTime()
            //         // }).catch(() => { })
            //     },
            //     isConnected: Boolean(garminResponse?.oauthToken),
            //     modalType: INTEGRATINOS_TYPES.GARMIN,
            //     isConnecting: integrationState.connectionName === INTEGRATINOS_TYPES.GARMIN && integrationState.isConnecting,
            //     isEditMode: integrationState.connectionName === INTEGRATINOS_TYPES.GARMIN && integrationState.isConnecting && integrationState.isEditMode,
            //     // keysArr: Boolean(garminResponse && garminResponse.apiKey) ? garminResponse.keysArray : []
            //     keysArr: garminResponse.keysArray
            // }
        ]
        setConnections(conne)
    }, [rescueResponse, togglResponse, ouraResponse, garminResponse, integrationState.isConnecting, integrationState.isEditMode])
    console.log("connections", connections)
    return <>
        <Box display={"flex"} gap={"32px"} flexWrap={"wrap"}>
            {connections.map(it => (<IntegrationBox
                icon={it.icon}
                keysArr={/* it.isConnected ?  */it.keysArr /* : [] */}
                onClick={it.onClick}
                onDisconnect={it.onDisconnect}
                modalType={it.modalType}
                email={outcomeState.userDetails.user_email}
                title={it.title}
                key={it.title}
                isConnected={it.isConnected}
                isConnecting={it.isConnecting}
                isEditMode={it.isEditMode}
            />))}
        </Box>
        <IntegrationDialogue
            isMobile={width < 768}
            modalType={modalType}
            openModal={openDialogue}
            onClose={() => {
                // setModalType(null)
                setEditMode(false)
                setOpenDisconnectDialogue(false)
                setOpenDialogue(false)
            }}
            outcomeState={outcomeState}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            rescueResponse={rescueResponse}
            togglResponse={togglResponse}
            ouraResponse={ouraResponse}
            garminResponse={garminResponse}
            getRescueTime={getRescueTime}
            getTogglTime={getTogglTime}
            getOURATime={getOURATime}
            getGarminTime={getGarminTime}
            setModalType={setModalType}
            isEditMode={isEditMode}
            setEditMode={setEditMode}
            integrationState={integrationState}
            openSelection={openSelection}
            setOpenSelection={setOpenSelection}
            integrationDispatch={integrationDispatch}
        />
        <DisconnectModal
            isMobile={width < 768}
            modalType={modalType}
            openModal={openDisconnectDialogue}
            outcomeState={outcomeState}
            getRescueTime={getRescueTime}
            getTogglTime={getTogglTime}
            setModalType={setModalType}
            getOURATime={getOURATime}
            getGarminTime={getGarminTime}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            setmsgIcon={setmsgIcon}
            setPreventAutoHide={setPreventAutoHide}
            onClose={() => {
                // setModalType(null)
                setOpenDisconnectDialogue(false)
            }}
        />
        <OAuthConnectModal
            isMobile={width < 768}
            modalType={modalType}
            openModal={openOAuthConnectionDialogue}
            outcomeState={outcomeState}
            getRescueTime={getRescueTime}
            getTogglTime={getTogglTime}
            setModalType={setModalType}
            getOURATime={getOURATime}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            setmsgIcon={setmsgIcon}
            setPreventAutoHide={setPreventAutoHide}
            onClose={() => {
                // setModalType(null)
                setOpenOAuthConnectionDialogue(false)
            }}
        />
    </>
}

export default Integrations

const IntegrationBox = ({
    icon,
    title,
    email,
    onClick,
    onDisconnect,
    keysArr = [],
    modalType,
    isConnected,
    isConnecting,
    isEditMode
}) => {
    const [selectionKeys, setSelectionKeys] = useState([])
    useEffect(() => {
        console.log("keysArr", keysArr, modalType);

        if (keysArr && keysArr.length && modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
            const arr = []
            keysArr.map((key) => {
                if (key !== "date") {
                    // Transform the key
                    const formattedKey = key
                        .replace(/_/g, " ")                 // Replace underscores with spaces
                        .split(" ")                        // Split into words
                        .map((word) => {
                            if (word.toLowerCase() === "and") {
                                return "&";                    // Replace "and" with "&"
                            }
                            return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter
                        })
                        .join(" ");                        // Join the words back into a single string

                    // Ensure correct capitalization after "&"
                    const finalKey = formattedKey.replace(/&\s*(\w)/g, (match, char) => `& ${char.toUpperCase()}`);
                    arr.push({
                        value: key,
                        label: finalKey
                    })
                }
                return key
            })
            setSelectionKeys(arr)
        }
        if (keysArr && keysArr.length && modalType === INTEGRATINOS_TYPES.TOGGL) {
            const arr = []
            console.log("keysArrselectionKeys keysArr", arr);

            keysArr.map((key) => {
                arr.push({
                    value: key.pid,
                    label: key.project_name
                })
                return key
            })
            console.log("keysArrselectionKeys arr", arr);

            setSelectionKeys(arr)
        }
        /* else {
            setSelectionKeys([]) 
        } */
    }, [keysArr])
    console.log("keysArrselectionKeys", selectionKeys);

    return <Box width={"226px"} display={"inline-flex"} flexDirection={"column"} gap={"12px"} borderRadius={"12px"} border={"1px solid var(--color-border)"} padding={"24px 24px 24px 20px"}>
        <Box width={"100%"} display={"inline-flex"} gap={"8px"} alignItems={"center"}>
            <Box display={"inline-flex"} alignItems={"center"} justifyContent={"center"} height={"48px"} width={"48px"} borderRadius={"10px"}>
                {icon}
            </Box>
            <Box display={"inline-flex"} flexDirection={"column"} gap={"8px"}>
                <CommonTypo size={16} fontWeight={600} lineHeight={19.2} color="var(--color-text-primary)">{title}</CommonTypo>
                {/* <CommonTypo size={16} fontWeight={400} lineHeight={19.2} color="var(--color-text-secondary)">{email}</CommonTypo> */}
            </Box>
        </Box>
        {/* <Box width={"100%"} display={"inline-flex"} gap={"16px"} >
            <Box display={"inline-flex"} 
                width={"100%"} gap={"8px"} sx={{
                    overflowX: "auto",
                    overflowY: "hidden"
                }} className="hide-scrollbar">
                {selectionKeys && selectionKeys[0] && (
                    <Box
                        height="34px"
                        padding={"8px 24px"}
                        gap={"4px"}
                        display={"inline-flex"}
                        whiteSpace={"nowrap"}
                        alignItems={"center"}
                        borderRadius={"20px"}
                        border={"1px solid var(--color-border)"}
                    >
                        <InfluenceIcon />
                        <CommonTypo size={16} fontWeight={400} lineHeight={19.2} color="var(--color-text-secondary)">{selectionKeys[0].label}</CommonTypo>
                    </Box>
                )}
                {selectionKeys && selectionKeys.length > 1 && (
                    <Box
                        height="34px"
                        padding={"8px 24px"}
                        gap={"4px"}
                        display={"inline-flex"}
                        whiteSpace={"nowrap"}
                        alignItems={"center"}
                        borderRadius={"20px"}
                        border={"1px solid var(--color-border)"}
                    >
                        <CommonTypo size={16} fontWeight={400} lineHeight={19.2} color="var(--color-text-secondary)">+{selectionKeys.length - 1} More</CommonTypo>
                    </Box>
                )}
            </Box>
        </Box> */}
        {isConnected && !isConnecting ? (
            <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                <NewButton buttonText="Edit" borderRadius={"20px !important"} extraSx={{
                    background: "var(--color-border)",
                    border: "none",
                    width: "130px",
                    height: "40px",
                    marginBottom: "0px !important",
                    "&:hover": {
                        background: "var(--color-border)"
                    },
                    "&:after": {
                        width: "130px"
                    },
                    "&.MuiButton-outlined": {
                        border: `none`,
                    },
                    "&.MuiButton-contained": {
                        border: `none`,
                    },
                }} boxSx={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} fontSize={`16px`} fontWeight={600} typographySx={{
                    fontSize: "16px !important",
                    lineHeight: "16px !important",
                    fontWeight: `600 !important`,
                    letterSpacing: "2% important"
                }} onClick={() => onClick(true)} />
                <NewButton buttonText={<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0306 9.96936C11.1715 10.1103 11.2506 10.3014 11.2506 10.5006C11.2506 10.6999 11.1715 10.891 11.0306 11.0319C10.8897 11.1728 10.6986 11.2519 10.4993 11.2519C10.3001 11.2519 10.109 11.1728 9.9681 11.0319L5.99997 7.06249L2.0306 11.0306C1.8897 11.1715 1.69861 11.2507 1.49935 11.2507C1.30009 11.2507 1.10899 11.1715 0.968098 11.0306C0.827202 10.8897 0.748047 10.6986 0.748047 10.4994C0.748047 10.3001 0.827202 10.109 0.968098 9.96811L4.93747 5.99999L0.969348 2.03061C0.828451 1.88972 0.749297 1.69862 0.749297 1.49936C0.749297 1.30011 0.828451 1.10901 0.969348 0.968112C1.11024 0.827216 1.30134 0.748061 1.5006 0.748061C1.69986 0.748061 1.89095 0.827216 2.03185 0.968112L5.99997 4.93749L9.96935 0.967487C10.1102 0.826591 10.3013 0.747437 10.5006 0.747437C10.6999 0.747437 10.8909 0.826591 11.0318 0.967487C11.1727 1.10838 11.2519 1.29948 11.2519 1.49874C11.2519 1.69799 11.1727 1.88909 11.0318 2.02999L7.06247 5.99999L11.0306 9.96936Z" fill="#292B2A" />
                </svg>
                } borderRadius={"20px !important"} extraSx={{
                    background: "var(--color-border)",
                    border: "none",
                    width: "48px",
                    minWidth: "48px",
                    height: "40px",
                    marginBottom: "0px !important",
                    padding: "16px",
                    "&:hover": {
                        background: "var(--color-border)"
                    },
                    "&:after": {
                        width: "48px"
                    },
                    "&.MuiButton-outlined": {
                        border: `none`,
                    },
                    "&.MuiButton-contained": {
                        border: `none`,
                    },
                }} boxSx={{
                    width: "48px",
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} fontSize={`16px`} fontWeight={600} typographySx={{
                    fontSize: "16px !important",
                    lineHeight: "16px !important",
                    fontWeight: `600 !important`,
                    letterSpacing: "2% important"
                }} onClick={() => onDisconnect(true)} />
            </Box>
        ) : isConnecting ? (
            <NewButton
                buttonText={
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"8px"}>
                        <ConnectingIcon />
                        <CommonTypo>{isEditMode ? "Updating..." : "Connecting..."}</CommonTypo>
                    </Box>
                }
                onClick={() => { }}
                extraSx={{
                    background: "var(--color-border)",
                    border: "none",
                    height: "40px",
                    marginBottom: "0px !important",
                    padding: "16px",
                    boxShadow: "none",
                    "&:hover": {
                        background: "var(--color-border)",
                        boxShadow: "none",
                    },
                    "&:after": {
                        width: "48px"
                    },
                    "&.MuiButton-outlined": {
                        border: `none`,
                    },
                    "&.MuiButton-contained": {
                        border: `none`,
                    },
                }}
                boxSx={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            />
        ) : (
            <NewButton
                buttonText="Connect"
                onClick={() => onClick(false)}
                extraSx={{
                    height: "40px",
                    padding: 0
                }}
                boxSx={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            />
        )}


    </Box>
}