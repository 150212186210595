/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import { makeStyles, Typography } from "@material-ui/core";
import palette from "../../theme/palette";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { UpdateUserLevelModal } from "../../interfaces/outcomeModal";
import { ButtonLoader } from "../../components/buttonLoader";
import useWidth from "../../utils/useWidth";
import { useNavigate } from "react-router-dom";
import CommonTypo from "../../components/CommonTypo";
import NewButton from "../../components/NewButton";
const PlusIcon = require("../../assets/images/Add.png");

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "20px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  listItemNotes: {
    listStyleType: "none",
    fontSize: "14px",
    lineHeight: "14px",
    color: palette.primary.light,
    margin: 0,
    paddingLeft: "0px",
    marginBottom: "20px",
    "& li": {
      marginBottom: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

interface AddRefQues {
  setopenUnavailablecategory: (openUnavailablecategory: boolean) => void;
  openUnavailablecategoryMessage?: string
  link?: string
  setLink?: (openUnavailablecategory: string) => void;
  //   refId: string;
  //   setShowSnakbar: (showSnakbar: boolean) => void;
  //   setSnakbarMsg: (snakbarMsg: string) => void;
  //   setMsgType: (msgType: string) => void;
  //   setRefId: (refId: string) => void;
  //   isEdit: boolean;
  //   setIsEdit: any;
  //   journeyData: any;
  //   headerDate: any;
  //   callService: any;
  //   item: any;
}
const UnavailableCategoryV2 = (props: AddRefQues) => {
  const [openStartModal, setStartModal] = useState(true);
  const width = useWidth();
  const handleCloseStartModal = async () => {
    setStartModal(false);
    await props.setopenUnavailablecategory(false);
    props?.setLink("")
    // props.setRefId("");
  };
  return (
    <Grid>
      <Dialog
        // onClose={handleCloseStartModal}
        open={openStartModal}
        className="dialogWrapper dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            height: "383px",
            padding: "32px 24px",
            gap: "16px",
            borderRadius: "12px 12px 0px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }
        }}
      >
        <Box height={"64px"} width={"64px"} borderRadius={"8px"} padding={"5.33px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
          background: "var(--color-new-main)"
        }}>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.3333 6.66663H8.66667C7.42899 6.66663 6.242 7.15829 5.36683 8.03346C4.49167 8.90863 4 10.0956 4 11.3333V29.3333C4 30.571 4.49167 31.758 5.36683 32.6331C6.242 33.5083 7.42899 34 8.66667 34H20V36H16.6667C16.1362 36 15.6275 36.2107 15.2525 36.5858C14.8774 36.9608 14.6667 37.4695 14.6667 38C14.6667 38.5304 14.8774 39.0391 15.2525 39.4142C15.6275 39.7892 16.1362 40 16.6667 40H27.3333C27.8638 40 28.3725 39.7892 28.7475 39.4142C29.1226 39.0391 29.3333 38.5304 29.3333 38C29.3333 37.4695 29.1226 36.9608 28.7475 36.5858C28.3725 36.2107 27.8638 36 27.3333 36H24V34H35.3333C35.9462 34 36.553 33.8793 37.1192 33.6447C37.6854 33.4102 38.1998 33.0665 38.6332 32.6331C39.0665 32.1998 39.4102 31.6853 39.6448 31.1192C39.8793 30.553 40 29.9461 40 29.3333V11.3333C40 10.7205 39.8793 10.1136 39.6448 9.54744C39.4102 8.98125 39.0665 8.4668 38.6332 8.03346C38.1998 7.60012 37.6854 7.25638 37.1192 7.02186C36.553 6.78733 35.9462 6.66663 35.3333 6.66663ZM8.66667 10.6666H35.3333C35.5101 10.6666 35.6797 10.7369 35.8047 10.8619C35.9298 10.9869 36 11.1565 36 11.3333V23.3333H8V11.3333C8 11.1565 8.07024 10.9869 8.19526 10.8619C8.32029 10.7369 8.48986 10.6666 8.66667 10.6666ZM35.3333 30H8.66667C8.48986 30 8.32029 29.9297 8.19526 29.8047C8.07024 29.6797 8 29.5101 8 29.3333V27.3333H36V29.3333C36 29.5101 35.9298 29.6797 35.8047 29.8047C35.6797 29.9297 35.5101 30 35.3333 30Z" fill="white" />
          </svg>
        </Box>
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"} gap={"8px"}>
          <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">Use desktop app</CommonTypo>
          <CommonTypo size={12} lineHeight={14.4} color="var(--color-text-secondary)" extraSx={{
            textAlign: "center"
          }}>{props.openUnavailablecategoryMessage}</CommonTypo>
        </Box>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
          <div style={{ width: "100%", height: "30px", borderRadius: "20px", border: "2px solid var(--color-new-main)", backgroundColor: "#FFF", padding: "8px 16px", display: "flex", alignItems: "center" }}>
            <CommonTypo size={12} lineHeight={14.4} color="var(--color-text-secondary)">{props?.link || window.location.href}</CommonTypo>
          </div>
        </div>
        <CommonTypo size={12} lineHeight={14.4} color="var(--color-text-secondary)" extraSx={{
          textAlign: "center"
        }}>We apologize for any inconvenience this may cause and appreciate your understanding. Thank you for choosing TNT to support your personal growth journey!</CommonTypo>
        <NewButton buttonText="Got it" borderRadius={"20px !important"} padding="16px" extraSx={{
          background: "var(--color-border)",
          border: "none",
          width: "100%",
          height: "43px",
          marginBottom: "0px !important",
          "&:hover": {
            background: "var(--color-border)"
          },
          "&.MuiButton-outlined": {
            border: `none`,
          },
          "&.MuiButton-contained": {
            border: `none`,
          },
        }} boxSx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }} fontSize={`16px`} fontWeight={600} typographySx={{
          fontSize: "16px !important",
          lineHeight: "16px !important",
          fontWeight: `600 !important`,
          letterSpacing: "2% important"
        }} onClick={handleCloseStartModal} />
      </Dialog>
    </Grid>
  );
};
export default UnavailableCategoryV2;
