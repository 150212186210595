import { makeStyles } from "@material-ui/core"
import { Box } from "@mui/material"
import React from "react"
import palette from "../theme/palette"
import CommonTypo from "./CommonTypo"

const styles = makeStyles((theme) => ({
    tabBox: {
        padding: "4px",
        borderRadius: "20px",
        background: "#EEEEEE",
        width: "max-content",
        display: "flex",
        alignItems: "center"
    },
    tabBoxFull: {
        padding: "4px",
        borderRadius: "20px",
        background: "#EEEEEE",
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    tabClasses: {
        padding: "6px 12px",
        borderRadius: "20px",
        display: "flex",
        gap: "8px",
        alignItems: "center",
        cursor: "pointer"
    },
    tabClassesMobile: {
        padding: "16px",
        height: "32px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        flex: 1,
        alignItems: "center",
        cursor: "pointer"
    },
    tabClassesFull: {
        padding: "6px 12px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        flex: 1,
        alignItems: "center",
        cursor: "pointer"
    },
}))

const TabBox = ({
    tabs = [],
    activeTab = null,
    onSelectTab,
    fullWidth = false,
    isMobile = false
}: {
    tabs: {
        name: string,
        icon?: React.ReactNode
    }[],
    activeTab: string
    onSelectTab: (x: {
        name: string,
        icon?: React.ReactNode
    }) => void
    fullWidth?: boolean,
    isMobile?: boolean
}) => {
    const classes = styles()
    return <div className={fullWidth ? classes.tabBoxFull :classes.tabBox}>
        {tabs && tabs.length > 0 && tabs.map(it => <Box className={isMobile ? classes.tabClassesMobile :fullWidth ? classes.tabClassesFull : classes.tabClasses} onClick={() => {
            onSelectTab(it)
        }} sx={{
            background: activeTab === it.name ? palette.common.white : "transparent",
        }}>
            {it.icon || null}
            <CommonTypo size={16} color="var(--color-text-primary)" fontWeight={activeTab === it.name ? 600 : 400}>{it.name}</CommonTypo>
        </Box>)}
    </div>
}

export default TabBox