import React, { useContext, useEffect, useRef, useState } from "react";
import { UserTypeContext } from "../../context/userStatus";
import TrackBody from "./trackBody";
// import PersonalTrackBody from "./PersonalTrackBody";
import {
  addCategoryExpandAction,
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  addOutcomeAction,
  addRefelectiveAction,
  isEmptyState,
} from "../../store/actions/userTypeAction";
import { outcomeService } from "../../services/outcome";
import { reflectiveQueService } from "../../services/reflective-questions";
import { influenceService } from "../../services/influence";
import {
  InfluenceGetResponse,
  OutcomeGetResponse,
  RefelectiveGetResponse,
} from "../../interfaces/outcomeModal";
import { TrackingContext } from "../../context/userTracking";
import {
  setInfluenceIdeas,
  setOutcomeIdeas,
  setReflectiveIdeas,
  setTrackingState,
} from "../../store/actions/trackerAction";
import PersonalTrackBody from "./PersonalTrackBody";
import moment from "moment";
import { trackingService } from "../../services/tracking";

const TrackBodyData = () => {
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const [selectedDate, setSelectedDateInfluence] = useState(null);
  const [influenceId, setInfluenceIdCalendar] = useState(null);
  const {
    startDate,
    endDate,
    calendar,
    today,
    isFirst,
    isExpandable,
    isLastAlreadyLoaded,
  } = ITrackerState;
  const pTrackBodyref = useRef(null);
  useEffect(() => {
    if (IUserTypeState.userDetails.createdAt) {
      let startDay = isFirst
        ? moment(startDate).startOf("month")
        : moment().startOf("month");
      const current = moment();
      if (!isFirst && current?.diff(startDay, "day") < 7) {
        startDay = moment()
          .startOf("month")
          .subtract(7 - current?.diff(startDay, "day"), "day")
          .startOf("day");
      }
      if (calendar.length === 0 && IUserTypeState.userDetails.createdAt) {
        const endOfMonth = moment().clone().endOf("month");
        if (endOfMonth?.diff(moment(), "day") >= 7) {
          setEndDay(moment().clone().endOf("month"));
          // props?.setEndDate(moment().clone().endOf('month'))
        } else {
          const currDate = moment(startDay)?.clone().isBefore(registeredDate);
          if (currDate) {
            if (endOfMonth?.diff(registeredDate.clone(), "day") <= 7) {
              setEndDay(moment().clone().add(1, "month").endOf("month"));
            } else {
              if (endOfMonth?.diff(registeredDate.clone(), "day") <= 15) {
                setEndDay(moment().clone().add(1, "month").endOf("month"));
              } else {
                setEndDay(moment().clone().add(7, "day"));
              }
              // setEndDay(moment().clone().add(7, 'day'));
            }
          } else {
            if (endOfMonth?.diff(registeredDate.clone(), "day") <= 15) {
              setEndDay(moment().clone().add(1, "month").endOf("month"));
            } else {
              setEndDay(moment().clone().add(7, "day"));
            }
          }
        }

        setStartDay(startDay);
      }
      // trackingEvent();
      enumerateDaysBetweenDates(startDate);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [startDate, endDate, IUserTypeState.userDetails.createdAt]);
  const setStartDay = (val) => {
    trackingDispatch(
      setTrackingState({
        startDate: val,
      })
    );
  };
  const setEndDay = (val) => {
    trackingDispatch(
      setTrackingState({
        endDate: val,
      })
    );
  };
  const setCalendar = (val) => {
    trackingDispatch(
      setTrackingState({
        calendar: val,
      })
    );
  };
  const setToday = (val) => {
    trackingDispatch(
      setTrackingState({
        today: val,
      })
    );
  };
  const setIsFirst = (val) => {
    trackingDispatch(
      setTrackingState({
        isFirst: val,
      })
    );
  };
  let registeredDate = moment(IUserTypeState.userDetails.createdAt);

  useEffect(() => {
    startDate && endDate && callservice(!isLastAlreadyLoaded);
    // interval && clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  const getPeronsltrackData = async (bodyData) => {
    let data = await trackingService.getPersonalJourneyList(
      IUserTypeState.token,
      bodyData
    );
    if (data.data.length <= 0) {
      userTypeDispatch(isEmptyState(true));
    } else {
      userTypeDispatch(isEmptyState(false));
    }

    userTypeDispatch(addCategoryJourneyAction(data?.data));
    userTypeDispatch(addNotesJourneyAction(data?.data));
    const newAr = JSON.parse(localStorage.getItem("menuExpandableIds") || '[]')
    console.log("newAr", newAr);
    
    if(newAr && newAr.length) {
      userTypeDispatch(addCategoryExpandAction(newAr));
    } else {
      const newArr = [...IUserTypeState?.journeyCategoryExpandList];
      for (let index = 0; index < data?.data.length; index++) {
        const element = data?.data[index];
        newArr.push(element.categoryId);
      }
      // if (!IUserTypeState?.isPersonal) {
      newArr.push("outcome", "influence", "reflective");
      localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
      userTypeDispatch(addCategoryExpandAction(newArr));
    }
  };
  const enumerateDaysBetweenDates = (startDate) => {
    if (endDate) {
      var dates = [];
      const currDate = moment(startDate)?.clone().isBefore(registeredDate)
        ? registeredDate.clone().subtract(1, "day")
        : moment(startDate)?.clone().subtract(1, "day");
      const lastDate = moment(endDate).endOf("day");

      while (currDate?.add(1, "days")?.diff(lastDate) < 0) {
        dates.push(currDate?.clone());
      }
      setCalendar([dates]);
    }
  };
  const callservice = async (showLoader = true) => {
    try {
      const bodyData = {
        from: moment(startDate).format("MM-DD-YYYY"),
        to: moment(endDate).format("MM-DD-YYYY"),
      };
      const personalDataLoaded =
        localStorage.getItem("personalDataLoaded") === "true";
      if (!personalDataLoaded && IUserTypeState.isPersonal) {
        trackingDispatch(
          setTrackingState({
            showLoader:
              IUserTypeState.journeyCategoryList &&
              IUserTypeState.journeyCategoryList.length
                ? false
                : true,
          })
        );
      }
      trackingDispatch(
        setTrackingState({
          showLoader: showLoader,
        })
      );
      let data = await trackingService.getJourneyList(
        IUserTypeState.token,
        bodyData
      );
      console.log("data", data);
      userTypeDispatch(addJourneyAction(data));
      userTypeDispatch(addNotesAction(data));
      
    //   const newAr = JSON.parse(localStorage.getItem("menuExpandableIds") || '[]')
    // console.log("newAr", newAr);
    //   if(newAr && newAr.length) {
    //     userTypeDispatch(addCategoryExpandAction(newAr));
    //   } else {
    //     const newArr = [
    //       ...IUserTypeState?.journeyCategoryExpandList,
    //       "outcome",
    //       "influence",
    //       "reflective",
    //     ];
    //     userTypeDispatch(addCategoryExpandAction(newArr));
    //   }
      trackingDispatch(
        setTrackingState({
          showLoader: false,
        })
      );
      // userTypeDispatch(pushCategoryJourneyAction([{
      //     categoryId: "outcome", expanded: true
      // },
      // ]));
      // userTypeDispatch(pushCategoryJourneyAction([
      //     {
      //         categoryId: "influence", expanded: true
      //     },
      // ]));
      // userTypeDispatch(pushCategoryJourneyAction([
      //     {
      //         categoryId: "reflective", expanded: true
      //     }]));

      if (!personalDataLoaded) {
        await getPeronsltrackData(bodyData);
      }

      localStorage.removeItem("personalDataLoaded");
      setTimeout(() => {
        // currentDateRef && currentDateRef.current && currentDateRef.current.scrollIntoView()
      }, 500);
    } catch (error) {
      trackingDispatch(
        setTrackingState({
          showLoader: false,
        })
      );
    }
  };
  const prevMonth = () => {
    if (isFirst === "next") {
      setIsFirst("prev");
      setToday(today.subtract(1, "month")); // today date
      const startDate = today.clone().startOf("month");
      setStartDay(startDate); // give start of the today date month
      let endDate = today.clone().endOf("month");
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        endDate = registeredDate.clone().endOf("month");
      }
      setEndDay(endDate);
    } else {
      setIsFirst("prev");
      setToday(today.subtract(1, "month"));
      const startDate = today.clone().startOf("month");

      setStartDay(startDate);
      // setEndDay(today.clone().endOf("month"));
      let endDate = today.clone().endOf("month");
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        endDate = registeredDate.clone().endOf("month");
      }
      setEndDay(endDate);
    }
    // props?.setShowLoader(true)
  };
  const nextMonth = () => {
    if (isFirst === "prev") {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      const startDate = today.clone().add(1, "month").startOf("month");
      setStartDay(startDate);
      const endDate = today.clone().add(1, "month").endOf("month");
      setEndDay(endDate);
    } else {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      const startDate = today.clone().add(1, "month").startOf("month");
      setStartDay(startDate);
      const endDate = today.clone().add(1, "month").endOf("month");
      setEndDay(endDate);
    }
    // props?.setShowLoader(true)
  };
  useEffect(() => {
    // GET OUTCOME LIST
    getOutcomeList();

    // GET OUTCOME SUGGESTION LIST
    getOutcomeSuggestion();

    // GET INFLUENCE LIST
    getInfluenceList();

    // GET INFLUENCE SUGGESTION LIST
    getInfluenceSuggestion();

    // GET REFLECTIVE QUESTIONS LIST
    getReflectiveList();

    // GET REFLECTIVE SUGGESTION LIST
    getReflectiveSuggestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!IUserTypeState.isPersonal) {
      // GET OUTCOME LIST
      getOutcomeList();

      // GET OUTCOME SUGGESTION LIST
      getOutcomeSuggestion();

      // GET INFLUENCE LIST
      getInfluenceList();

      // GET INFLUENCE SUGGESTION LIST
      getInfluenceSuggestion();

      // GET REFLECTIVE QUESTIONS LIST
      getReflectiveList();

      // GET REFLECTIVE SUGGESTION LIST
      getReflectiveSuggestionList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    callservice(false);
  }, [IUserTypeState.isPersonal]);

  const getOutcomeList = () => {
    outcomeService
      .getOutcomeList(IUserTypeState.token)
      .then((data: OutcomeGetResponse) => {
        userTypeDispatch(addOutcomeAction(data?.data));
      })
      .catch((error) => {
        console.log("Outcome Error");
      });
  };
  const getOutcomeSuggestion = () => {
    outcomeService
      .getOutcomeSuggestion(IUserTypeState.token)
      .then((res: any) => {
        trackingDispatch(setOutcomeIdeas(res));
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
  };
  const getInfluenceList = () => {
    influenceService
      .getInfluenceList(IUserTypeState.token)
      .then((data: InfluenceGetResponse) => {
        userTypeDispatch(addInfluenceAction(data?.data));
      })
      .catch((error) => {
        console.log("influence Error");
      });
  };
  const getInfluenceSuggestion = () => {
    influenceService
      .getInfluenceSuggestion(IUserTypeState.token)
      .then((res: any) => {
        trackingDispatch(setInfluenceIdeas(res));
      })
      .catch((error: any) => {
        console.log("influence suggestion error", error);
      });
  };
  const getReflectiveList = () => {
    reflectiveQueService
      .getReflectiveList(IUserTypeState.token)
      .then((data: RefelectiveGetResponse) => {
        userTypeDispatch(addRefelectiveAction(data?.data));
      })
      .catch((error) => {
        console.log("Ques error");
      });
  };
  const getReflectiveSuggestionList = () => {
    reflectiveQueService
      .getRefelectiveSuggestion(IUserTypeState.token)
      .then((res: any) => {
        trackingDispatch(setReflectiveIdeas(res));
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
  };
  if (startDate && endDate) {
    return (
      <>
        {!IUserTypeState?.isPersonal && IUserTypeState.userDetails.createdAt ? (
          // default view
          <div
            style={{
              height: "auto",
              overflow: "auto",
              borderTop: "1px solid #EEEEEE"
            }}
          >
            <TrackBody
              createDate={IUserTypeState.userDetails.createdAt}
              getReflectiveList={getReflectiveList}
              getInfluenceList={getInfluenceList}
              getOutcomeList={getOutcomeList}
              prevMonth={prevMonth}
              nextMonth={nextMonth}
              isExpandable={isExpandable}
              selectedDate={selectedDate}
              setSelectedDateInfluence={setSelectedDateInfluence}
              influenceId={influenceId}
              setInfluenceIdCalendar={setInfluenceIdCalendar}
              callservice={callservice}
            />
          </div>
        ) : (
          <div
            style={{
              height: "auto",
              overflow: "auto",
              borderTop: "1px solid #EEEEEE"
            }}
            ref={pTrackBodyref}
          >
            <PersonalTrackBody
              createDate={IUserTypeState.userDetails.createdAt}
              prevMonth={prevMonth}
              nextMonth={nextMonth}
              isExpandable={isExpandable}
              selectedDate={selectedDate}
              setSelectedDateInfluence={setSelectedDateInfluence}
              influenceId={influenceId}
              setInfluenceIdCalendar={setInfluenceIdCalendar}
              callservice={callservice}
            />
          </div>
        )}
      </>
    );
  }
  return null;
};

export default TrackBodyData;
