import React, { useContext, useState } from "react"
import { Box, Dialog, Radio } from "@mui/material"
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import CommonTypo from "../../../components/CommonTypo"
import CloseButton from "../../../components/CloseButton"
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Exclamation from "../../../assets/images/icon/Exclamation";
import NewButton from "../../../components/NewButton";
import { INTEGRATINOS_TYPES } from "../integrationsConstants";
import { integrationsService } from "../../../services/integrations";
import SuccessDialogue from "./successDialogue";
import ConnectingIcon from "../../../assets/images/icon/integration/connecting";
import { IntegrationContext } from "../../../context/userIntegrations";

const StyledFormControlLabel = styled((props) => <FormControlLabel control={undefined} label={""} {...props} />)(
    ({ theme }) => ({
        variants: [
            {
                props: { checked: true },
                style: {
                    '.MuiFormControlLabel-label': {
                        color: "#292B2A",
                    },
                },
            },
        ],
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel color="#292B2A" checked={checked} {...props} />;
}

const OAuthConnectModal = ({
    isMobile,
    onClose,
    openModal,
    modalType,
    getRescueTime,
    getTogglTime,
    outcomeState,
    setModalType,
    getOURATime,
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType,
    setmsgIcon,
    setPreventAutoHide
}) => {
    const { state: integrationState, dispatch: integrationDispatch } = useContext(IntegrationContext)
    const [selectedValue, setSelectedValue] = useState("DELETE_ALL")
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const closemodal = () => {
        onClose()
    }
    const onClickDisconnect = () => {
        console.log("modalType", modalType);
        integrationsService.garminRequestToken(outcomeState.token).then(data => {
            window.location.href = data.url
        })
    }
    let paperStyles: any = {
        display: 'flex !important',
        padding: '16px 24px 32px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '32px !important',
        minWidth: "360px",
        maxWidth: "360px",
        borderRadius: "12px"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    return <>
        <Dialog
            onClose={closemodal}
            open={openModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} alignItems={"flex-start"} gap={"8px"} width={"100%"}>
                {/* <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} flexBasis={"90%"}>
                    <CommonTypo size={24} fontWeight={700} lineHeight={28.8} color="var(--color-text-primary)">What exactly do you want to do?</CommonTypo>
                </Box> */}
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} flexBasis={"100%"} position={"relative"}>
                    <CloseButton onClick={closemodal} boxStyle={{
                        display: "flex",
                    }} />
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexBasis={"100%"} width={"100%"}>
                <img width="44" height="44" src={"/garmin.svg"} alt="Garmin" style={{
                    borderRadius: "10px"
                }} />
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={"8px"} flexBasis={"100%"} width={"100%"}>
                <CommonTypo size={24} fontWeight={700} lineHeight={28.8} color="var(--color-text-primary)">Garmin Integration</CommonTypo>
                <CommonTypo size={16} lineHeight={25.6} color="var(--color-text-secondary)" extraSx={{
                    textAlign: "center"
                }}>We will take you to the Garmin website. Come back after you sign in to continue the integration.</CommonTypo>
            </Box>
            <Box display={"flex"} flex={1} width={"100%"} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                    {integrationState.isConnecting && integrationState.connectionName === INTEGRATINOS_TYPES.GARMIN ? <NewButton
                        buttonText={
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"8px"}>
                                <ConnectingIcon />
                                <CommonTypo>{integrationState.isEditMode ? "Updating..." : "Connecting..."}</CommonTypo>
                            </Box>
                        }
                        isDisabled
                        onClick={() => { }}
                        extraSx={{
                            background: "var(--color-border)",
                            border: "none",
                            width: "102px",
                            height: "43px",
                            flex: 1,
                            marginBottom: "0px !important",
                            "&:hover": {
                                background: "var(--color-new-main)"
                            },
                            "&.MuiButton-outlined": {
                                border: `none`,
                            },
                            "&.MuiButton-contained": {
                                border: `none`,
                            },
                        }}
                        boxSx={{
                            padding: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    />
                        : <NewButton
                            buttonText="Connect"
                            onClick={onClickDisconnect}
                            extraSx={{
                                background: "var(--color-new-main)",
                                border: "none",
                                width: "102px",
                                height: "43px",
                                flex: 1,
                                marginBottom: "0px !important",
                                "&:hover": {
                                    background: "var(--color-new-main)"
                                },
                                "&.MuiButton-outlined": {
                                    border: `none`,
                                },
                                "&.MuiButton-contained": {
                                    border: `none`,
                                },
                            }} fontSize={`16px`} fontWeight={600} typographySx={{
                                fontSize: "16px !important",
                                lineHeight: "16px !important",
                                fontWeight: `600 !important`,
                                letterSpacing: "2% important"
                            }}
                        />}

                </Box>


            </Box>
        </Dialog>
        <SuccessDialogue isEditMode={false} isDelete={true}
            isMobile={isMobile} modalType={modalType} openModal={openSuccessModal} onClose={() => {

                setOpenSuccessModal(false)
                if (modalType === INTEGRATINOS_TYPES.RESCUE_TIME) {
                    getRescueTime()
                }
                if (modalType === INTEGRATINOS_TYPES.TOGGL) {
                    getTogglTime()
                }
                if (modalType === INTEGRATINOS_TYPES.OURA) {
                    getOURATime()
                }
            }} setModalType={setModalType} deleteAll={selectedValue === "DELETE_ALL"} />
    </>

}

export default OAuthConnectModal