import React, { useContext } from "react";
import * as dateFns from "date-fns";
import "./calender.css";
import moment from "moment/moment";
import { TrackingContext } from "../../context/userTracking";
class CustomCalender extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };
  static contextType = TrackingContext;

  componentDidMount() {
    const { state = {} } = this.context;
    const { startDate = new Date() } = state;
    this.setState({
      currentMonth: new Date(startDate),
    });
    console.log("this.props", this.props, this.context, startDate);
  }
  renderHeader() {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            &lt;
          </div>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">&gt;</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "EEE";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          style={{
            width: "172px",
            height: "32px",
            backgroundColor: "#F7F9FA",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: "500",
            paddingLeft: "8px",
          }}
          key={i}
        >
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div style={{ display: "flex", width: "fit-content" }}>{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        console.log(
          "this.props?.journeyData",
          this.props,
          this.props?.journeyData
        );
        let dayData;
        let nearValue = undefined;
        if (this.props.type === "influence") {
          dayData = this.props?.journeyData?.filter(
            (data) =>
              data?.date == moment(day).format("MM-DD-YYYY") &&
              data?.influenceId === this.props?.filterId
          )?.[0];
          if (dayData) {
            const closest = (needle, haystack = []) => {
              if (haystack && haystack.length) {
                return haystack.reduce(function (prev, curr) {
                  return Math.abs(curr - needle) < Math.abs(prev - needle)
                    ? curr
                    : prev;
                });
              }
              return null;
            };
            const nearestValue = (val, influenceItem) => {
              let arr = [];
              if (
                influenceItem.type === "TYPE2" &&
                influenceItem.options.length &&
                influenceItem.options[val - 1]
              ) {
                return influenceItem.options[val - 1].colorCode;
              }
              if (influenceItem?.type === "TYPE12") {
                let arr = [];
                if (influenceItem?.options && influenceItem?.options.length) {
                  arr = [...influenceItem?.options].map((it) => it.value);
                }
                if (
                  influenceItem.type === "TYPE2" &&
                  influenceItem.options.length &&
                  influenceItem.options[val - 1]
                ) {
                  return influenceItem.options[val - 1].colorCode;
                }

                const returnVal = closest(val, arr);
                if (
                  influenceItem?.options &&
                  influenceItem?.options.length &&
                  !Number.isNaN(returnVal)
                ) {
                  const foundIndexOfValue = influenceItem?.options.findIndex(
                    (it) => it.value === returnVal
                  );

                  if (foundIndexOfValue > -1) {
                    return influenceItem?.options[foundIndexOfValue]?.colorCode;
                  }
                }
                return false;
              }
              if (influenceItem?.options && influenceItem?.options.length) {
                arr = [...influenceItem?.options].map((it) => it.value);
              }
              const returnVal = closest(val, arr);
              if (
                influenceItem?.options &&
                influenceItem?.options.length &&
                returnVal
              ) {
                const foundIndexOfValue = influenceItem?.options.findIndex(
                  (it) => it.value === returnVal
                );
                if (foundIndexOfValue > -1) {
                  return influenceItem?.options[foundIndexOfValue]?.colorCode;
                }
              }
              return false;
            };
            const catItem = this.props?.outcomeState?.influenceList.find(
              (catIt) => catIt.influenceId === this.props?.filterId
            );
            console.log("catItem", catItem);

            if (catItem) {
              nearValue = nearestValue(
                dayData?.type === "TYPE1" || dayData?.type === "TYPE12"
                  ? dayData?.score
                  : dayData?.index,
                catItem
              );

              if (dayData?.type === "TYPE2") {
                if (
                  catItem.type === "TYPE2" &&
                  catItem.options.length &&
                  catItem.options[dayData?.index - 1]
                ) {
                  nearValue = catItem.options[dayData?.index - 1].colorCode;
                }
              }
              if (Number(dayData?.score) === 0 && dayData?.type === "TYPE2") {
                nearValue = "#C7C7C7";
              }
              if (
                catItem?.type === "TYPE12" &&
                catItem?.unitValue === "timeofday" &&
                catItem?.metric_type === "NEUTRAL"
              ) {
                nearValue = "var(--color-border)";
              }
            }
          }
        }
        if (this.props.type === "outcome") {
          dayData = this.props?.journeyData?.filter(
            (data) =>
              data?.date == moment(day).format("MM-DD-YYYY") &&
              data?.outcomeId === this.props?.filterId
          )?.[0];

          const nearestValueOutcome = (val, influenceItem) => {
            let arr = [];
            if (
              influenceItem.outcomeId &&
              influenceItem.options.length &&
              influenceItem.options[val - 1]
            ) {
              return influenceItem.options[val - 1].colorCode;
            }
            if (influenceItem?.options && influenceItem?.options.length) {
              arr = [...influenceItem?.options].map((it) => it.score);
            }
            const returnVal =
              arr.reduce(
                (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
                Infinity
              ) + val;
            if (influenceItem?.options && influenceItem?.options.length) {
              const foundIndexOfValue = influenceItem?.options.findIndex(
                (it) => it.score === returnVal
              );
              if (foundIndexOfValue > -1) {
                return influenceItem?.options[foundIndexOfValue]?.colorCode;
              }
            }
            return false;
          };
          if (dayData) {
            const catItem = this.props?.outcomeState?.outcomeList.find(
              (catIt) => catIt.outcomeId === this.props?.filterId
            );
            if (catItem) {
              nearValue = nearestValueOutcome(dayData?.index, catItem);
              if (dayData?.type === "TYPE2") {
                nearValue = null;
              }
              if (Number(dayData?.score) === 0) {
                nearValue = "#C7C7C7";
              }
            }
          }
        }
        if (this.props.type === "reflective") {
          dayData = this.props?.journeyData?.filter(
            (data) =>
              data?.date == moment(day).format("MM-DD-YYYY") &&
              data?.reflectiveId === this.props?.filterId
          )?.[0];
        }
        let fontStyling = {
          top: "2rem",
        };
        if (dayData?.type === "TYPE1" || dayData?.type === "TYPE12") {
          if (dayData?.score && dayData?.score?.toString()?.length === 2) {
            fontStyling = {
              top: "2rem",
              fontSize: "14px",
            };
          }
          if (dayData?.score?.toString()?.length >= 3) {
            fontStyling = {
              top: "2rem",
              fontSize: "12px",
            };
          }
        }
        if (dayData?.type === "TYPE3") {
          dayData.colorCode =
            dayData?.levelReach === "No" || dayData?.levelReach === "No"
              ? dayData?.inverse === "true"
                ? "var(--color-new-main)"
                : "#F17F79"
              : dayData?.inverse === "true"
              ? "#F17F79"
              : "var(--color-new-main)";
        }
        console.log("dayData", dayData);

        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            style={{
              minWidth: "172px",
              height: "182px",
              border: "1px solid #DCE5E0",
            }}
            key={day}
            onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            {dayData && (
              <span
                className="score"
                style={{
                  backgroundColor:
                    nearValue || dayData?.colorCode || "var(--color-new-main)",
                  ...fontStyling,
                }}
              >
                {dayData?.type === "TYPE12" && dayData?.timeOfDay && (
                  <div
                    style={{
                      margin: "auto",
                      // height: "20px",
                      // width: "20px",
                      fontSize: "8px",
                      color: "var(--color-black)",
                    }}
                  >
                    {dayData?.timeOfDay}
                  </div>
                )}
                {![null, undefined].includes(dayData?.index) &&
                Number(dayData?.index) >= 0
                  ? dayData?.index
                  : ![null, undefined].includes(dayData?.score) &&
                    Number(dayData?.score) >= 0
                  ? dayData?.score
                  : ""}
              </span>
            )}
            {dayData && (
              <span className="note">
                {dayData?.note?.[0] ||
                  dayData?.answers?.map((ans) => <li>{ans}</li>)}
              </span>
            )}
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
    this.props?.callService(
      moment(dateFns.addMonths(this.state.currentMonth, 1))
        .startOf("month")
        .format("YYYY-MM-DD hh:mm"),
      moment(dateFns.addMonths(this.state.currentMonth, 1))
        .endOf("month")
        .format("YYYY-MM-DD hh:mm")
    );
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
    this.props?.callService(
      moment(dateFns.subMonths(this.state.currentMonth, 1))
        .startOf("month")
        .format("YYYY-MM-DD hh:mm"),
      moment(dateFns.subMonths(this.state.currentMonth, 1))
        .endOf("month")
        .format("YYYY-MM-DD hh:mm")
    );
  };

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default CustomCalender;

CustomCalender.contextType = TrackingContext;
