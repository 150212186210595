import React, { useContext, useEffect, useRef } from "react"
import useStyles from "./TrackingClasses"
import { TrackingContext } from "../../context/userTracking";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import clsx from "clsx";
import { UserTypeContext } from "../../context/userStatus";
import palette from "../../theme/palette";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandIcon from "../../assets/images/icon/ExpandIcon";
import ThreeDotsIcon from "../../assets/images/icon/ThreeDotsIcon";
import { setTrackingState } from "../../store/actions/trackerAction";
import IconPersonalOutcome from "./IconPersonalOutcome";
import IconPersonalInfluence from "./IconPersonalInfluence";
import IconPersonalReflective from "./IconPersonalReflective";
import PersonalTrackingOutcome from "./PersonalTrackingOutcome";
import PersonalTrackingInfluence from "./PersonalTrackingInfluence";
import PersonalTrackingReflective from "./PersonalTrackingReflective";
import { addCategoryExpandAction } from "../../store/actions/userTypeAction";
import { TRACKING_CONST } from "./constants";
import { pxToRem } from "../../theme/typography";
import RescueTime from "../../assets/images/icon/integration/rescutime";
import InfluenceIcon from "../../assets/images/icon/ProgressInsights/InfluenceIcon";
import OutcomeIcon from "../../assets/images/icon/ProgressInsights/OutcomeIcon";
import CommonTypo from "../../components/CommonTypo";
const PersonalItemRow = ({
    category,
    index,
    journyList,
    setOutcomeId,
    outcomeId,
    setInfluenceId,
    influenceId,
    setInfluenceIdCalendar,
    setSelectedDateInfluence,
    refId,
    setRefId,
    setShowOutcomeInput,
    setShowInfluenceInput,
    callservice
}) => {
    const classes = useStyles()
    const currentDateRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
        trackingDispatch(setTrackingState({
            categoryId: category?.categoryId
        }))
        // setCategoryId(category?.categoryId);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(null);
    };
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
    const {
        calendar
    } = ITrackerState
    console.log("category", category);

    useEffect(() => {
        const todayEl = document.getElementById(moment().format(TRACKING_CONST.DATE_FORMAT))
        if (todayEl && !outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            })
        }
        if (todayEl && outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            })
        }
    }, [currentDateRef && currentDateRef.current, outcomeState?.isExpand])
    const arrToReturn = [<tr className={classes.row}>

        <td className={clsx(classes.cell, classes.stickyLeftCell)}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                width: "100%"
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 0px",
                        gap: '8px',
                        flex: '1 0 0',
                    }}
                    onClick={() => {
                        const expanded = [...outcomeState?.journeyCategoryExpandList].includes(category.categoryId)
                        if (!expanded) {
                            const newArr = [...outcomeState?.journeyCategoryExpandList, category.categoryId]
                            localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                            userTypeDispatch(addCategoryExpandAction(newArr));
                        } else {
                            const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== category.categoryId)
                            localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                            userTypeDispatch(addCategoryExpandAction(newArr));
                        }
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <MuiAccordionSummary expandIcon={<ExpandIcon />} sx={![...outcomeState?.journeyCategoryExpandList].includes(category.categoryId) ? {
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                transform: "rotate(180deg)"
                            }
                        } : {}} />
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"} width={"100%"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"8px"} width={"100%"}>
                                {category?.integrationType && category?.integrationType === "RESCUE_TIME" && (
                                    <RescueTime size={24} />
                                )}
                                {category?.integrationType && category?.integrationType === "TOGGL" && (
                                    <img width="24" height="24" src={"/toggl.png"} alt="toggl" style={{
                                        borderRadius: "10px"
                                    }} />
                                )}
                                {category?.integrationType && category?.integrationType === "OURA" && (
                                    <img width="24" height="24" src={"/oura.png"} alt="Oura" style={{
                                        borderRadius: "10px"
                                    }} />
                                )}
                                {category?.integrationType && category?.integrationType === "GENERAL" && (
                                    <OutcomeIcon size={24} />
                                )}
                                <Typography
                                    variant="h3"
                                    fontWeight={600}
                                    fontSize={'1.125rem !important'}
                                    className={classes.listTitle}
                                    color={palette.common.black}
                                >
                                    {category?.name}
                                </Typography>
                            </Box>

                            {category?.goal === "enable" && (
                                <div className={classes.boxPlan}>
                                    <Typography
                                        variant="h3"
                                        fontWeight={700}
                                        fontSize={`${pxToRem(10)} !important`}
                                        lineHeight={"100% !important"}
                                        color={"var(--color-text-primary)"}
                                    >
                                        {"Plan"}
                                    </Typography>
                                </div>
                            )}
                            {category?.integrationType && category?.integrationType !== "GENERAL" && (
                                <div className={classes.boxPlan}>
                                    <Typography
                                        variant="h3"
                                        fontWeight={700}
                                        fontSize={`${pxToRem(10)} !important`}
                                        lineHeight={"100% !important"}
                                        color={"var(--color-text-primary)"}
                                    >
                                        {"Integration"}
                                    </Typography>
                                </div>
                            )}
                        </Box>

                    </div>
                    {category?.name !== "Other" && (
                        <span
                            onClick={(e) => handleClick(e)}
                            id="add-other-dropdown"
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                        >
                            <ThreeDotsIcon />
                        </span>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        className={classes.dropMenu}
                        PaperProps={{
                            elevation: 0,
                        }}
                        transformOrigin={{
                            horizontal: "center",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                        }}
                    >
                        {/* <MenuItem>Rename</MenuItem> */}
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation()
                                // let days = 0
                                // const createDate = outcomeState.userDetails.createdAt
                                // const diff = moment().diff(moment(createDate), 'days')
                                // if (diff !== 0 && diff < 30) {
                                //     days = 30 - diff
                                // } else if (diff === 0) {
                                //     days = 30 - diff
                                // }
                                // if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 5 && outcomeState.userDetails.level === "LOW") {
                                //     trackingDispatch(setTrackingState({
                                //         limitPopupVisible: true,
                                //         limitPopuptype: 'outcome'
                                //     }))
                                // } else {
                                trackingDispatch(setTrackingState({
                                    showOutcome: true,
                                    openStartModal: true,
                                    addOutcomeTracking: true,
                                }))
                                // }
                                setAnchorEl(null)

                                // psetShowOutcome(true);
                                // psetStartModal(true);
                                // psetAddOutcomeTracking(true);
                            }}
                        >
                            Add outcome
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation()
                                // let days = 0
                                // const createDate = outcomeState.userDetails.createdAt
                                // const diff = moment().diff(moment(createDate), 'days')
                                // if (diff !== 0 && diff < 30) {
                                //     days = 30 - diff
                                // } else if (diff === 0) {
                                //     days = 30 - diff
                                // }
                                // if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 10 && outcomeState.userDetails.level === "LOW") {
                                //     trackingDispatch(setTrackingState({
                                //         limitPopupVisible: true,
                                //         limitPopuptype: 'influence'
                                //     }))
                                // } else {
                                trackingDispatch(setTrackingState({
                                    showInfluence: true,
                                    openStartModalInfluence: true,
                                    addInfluenceTracking: true,
                                }))
                                // }
                                setAnchorEl(null)


                                // psetShowInfluence(true);
                                // psetStartModalInfluence(true);
                                // psetAddInfluenceTracking(true);
                            }}
                        >
                            Add influence
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation()
                                // let days = 0
                                // const createDate = outcomeState.userDetails.createdAt
                                // const diff = moment().diff(moment(createDate), 'days')
                                // if (diff !== 0 && diff < 30) {
                                //     days = 30 - diff
                                // } else if (diff === 0) {
                                //     days = 30 - diff
                                // }
                                // if (days > 0 && outcomeState?.reflectiveList && outcomeState?.reflectiveList?.length >= 3 && outcomeState.userDetails.level === "LOW") {
                                //     trackingDispatch(setTrackingState({
                                //         limitPopupVisible: true,
                                //         limitPopuptype: 'question'
                                //     }))
                                // } else {
                                trackingDispatch(setTrackingState({
                                    showQues: true,
                                    openStartModalQues: true,
                                    addQuesTracking: true,
                                }))

                                // }
                                setAnchorEl(null)

                                // psetShowQues(true);
                                // psetStartModalQues(true);
                                // psetAddQuesTracking(true);
                            }}
                        >
                            Add qualitative reflection
                        </MenuItem>
                    </Menu>
                </div>
                {category?.integrationType && category?.integrationType !== "GENERAL" && [...outcomeState?.journeyCategoryExpandList].includes(category.categoryId) && (
                    <Box display={"flex"} width={"100%"} borderRadius={"12px"} gap={"8px"} padding={"16px"} sx={{
                        background: "#FFB378"
                    }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2 2.00004V5.60004C15.2 5.75917 15.1368 5.91178 15.0243 6.0243C14.9117 6.13683 14.7591 6.20004 14.6 6.20004H11C10.8813 6.20013 10.7652 6.165 10.6664 6.09907C10.5676 6.03315 10.4907 5.93941 10.4452 5.82972C10.3998 5.72002 10.3879 5.59931 10.4111 5.48286C10.4343 5.36641 10.4915 5.25945 10.5755 5.17554L11.9487 3.80004C10.844 2.7416 9.37466 2.14842 7.84474 2.14329H7.81099C6.2433 2.14022 4.73744 2.75452 3.61924 3.85329C3.50465 3.96026 3.35276 4.01813 3.19605 4.01454C3.03933 4.01094 2.89025 3.94616 2.78069 3.83406C2.67113 3.72195 2.6098 3.57142 2.60981 3.41466C2.60981 3.25791 2.67117 3.10739 2.78074 2.99529C4.11827 1.68847 5.91176 0.953296 7.78171 0.945317C9.65165 0.937338 11.4514 1.65719 12.8 2.95254L14.177 1.57554C14.261 1.49205 14.3678 1.43528 14.484 1.41238C14.6002 1.38949 14.7206 1.40149 14.83 1.44688C14.9393 1.49227 15.0328 1.56902 15.0987 1.66745C15.1645 1.76589 15.1998 1.88161 15.2 2.00004ZM12.3807 12.1468C11.2694 13.2323 9.78025 13.8444 8.22673 13.8543C6.67322 13.8643 5.17641 13.2712 4.05124 12.2L5.42449 10.8268C5.50927 10.743 5.56718 10.6359 5.59082 10.519C5.61445 10.4022 5.60275 10.281 5.55721 10.1708C5.51167 10.0606 5.43434 9.96653 5.3351 9.9005C5.23585 9.83447 5.11919 9.7995 4.99999 9.80004H1.39999C1.24086 9.80004 1.08825 9.86325 0.975724 9.97578C0.863202 10.0883 0.799988 10.2409 0.799988 10.4V14C0.799895 14.1188 0.835033 14.2349 0.900956 14.3336C0.966878 14.4324 1.06062 14.5094 1.17031 14.5548C1.28001 14.6003 1.40072 14.6121 1.51717 14.589C1.63362 14.5658 1.74057 14.5085 1.82449 14.4245L3.19999 13.0475C4.52914 14.3303 6.30281 15.0494 8.14999 15.0545H8.18974C10.0708 15.0594 11.878 14.323 13.22 13.0048C13.3296 12.8927 13.3909 12.7422 13.3909 12.5854C13.3909 12.4287 13.3296 12.2781 13.22 12.166C13.1105 12.0539 12.9614 11.9891 12.8047 11.9855C12.648 11.9819 12.4961 12.0398 12.3815 12.1468H12.3807Z" fill="var(--color-text-primary)" />
                        </svg>
                        <CommonTypo>The integration shares your data at midnight with us</CommonTypo>
                    </Box>
                )}

            </div>

        </td>
        {!index && calendar[0] && calendar[0].map((date, index) => {
            const headerClass = `${moment().isSame(date, "day")
                ? clsx(
                    outcomeState?.isExpand
                        ? clsx(classes.headerBlocksExpand, classes.tableClass)
                        : classes.headerBlocks,
                    classes.background
                )
                : outcomeState?.isExpand
                    ? clsx(classes.headerBlocksExpand, classes.commonBackground, classes.tableClass)
                    : clsx(classes.headerBlocks, classes.commonBackground)
                }`;
            return <td className={clsx(classes.cell, classes.stickyCell)} id={date.format(TRACKING_CONST.DATE_FORMAT)} style={!index ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}>
                {<div className={headerClass} ref={moment().isSame(date, "day") ? currentDateRef : null} style={{ position: "sticky", top: "70px", alignSelf: "flex-start", zIndex: "100", padding: "0px 16px" }}>
                    <Typography
                        variant="caption"
                        component="p"
                        className={classes.weekday}
                    >
                        {moment(date).format("ddd")}
                    </Typography>
                    <Typography
                        variant="caption"
                        fontSize={"0.875rem!important"}
                        color={palette.common.black}
                        sx={{ fontWeight: 400 }}
                    >
                        {date.format("D")}{" "}
                    </Typography>
                </div>}

            </td>
        })}

    </tr>];
    if ([...outcomeState?.journeyCategoryExpandList].includes(category.categoryId)) {
        if (category.name !== "Other") {
            for (let index = 0; index < category.alls.length; index++) {
                const element = category.alls[index];
                arrToReturn.push(<tr className={classes.row} style={{
                    height: "1px",
                }}>
                    {element.outcomeId ? <PersonalTrackingOutcome item={element} isExpand={outcomeState?.isExpand} categoryId={category.categoryId} /> :
                        element.influenceId ? <PersonalTrackingInfluence item={element} isExpand={outcomeState?.isExpand} /> :
                            element.reflectiveId ? <PersonalTrackingReflective item={element} isExpand={outcomeState?.isExpand} /> :
                                <td className={clsx(classes.cell, classes.stickyLeftCell)}>{null}</td>}

                    {calendar[0] && calendar[0].map((date, i) => {
                        if (element.outcomeId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalOutcome
                                item={element}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                date={date}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                journyList={journyList}
                                setOutcomeId={setOutcomeId}
                                category={category}
                                outcomeId={outcomeId} callservice={callservice} isLast={isLast} />
                        }
                        if (element.influenceId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalInfluence
                                item={element}
                                journyList={journyList}
                                date={date}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                setOutcomeId={setOutcomeId}
                                category={category}
                                setInfluenceId={setInfluenceId}
                                influenceIdInfluence={influenceId}
                                setInfluenceIdCalendar={setInfluenceIdCalendar}
                                setSelectedDateInfluence={setSelectedDateInfluence}
                                callservice={callservice}
                                isLast={isLast}
                            // refId={refId}
                            // setRefId={setRefId}
                            />
                        }
                        if (element.reflectiveId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalReflective
                                item={element}
                                date={date}
                                journyList={journyList}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                setShowOutcomeInput={setShowOutcomeInput}
                                setShowInfluenceInput={setShowInfluenceInput} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId} setRefId={setRefId}
                                refId={refId}
                                callservice={callservice}
                                isLast={isLast}
                            />
                        }
                        return null
                    })
                    }
                </tr>)
            }
        } else if (category.name === "Other") {
            if (category.outcomes && category.outcomes.length) {
                for (let index = 0; index < category.outcomes.length; index++) {
                    const element = category.outcomes[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>

                        <PersonalTrackingOutcome item={element} isExpand={outcomeState?.isExpand} categoryId={category.categoryId} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;

                            if (!category.reflectives.length && !category.influences.length && i === (calendar[0].length - 1) && (index === category.outcomes.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalOutcome
                                item={element}
                                date={date}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                journyList={journyList}
                                category={category}
                                setOutcomeId={setOutcomeId}
                                outcomeId={outcomeId} callservice={callservice} isLast={isLast} />
                        })}
                    </tr>)
                }
            }
            if (category.influences && category.influences.length) {
                for (let index = 0; index < category.influences.length; index++) {
                    const element = category.influences[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>

                        <PersonalTrackingInfluence item={element} isExpand={outcomeState?.isExpand} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;
                            if (!category.reflectives.length && i === (calendar[0].length - 1) && (index === category.influences.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalInfluence item={element} date={date}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]} journyList={journyList} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId}
                                influenceIdInfluence={influenceId}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                setInfluenceIdCalendar={setInfluenceIdCalendar}
                                setSelectedDateInfluence={setSelectedDateInfluence} callservice={callservice} isLast={isLast} />
                        })}
                    </tr>)
                }
            }
            if (category.reflectives && category.reflectives.length) {
                for (let index = 0; index < category.reflectives.length; index++) {
                    const element = category.reflectives[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>
                        <PersonalTrackingReflective item={element} isExpand={outcomeState?.isExpand} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;
                            if (i === (calendar[0].length - 1) && (index === category.reflectives.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalReflective item={element} date={date} isExpand={outcomeState?.isExpand} calendar={calendar[0]} journyList={journyList}
                                setShowOutcomeInput={setShowOutcomeInput}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                setShowInfluenceInput={setShowInfluenceInput} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId} setRefId={setRefId} refId={refId} callservice={callservice}
                                isLast={isLast} />
                        })}
                    </tr>)
                }
            }

        }
    }


    return <>{arrToReturn}</>
}

export default PersonalItemRow