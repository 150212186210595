import React, { useContext, useEffect, useLayoutEffect, useRef } from "react"
import useStyles from "./TrackingClasses"
import { TrackingContext } from "../../context/userTracking";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import clsx from "clsx";
import { UserTypeContext } from "../../context/userStatus";
import palette from "../../theme/palette";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandIcon from "../../assets/images/icon/ExpandIcon";
import ThreeDotsIcon from "../../assets/images/icon/ThreeDotsIcon";
import { setTrackingState } from "../../store/actions/trackerAction";
import IconPersonalOutcome from "./IconPersonalOutcome";
import IconPersonalInfluence from "./IconPersonalInfluence";
import IconPersonalReflective from "./IconPersonalReflective";
import PersonalTrackingOutcome from "./PersonalTrackingOutcome";
import PersonalTrackingInfluence from "./PersonalTrackingInfluence";
import PersonalTrackingReflective from "./PersonalTrackingReflective";
import { addCategoryExpandAction } from "../../store/actions/userTypeAction";
import HTMLTooltip from "../../components/HTMLTooltip";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import { pxToRem } from "../../theme/typography";
import AddPlusIcon from "../../assets/images/icon/AddPlusIcon";
import { TRACKING_CONST } from "./constants";
const ItemRow = ({
    // index,
    journyList,
    setOutcomeId,
    outcomeId,
    setInfluenceId,
    influenceId,
    setInfluenceIdCalendar,
    setSelectedDateInfluence,
    refId,
    setRefId,
    setShowOutcomeInput,
    setShowInfluenceInput,
    callservice
}) => {
    const classes = useStyles()
    const currentDateRef = useRef(null)
    const currentDateExpandRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
        // setCategoryId(category?.categoryId);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
    const {
        calendar,
        showLoader
    } = ITrackerState

    useEffect(() => {
        const todayEl = document.getElementById(moment().format(TRACKING_CONST.DATE_FORMAT))
        if (todayEl && !outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: "end", inline: "end"
            })
        }
        if (todayEl && outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            })
        }
    }, [currentDateRef && currentDateRef.current, outcomeState?.isExpand])
    // useEffect(() => {
    //     console.log("currentDateRef", currentDateRef);
    //     if (currentDateRef && currentDateRef.current && outcomeState?.isExpand) {
    //         setTimeout(() => {
    //             console.log("outcomeState?.isExpand new", outcomeState?.isExpand);

    //             currentDateRef.current.scrollIntoView()
    //             // // Adjust scroll to add margin on the right
    //             // const marginRight = 300; // Adjust this value as needed
    //             // document.getElementById("main-table").scrollLeft -= -marginRight
    //         }, 1000);

    //     }
    // }, [outcomeState?.isExpand])
    const arrToReturn = [<tr className={classes.row} key="outcome">

        <td className={clsx(classes.cell, classes.stickyLeftCell)}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 0px",
                    gap: '8px',
                    flex: '1 0 0',
                }}
                onClick={() => {
                    const expanded = [...outcomeState?.journeyCategoryExpandList].includes("outcome")
                    if (!expanded) {
                        const newArr = [...outcomeState?.journeyCategoryExpandList, "outcome"]
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    } else {
                        const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== "outcome")
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    }
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"

                    }}
                >
                    <MuiAccordionSummary expandIcon={<ExpandIcon />} sx={![...outcomeState?.journeyCategoryExpandList].includes("outcome") ? {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: "rotate(180deg)"
                        }
                    } : {}} />

                    <Typography
                        variant="h3"
                        fontWeight={600}
                        fontSize={'1.125rem !important'}
                        className={classes.listTitle}
                        color={palette.common.black}
                    >
                        {"Outcomes"}
                    </Typography>
                    <IconButton
                        style={{
                            padding: "0px",
                            height: "20px",
                            width: "20px"
                        }}
                    //   onClick={() => setArchiveConfirmationModal(true)}
                    >
                        <HTMLTooltip customIcon={<div><InfoIcon color={"var(--color-new-main)"} size={"14"} /></div>} title="Outcomes refer to the psychological states or tangible results that are influenced by your daily habits and actions. These can include your overall well-being, sense of progress, energy levels, and sense of connection. Outcomes are what you track to see the impact of various influences over time. Think of outcomes as the 'results' of your personal experiments." fontProps={{
                            fontSize: `${pxToRem(12)} !important`
                        }} sx={{
                            '& .MuiTooltip-tooltip': {
                                maxWidth: "300px"
                            }
                        }} />
                    </IconButton>
                </div>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        // let days = 0
                        // console.log("outcomeState.userDetails", outcomeState.userDetails)
                        // const createDate = outcomeState.userDetails.createdAt
                        // const diff = moment().diff(moment(createDate), 'days')
                        // if (diff !== 0 && diff < 30) {
                        //     days = 30 - diff
                        // } else if (diff === 0) {
                        //     days = 30 - diff
                        // }
                        // if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 5 && outcomeState.userDetails.level === "LOW") {
                        //     trackingDispatch(setTrackingState({
                        //         limitPopupVisible: true,
                        //         limitPopuptype: 'outcome'
                        //     }))
                        // } else {
                            trackingDispatch(setTrackingState({
                                showOutcome: true,
                                openStartModal: true,
                                addOutcomeTracking: true,
                                categoryId: ""
                            }))
                        // }

                    }}
                >
                    <AddPlusIcon />
                </span>
            </div>
        </td>
        {calendar[0] && calendar[0].map((date, index) => {
            const headerClass = `${moment().isSame(date, "day")
                ? clsx(
                    outcomeState?.isExpand
                        ? clsx(classes.headerBlocksExpand, classes.tableClass)
                        : classes.headerBlocks,
                    classes.background
                )
                : outcomeState?.isExpand
                    ? clsx(classes.headerBlocksExpand, classes.commonBackground, classes.tableClass)
                    : clsx(classes.headerBlocks, classes.commonBackground)
                }`;
            return <td className={clsx(classes.cell, classes.stickyCell)} id={date.format(TRACKING_CONST.DATE_FORMAT)} ref={moment().isSame(date, "day") ? !outcomeState?.isExpand ? currentDateRef : currentDateExpandRef : null} key={date.format(TRACKING_CONST.DATE_FORMAT)} style={!index ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}>
                {<div className={headerClass}  style={{ position: "sticky", top: "70px", alignSelf: "flex-start", zIndex: "100", padding: "0px 16px" }}>
                    <Typography
                        variant="caption"
                        component="p"
                        fontSize={`${pxToRem(10)}!important`}
                        className={classes.weekday}
                    >
                        {moment(date).format("ddd")}
                    </Typography>
                    <Typography
                        variant="caption"
                        fontSize={`${pxToRem(14)}!important`}
                        color={"var(--sidebar-active-color)"}
                        sx={{ fontWeight: 400 }}
                    >
                        {date.format("D")}{" "}
                    </Typography>
                </div>}

            </td>
        })}

    </tr>];
    if ([...outcomeState?.journeyCategoryExpandList].includes("outcome") && outcomeState.outcomeList && outcomeState.outcomeList.length) {
        for (let index = 0; index < outcomeState.outcomeList.length; index++) {
            const element = outcomeState.outcomeList[index];
            arrToReturn.push(<tr className={classes.row} key={"outcome_row" + element._id} style={{
                height: "1px",
            }}>
                {<PersonalTrackingOutcome item={element} isExpand={outcomeState?.isExpand} />}
                {calendar[0] && calendar[0].map((date, i) => {
                    let isLast = false;
                    if (!outcomeState.reflectiveList.length && !outcomeState.influenceList.length && i === (calendar[0].length - 1) && (index === outcomeState.outcomeList.length - 1)) {
                        isLast = true
                    }
                    return <IconPersonalOutcome
                        item={{
                            ...element,
                            outcomesResponses: [...outcomeState.journeyList.outcome_data].filter(it => it.outcomeId === element.outcomeId)
                        }}
                        style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                        key={date.format(TRACKING_CONST.DATE_FORMAT) + "_outcomes" + element._id}
                        date={date}
                        isExpand={outcomeState?.isExpand}
                        calendar={calendar[0]}
                        journyList={outcomeState?.journeyCategoryList}
                        setOutcomeId={setOutcomeId}
                        outcomeId={outcomeId}
                        callservice={callservice}
                        isLast={isLast}
                    />
                })}
            </tr>)
        }
    }
    arrToReturn.push(<tr className={classes.row} key="influence" >

        <td className={clsx(classes.cell, classes.stickyLeftCell)}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 0px",
                    gap: '8px',
                    flex: '1 0 0',
                }}
                onClick={() => {
                    const expanded = [...outcomeState?.journeyCategoryExpandList].includes("influence")
                    if (!expanded) {
                        const newArr = [...outcomeState?.journeyCategoryExpandList, "influence"]
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    } else {
                        const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== "influence")
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    }
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"

                    }}
                >
                    <MuiAccordionSummary expandIcon={<ExpandIcon />} sx={![...outcomeState?.journeyCategoryExpandList].includes("influence") ? {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: "rotate(180deg)"
                        }
                    } : {}} />

                    <Typography
                        variant="h3"
                        fontWeight={600}
                        fontSize={'1.125rem !important'}
                        className={classes.listTitle}
                        color={palette.common.black}
                    >
                        {"Influences"}
                    </Typography>
                    <IconButton
                        style={{
                            padding: "0px",
                            height: "20px",
                            width: "20px"
                        }}
                    //   onClick={() => setArchiveConfirmationModal(true)}
                    >
                        <HTMLTooltip customIcon={<div><InfoIcon color={"var(--color-new-main)"} size={"14"} /></div>} title="Influences are the behaviors, activities, and external factors that can affect your Outcomes. This includes anything from the amount of sleep you get, to your exercise routine, to the time you spend with loved ones. By tracking Influences, you identify what factors have the most significant positive or negative impact on your Outcomes." fontProps={{
                            fontSize: `${pxToRem(12)} !important`
                        }} sx={{
                            '& .MuiTooltip-tooltip': {
                                maxWidth: "300px"
                            }
                        }} />
                    </IconButton>
                </div>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        // let days = 0
                        // const createDate = outcomeState.userDetails.createdAt
                        // const diff = moment().diff(moment(createDate), 'days')
                        // if (diff !== 0 && diff < 30) {
                        //     days = 30 - diff
                        // } else if (diff === 0) {
                        //     days = 30 - diff
                        // }
                        // if (days > 0 && outcomeState?.influenceList && outcomeState?.influenceList?.length >= 10 && outcomeState.userDetails.level === "LOW") {
                        //     trackingDispatch(setTrackingState({
                        //         limitPopupVisible: true,
                        //         limitPopuptype: 'influence'
                        //     }))
                        // } else {
                            trackingDispatch(setTrackingState({
                                showInfluence: true,
                                openStartModalInfluence: true,
                                addInfluenceTracking: true,
                                categoryId: ""
                            }))
                        // }

                    }}
                >
                    <AddPlusIcon />
                </span>
            </div>
        </td>
    </tr>)
    console.log("[...outcomeState?.journeyCategoryExpandList]", [...outcomeState?.journeyCategoryExpandList]);
    
    if ([...outcomeState?.journeyCategoryExpandList].includes("influence") && outcomeState.influenceList && outcomeState.influenceList.length) {
        for (let index = 0; index < outcomeState.influenceList.length; index++) {
            const element = outcomeState.influenceList[index];
            arrToReturn.push(<tr className={classes.row} style={{
                height: "1px",
            }} key={"influence_row" + element._id} >
                {<PersonalTrackingInfluence item={element} isExpand={outcomeState?.isExpand} />}
                {calendar[0] && calendar[0].map((date, i) => {
                    let isLast = false;
                    if (outcomeState.reflectiveList && !outcomeState.reflectiveList.length && i === (calendar[0].length - 1) && (index === outcomeState.influenceList.length - 1)) {
                        isLast = true
                    }
                    return <IconPersonalInfluence
                        item={{
                            ...element,
                            influenceResponses: outcomeState.journeyList && [...outcomeState.journeyList.influence_data].filter(it => it.influenceId === element.influenceId)
                        }}
                        style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                        key={date.format(TRACKING_CONST.DATE_FORMAT) + "_influence" + element._id}
                        date={date}
                        isExpand={outcomeState?.isExpand}
                        calendar={calendar[0]}
                        journyList={outcomeState?.journeyCategoryList}
                        setOutcomeId={setOutcomeId}
                        setInfluenceId={setInfluenceId}
                        influenceIdInfluence={influenceId}
                        setInfluenceIdCalendar={setInfluenceIdCalendar}
                        setSelectedDateInfluence={setSelectedDateInfluence} callservice={callservice}
                        isLast={isLast}
                    />
                })}
            </tr>)
        }
    }
    arrToReturn.push(<tr className={classes.row} key="reflective">

        <td className={clsx(classes.cell, classes.stickyLeftCell)}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 0px",
                    gap: '8px',
                    flex: '1 0 0',
                }}
                onClick={() => {
                    const expanded = [...outcomeState?.journeyCategoryExpandList].includes("reflective")
                    if (!expanded) {
                        const newArr = [...outcomeState?.journeyCategoryExpandList, "reflective"]
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    } else {
                        const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== "reflective")
                        localStorage.setItem("menuExpandableIds", JSON.stringify(newArr))
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    }
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"

                    }}
                >
                    <MuiAccordionSummary expandIcon={<ExpandIcon />} sx={![...outcomeState?.journeyCategoryExpandList].includes("reflective") ? {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: "rotate(180deg)"
                        }
                    } : {}} />

                    <Typography
                        variant="h3"
                        fontWeight={600}
                        fontSize={'1.125rem !important'}
                        className={classes.listTitle}
                        color={palette.common.black}
                    >
                        {"Qualitative Reflection"}
                    </Typography>
                    <IconButton
                        style={{
                            padding: "0px",
                            height: "20px",
                            width: "20px"
                        }}
                    //   onClick={() => setArchiveConfirmationModal(true)}
                    >
                        <HTMLTooltip customIcon={<div><InfoIcon color={"var(--color-new-main)"} size={"14"} /></div>} title="Reflective Questions are prompts that encourage you to think deeper about your day, your reactions to certain events, and the reasons behind your tracked outcomes and influences. They help integrate a journaling aspect into your tracking, allowing for richer insights and a better understanding of your personal development journey." fontProps={{
                            fontSize: `${pxToRem(12)} !important`
                        }} sx={{
                            '& .MuiTooltip-tooltip': {
                                maxWidth: "300px"
                            }
                        }} />
                    </IconButton>
                </div>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        // let days = 0
                        // const createDate = outcomeState.userDetails.createdAt
                        // const diff = moment().diff(moment(createDate), 'days')
                        // if (diff !== 0 && diff < 30) {
                        //     days = 30 - diff
                        // } else if (diff === 0) {
                        //     days = 30 - diff
                        // }
                        // if (days > 0 && outcomeState?.reflectiveList && outcomeState?.reflectiveList?.length >= 3 && outcomeState.userDetails.level === "LOW") {
                        //     trackingDispatch(setTrackingState({
                        //         limitPopupVisible: true,
                        //         limitPopuptype: 'question'
                        //     }))
                        // } else {
                            trackingDispatch(setTrackingState({
                                showQues: true,
                                openStartModalQues: true,
                                addQuesTracking: true,
                                categoryId: ""
                            }))

                        // }

                    }}
                >
                    <AddPlusIcon />
                </span>
            </div>
        </td>
    </tr>)
    if ([...outcomeState?.journeyCategoryExpandList].includes("reflective") && outcomeState.reflectiveList && outcomeState.reflectiveList.length) {
        for (let index = 0; index < outcomeState.reflectiveList.length; index++) {
            const element = outcomeState.reflectiveList[index];
            arrToReturn.push(<tr className={classes.row} style={{
                height: "1px",
            }} key={"reflective_row" + element._id}>
                {<PersonalTrackingReflective item={element} isExpand={outcomeState?.isExpand} />}
                {calendar[0] && calendar[0].map((date, i) => {

                    return <IconPersonalReflective
                        item={{
                            ...element,
                            reflectiveResponses: outcomeState.journeyList && [...outcomeState.journeyList.reflective_data].filter(it => it.reflectiveId === element.reflectiveId)
                        }}
                        key={date.format(TRACKING_CONST.DATE_FORMAT) + "_reflective" + element._id}
                        date={date}
                        style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                        isExpand={outcomeState?.isExpand}
                        calendar={calendar[0]}
                        journyList={outcomeState?.journeyCategoryList}
                        setShowOutcomeInput={setShowOutcomeInput}
                        setShowInfluenceInput={setShowInfluenceInput}
                        setOutcomeId={setOutcomeId}
                        setInfluenceId={setInfluenceId}
                        setRefId={setRefId} refId={refId}
                        callservice={callservice}
                        isLast={i === (calendar[0].length - 1) && (index === outcomeState.reflectiveList.length - 1)}
                    />
                })}
            </tr>)
        }
    }
    return <>{arrToReturn}{!showLoader && <></>}</>
}

export default ItemRow