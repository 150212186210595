import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useStyles from "./TrackingClasses";
import moment from "moment";
import palette from "../../theme/palette";
import { Tooltip, Typography } from "@mui/material";
import AddYesNoModal from "../tracker/addYesNoModal";
import AddTimeOfDay from "../tracker/addTimeOfDayModel";
import AddHoursModal from "../tracker/addHoursModal";
import AddTrackingEventInfluenceModal from "./../tracker/addTrackingEventInfluenceModal";
import SnackBarComp from "../../components/snackBar";
import { UserTypeContext } from "../../context/userStatus";
import { TrackingContext } from "../../context/userTracking";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
import { trackingService } from "../../services/tracking";
import {
  setDayCompleteMessage,
  setTrackingState,
} from "../../store/actions/trackerAction";
import HTMLTooltip from "../../components/HTMLTooltip";
import { pxToRem } from "../../theme/typography";
import { isFirefox, MeasurementUnit } from "../../utils/staticDataJson";
import { TRACKING_CONST } from "./constants";
import DoubleClickHandler from "../tracker/DoubleClickHandler";
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const IconPersonalInfluence = ({
  item: catItem,
  category = {} as any,
  style = {},
  date,
  isExpand,
  calendar,
  journyList,
  setOutcomeId,
  callservice,
  setInfluenceId,
  setInfluenceIdCalendar,
  setSelectedDateInfluence,
  influenceIdInfluence,
  isLast = false,
}) => {
  const { lockMatrix = false } = catItem
  const classes = useStyles();
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const textareaRef = useRef<HTMLTextAreaElement>();
  const formatedDate = moment(date).format("MM-DD-YYYY");
  const [showInput, setShowInput] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);
  const [openTrackingEventModal, setOpenTrackingEventModal] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [openTimeOfDayModal, setOpenTimeOfDayModal] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [inputValue, setInputValue] = useState<any>();
  const [inputNotesValue, setNotesInputValue] = useState<any>("");
  const [influenceIndex, setInfluenceIndex] = useState(0);
  const [visibleTooltip, setVisibleTooltip] = useState(true);
  const [headerDate, setHeaderDate] = useState(null);
  const [tooltipId, setTooltipId] = useState(null);
  const [optionIndex, setOptionIndex] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState<any>();
  const [displayBorder, setDisplayBorder] = useState(false);
  const { influenceResponses = [] } = catItem;
  const heightObj = TRACKING_CONST.EXPAND_HEIGHT;
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        32
      )}px`;
    }
  }, [showNotesInput, inputNotesValue]);
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [showNotesInput]);
  useEffect(() => {
    if (isLast && ITrackerState.showLoader) {
      trackingDispatch(
        setTrackingState({
          showLoader: false,
          isLastAlreadyLoaded: true,
        })
      );
    }
  }, [isLast, ITrackerState.showLoader]);
  const clickCondition = moment(date).isBetween(
    moment().subtract(8, "year"),
    moment().add(1, 'day').startOf('day')
  );
  const foundMeasurement = MeasurementUnit.find(
    (it) => it.id === catItem.unitValue
  );
  const found =
    influenceResponses && influenceResponses.length > 0
      ? influenceResponses.find((journey) => journey?.date === formatedDate)
      : undefined;
  let nearValue = undefined;
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce(function (prev, curr) {
        return Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev;
      });
    }
    return null;
  };
  const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (
      influenceItem.type === "TYPE2" &&
      influenceItem.options.length &&
      influenceItem.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.type === "TYPE12") {
      let arr = [];
      if (influenceItem?.options && influenceItem?.options.length) {
        arr = [...influenceItem?.options].map((it) => it.value);
      }
      if (
        influenceItem.type === "TYPE2" &&
        influenceItem.options.length &&
        influenceItem.options[val - 1]
      ) {
        return influenceItem.options[val - 1].colorCode;
      }

      const returnVal = closest(val, arr);
      if (
        influenceItem?.options &&
        influenceItem?.options.length &&
        !Number.isNaN(returnVal)
      ) {
        const foundIndexOfValue = influenceItem?.options.findIndex(
          (it) => it.value === returnVal
        );

        if (foundIndexOfValue > -1) {
          return influenceItem?.options[foundIndexOfValue]?.colorCode;
        }
      }
      return false;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    const returnVal = closest(val, arr);
    if (influenceItem?.options && influenceItem?.options.length && returnVal) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  const renderToNextInput = (e, categoryId, id) => {
    if (outcomeState.isPersonal) {
      const foundOutcome = (journyList || []).findIndex(
        (it) => it.influenceIds && it.influenceIds.includes(catItem.influenceId)
      );
      if (foundOutcome > -1) {
        const foundCategory = journyList.find(
          (it) => it.categoryId === categoryId
        );
        if (foundCategory && foundCategory.alls && foundCategory.alls.length) {
          const findIndexOfEl = foundCategory.alls.findIndex(
            (it) => it._id === id
          );
          if (findIndexOfEl > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              foundCategory.alls[findIndexOfEl + 1] &&
              foundCategory.alls[findIndexOfEl + 1]._id
            ) {
              if (foundCategory.alls[findIndexOfEl + 1].skipMetric || foundCategory.alls[findIndexOfEl + 1]?.lockMatrix) {
                renderToNextInput(
                  e,
                  categoryId,
                  foundCategory.alls[findIndexOfEl + 1]._id
                );
              } else {
                setTooltipId(foundCategory.alls[findIndexOfEl + 1]._id);
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                setHeaderDate(date);
                setTooltipId(null);
                setVisibleTooltip(false);
                if (foundCategory.alls[findIndexOfEl + 1].outcomeId) {
                  setOutcomeId(foundCategory.alls[findIndexOfEl + 1].outcomeId);
                }
                if (foundCategory.alls[findIndexOfEl + 1].influenceId) {
                  setInfluenceId(
                    foundCategory.alls[findIndexOfEl + 1].influenceId
                  );
                  setTooltipId(foundCategory.alls[findIndexOfEl + 1]._id);
                  setVisibleTooltip(true);
                }
                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl + 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) === 40) {
              const foundCategoryIndex = journyList.findIndex(
                (it) => it.categoryId === categoryId
              );
              if (foundCategoryIndex > -1) {
                if (journyList[foundCategoryIndex + 1].name === "Other") {
                  if (
                    journyList[foundCategoryIndex + 1].outcomes &&
                    journyList[foundCategoryIndex + 1].outcomes.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].outcomes[0].skipMetric ||
                      journyList[foundCategoryIndex + 1].outcomes[0].lockMatrix
                    ) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  } else if (
                    journyList[foundCategoryIndex + 1].influences &&
                    journyList[foundCategoryIndex + 1].influences.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].influences[0]
                        .skipMetric ||
                      journyList[foundCategoryIndex + 1].influences[0].lockMatrix
                    ) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  }
                } else {
                  if (
                    journyList[foundCategoryIndex + 1] &&
                    journyList[foundCategoryIndex + 1].alls &&
                    journyList[foundCategoryIndex + 1].alls.length
                  ) {
                    if (journyList[foundCategoryIndex + 1].alls[0].skipMetric || journyList[foundCategoryIndex + 1].alls[0].lockMatrix) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              foundCategory.alls[findIndexOfEl - 1] &&
              foundCategory.alls[findIndexOfEl - 1]._id
            ) {
              if (foundCategory.alls[findIndexOfEl - 1].skipMetric || foundCategory.alls[findIndexOfEl - 1].lockMatrix) {
                renderToNextInput(
                  e,
                  categoryId,
                  foundCategory.alls[findIndexOfEl - 1]._id
                );
              } else {
                setTooltipId(foundCategory.alls[findIndexOfEl - 1]._id);

                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                setHeaderDate(date);

                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (Number(e.keyCode) === 38) {
              const foundCategoryIndex = journyList.findIndex(
                (it) => it.categoryId === categoryId
              );
              if (foundCategoryIndex > -1) {
                if (
                  journyList[foundCategoryIndex - 1] &&
                  journyList[foundCategoryIndex - 1].alls &&
                  journyList[foundCategoryIndex - 1].alls.length
                ) {
                  if (
                    journyList[foundCategoryIndex - 1].alls[
                      journyList[foundCategoryIndex - 1].alls.length - 1
                    ].skipMetric || 
                    journyList[foundCategoryIndex - 1].alls[
                      journyList[foundCategoryIndex - 1].alls.length - 1
                    ].lockMatrix
                  ) {
                    renderToNextInput(
                      e,
                      categoryId,
                      journyList[foundCategoryIndex - 1].alls[
                        journyList[foundCategoryIndex - 1].alls.length - 1
                      ]._id
                    );
                  } else {
                    const date = moment(formatedDate, "mm-DD-yyyy").format(
                      "mm-DD-yyyy"
                    );
                    const foundElement = document.getElementById(
                      date +
                      "_" +
                      journyList[foundCategoryIndex - 1].alls[
                        journyList[foundCategoryIndex - 1].alls.length - 1
                      ]._id
                    );
                    if (foundElement) {
                      foundElement.click();
                    }
                  }
                }
              }
            }

            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              setTooltipId(id);

              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              setHeaderDate(date);

              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              setHeaderDate(date);
              const foundElement = document.getElementById(date + "_" + id);
              setTooltipId(id);

              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      } else {
        const foundOther = (journyList || []).findIndex(
          (it) => it.name === "Other"
        );
        if (foundOther > -1) {
          const outcomeFound = journyList[foundOther].influences.findIndex(
            (it) => it._id === id
          );
          if (outcomeFound > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              journyList[foundOther].influences[outcomeFound + 1] &&
              journyList[foundOther].influences[outcomeFound + 1]._id
            ) {
              if (
                journyList[foundOther].influences[outcomeFound + 1].skipMetric || 
                journyList[foundOther].influences[outcomeFound + 1].lockMatrix
              ) {
                renderToNextInput(
                  e,
                  categoryId,
                  journyList[foundOther].influences[outcomeFound + 1]._id
                );
              } else {
                setTooltipId(
                  journyList[foundOther].influences[outcomeFound + 1]._id
                );
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                  "_" +
                  journyList[foundOther].influences[outcomeFound + 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) === 40) {
              if (journyList[foundOther].reflectives[0]?.skipMetric || journyList[foundOther].reflectives[0].lockMatrix) {
                renderToNextInput(
                  e,
                  categoryId,
                  journyList[foundOther].reflectives[0]._id
                );
              } else {
                const foundZeroInfluence =
                  journyList[foundOther].reflectives[0]?._id;
                if (foundZeroInfluence) {
                  const date = moment(formatedDate, "mm-DD-yyyy").format(
                    "mm-DD-yyyy"
                  );
                  const foundElement = document.getElementById(
                    date + "_" + journyList[foundOther].reflectives[0]._id
                  );
                  if (foundElement) {
                    foundElement.click();
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              journyList[foundOther].influences[outcomeFound - 1] &&
              journyList[foundOther].influences[outcomeFound - 1]._id
            ) {
              if (
                journyList[foundOther].influences[outcomeFound - 1]?.skipMetric ||
                journyList[foundOther].influences[outcomeFound - 1]?.lockMatrix
              ) {
                renderToNextInput(
                  e,
                  categoryId,
                  journyList[foundOther].influences[outcomeFound - 1]._id
                );
              } else {
                setTooltipId(
                  journyList[foundOther].influences[outcomeFound - 1]._id
                );

                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                  "_" +
                  journyList[foundOther].influences[outcomeFound - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (Number(e.keyCode) === 38) {
              if (
                journyList[foundOther].outcomes &&
                journyList[foundOther].outcomes.length
              ) {
                if (
                  journyList[foundOther].outcomes[
                    journyList[foundOther].outcomes.length - 1
                  ]?.skipMetric ||
                  journyList[foundOther].outcomes[
                    journyList[foundOther].outcomes.length - 1
                  ]?.lockMatrix
                ) {
                  renderToNextInput(
                    e,
                    categoryId,
                    journyList[foundOther].outcomes[
                      journyList[foundOther].outcomes.length - 1
                    ]._id
                  );
                } else {
                  const foundZeroInfluence =
                    journyList[foundOther].outcomes[
                      journyList[foundOther].outcomes.length - 1
                    ]?._id;
                  if (foundZeroInfluence) {
                    const date = moment(formatedDate, "mm-DD-yyyy").format(
                      "mm-DD-yyyy"
                    );
                    const foundElement = document.getElementById(
                      date +
                      "_" +
                      journyList[foundOther].outcomes[
                        journyList[foundOther].outcomes.length - 1
                      ]._id
                    );
                    if (foundElement) {
                      foundElement.click();
                    }
                  }
                }
              } else {
                if (
                  journyList[foundOther - 1] &&
                  journyList[foundOther - 1].alls &&
                  journyList[foundOther - 1].alls.length
                ) {
                  if (
                    journyList[foundOther - 1].alls[
                      journyList[foundOther - 1].alls.length - 1
                    ]?.skipMetric ||
                    journyList[foundOther - 1].alls[
                      journyList[foundOther - 1].alls.length - 1
                    ]?.lockMatrix
                  ) {
                    renderToNextInput(
                      e,
                      categoryId,
                      journyList[foundOther - 1].alls[
                        journyList[foundOther - 1].alls.length - 1
                      ]._id
                    );
                  } else {
                    const date = moment(formatedDate, "mm-DD-yyyy").format(
                      "mm-DD-yyyy"
                    );
                    const foundElement = document.getElementById(
                      date +
                      "_" +
                      journyList[foundOther - 1].alls[
                        journyList[foundOther - 1].alls.length - 1
                      ]._id
                    );
                    if (foundElement) {
                      foundElement.click();
                    }
                  }
                }
              }
            }

            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              setTooltipId(id);

              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              setTooltipId(id);

              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      }
    } else {
      if (e.key === "Enter" || Number(e.keyCode) === 40) {
        let nextId = "";
        let nextItem;
        let isLastOutcome = false;
        for (let i = 0; i < outcomeState?.influenceList?.length; i++) {
          if (outcomeState?.influenceList[i]._id === id) {
            if (i === outcomeState?.influenceList?.length - 1) {
              isLastOutcome = true;
            }
            nextId = outcomeState?.influenceList[i + 1]?._id;
            nextItem = outcomeState?.influenceList[i + 1];
          }
        }
        if ((nextItem?.skipMetric || nextItem?.lockMatrix) && nextId) {
          renderToNextInput(e, categoryId, nextId);
        } else if (nextItem) {
          setInfluenceId(nextId);
          setItem(nextItem);
          setTooltipId(nextId);
          const foundElement = document.getElementById(
            formatedDate + "_" + nextId
          );
          if (foundElement) {
            foundElement.click();
          }
          if (
            nextItem?.type === "TYPE12" &&
            nextItem?.unitValue === "timeofday" &&
            nextItem?.metric_type === "NEUTRAL"
          ) {
            setOpenTimeOfDayModal(true);
          } else {
            setShowInput(true);
            setInputValue("");
          }
        } else {
          if (isLastOutcome) {
            const filteredWithoutSkipMetric = [
              ...outcomeState?.reflectiveList,
            ].filter((it) => !it.skipMetric);
            if (filteredWithoutSkipMetric && filteredWithoutSkipMetric.length) {
              const foundZeroInfluence = filteredWithoutSkipMetric[0]?._id;
              if (foundZeroInfluence) {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + filteredWithoutSkipMetric[0]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            }
          } else {
            const foundZeroInfluence = outcomeState?.reflectiveList[0]?._id;
            if (foundZeroInfluence) {
              const foundElement = document.getElementById(
                formatedDate + "_" + outcomeState?.reflectiveList[0]._id
              );
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      }

      // up arrow key
      if (Number(e.keyCode) === 38) {
        let prevId = "";
        let prevItem;
        let isFirst = false;
        for (let i = 0; i < outcomeState?.influenceList?.length; i++) {
          if (outcomeState?.influenceList[i]._id === id) {
            if (i === 0) isFirst = true;
            prevId = outcomeState?.influenceList[i - 1]?._id;
            prevItem = outcomeState?.influenceList[i - 1];
          }
        }
        if ((prevItem?.skipMetric || prevItem?.lockMatrix) && prevId) {
          renderToNextInput(e, categoryId, prevId);
        } else if (prevId) {
          setInfluenceId(prevId);
          setItem(prevItem);
          const foundElement = document.getElementById(
            formatedDate + "_" + prevId
          );
          if (foundElement) {
            foundElement.click();
          }
          if (
            prevItem?.type === "TYPE12" &&
            prevItem?.unitValue === "timeofday" &&
            prevItem?.metric_type === "NEUTRAL"
          ) {
            setOpenTimeOfDayModal(true);
          } else {
            setShowInput(true);
            setInputValue("");
          }
        } else {
          if (isFirst) {
            const filteredWithoutSkipMetric = [
              ...outcomeState?.outcomeList,
            ].filter((it) => !it.skipMetric);
            if (filteredWithoutSkipMetric && filteredWithoutSkipMetric.length) {
              const foundZeroInfluence =
                filteredWithoutSkipMetric[filteredWithoutSkipMetric.length - 1]
                  ?._id;
              if (foundZeroInfluence) {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                  "_" +
                  filteredWithoutSkipMetric[
                    filteredWithoutSkipMetric.length - 1
                  ]?._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            }
          } else {
            const foundZeroInfluence =
              outcomeState?.outcomeList[outcomeState?.outcomeList.length - 1]
                ?._id;
            if (foundZeroInfluence) {
              const foundElement = document.getElementById(
                formatedDate +
                "_" +
                outcomeState?.outcomeList[
                  outcomeState?.outcomeList.length - 1
                ]._id
              );
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      }

      //right side arrow keys
      if (Number(e.keyCode) === 39) {
        setInfluenceId(id);
        setItem([...outcomeState?.influenceList].find((it) => it._id === id));
        const date = moment(formatedDate, "mm-DD-yyyy")
          .add(1, "day")
          .format("mm-DD-yyyy");
        const foundElement = document.getElementById(date + "_" + id);

        if (foundElement) {
          foundElement.click();
          return;
        }
      }

      if (Number(e.keyCode) === 37) {
        setInfluenceId(id);
        setItem([...outcomeState?.influenceList].find((it) => it._id === id));
        const date = moment(formatedDate, "mm-DD-yyyy")
          .subtract(1, "day")
          .format("mm-DD-yyyy");
        const foundElement = document.getElementById(date + "_" + id);
        if (foundElement) {
          foundElement.click();
        }
      }
    }
  };
  const handleBlurInput = (type, e: any, mouseOut, categoryId, id) => {
    setInfluenceIdCalendar(null);
    setSelectedDateInfluence(null);
    if (mouseOut) {
      setShowInput(false);
    }
    if (inputValue === "" || inputValue === undefined || inputValue === null) {
      if (
        e.key === "Enter" ||
        Number(e.keyCode) === 37 ||
        Number(e.keyCode) === 38 ||
        Number(e.keyCode) === 39 ||
        Number(e.keyCode) === 40 ||
        mouseOut
      ) {
        renderToNextInput(e, categoryId, id);
      }
      return null;
    }
    if (
      e.key === "Enter" ||
      Number(e.keyCode) === 37 ||
      Number(e.keyCode) === 38 ||
      Number(e.keyCode) === 39 ||
      Number(e.keyCode) === 40 ||
      mouseOut
    ) {
      if (type === "TYPE2") {
        if (catItem.absenceToggle) {
          if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            outcomeCreate();
            renderToNextInput(e, categoryId, id);
          } else {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Please enter value between 0 to 5");
            // renderToNextInput(e, categoryId, id);
          }
        } else {
          if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            outcomeCreate();
            renderToNextInput(e, categoryId, id);
          } else {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Please enter value between 1 to 5");
            // renderToNextInput(e, categoryId, id);
          }
        }
      } else if (
        type === "TYPE3" ||
        (type === "TYPE1" && catItem?.flag === "yes")
      ) {
        if (["y", "n"].includes(inputValue)) {
          setShowInput(false);
          yesnocreate();
          renderToNextInput(e, categoryId, id);
        } else {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Please enter value as y or n");
          // renderToNextInput(e, categoryId, id);
        }
      } else {
        if (inputValue !== "" && !isNaN(inputValue)) {
          setShowNotesInput(false);
          unitCreate(true);
          renderToNextInput(e, categoryId, id);
        } else if (inputValue !== "") {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Please enter value in number");
          setShowNotesInput(false);
          // renderToNextInput(e, categoryId, id);
        }
      }
    }
  };

  const handleBlurInputArea = (type, e: any, mouseOut, categoryId, id) => {
    setInfluenceIdCalendar(null);
    setSelectedDateInfluence(null);
    if (mouseOut) {
      setShowNotesInput(false);
    }

    if (
      inputNotesValue === "" ||
      inputNotesValue === undefined ||
      inputNotesValue === null
    ) {
      if (
        Number(e.keyCode) === 37 ||
        Number(e.keyCode) === 38 ||
        Number(e.keyCode) === 39 ||
        Number(e.keyCode) === 40 ||
        mouseOut
      ) {
        renderToNextInput(e, categoryId, id);
      }
      return null;
    }
    if (
      Number(e.keyCode) === 37 ||
      Number(e.keyCode) === 38 ||
      Number(e.keyCode) === 39 ||
      Number(e.keyCode) === 40 ||
      mouseOut
    ) {
      if (type === "TYPE2") {
        setShowNotesInput(false);
        outcomeCreate(true);
        renderToNextInput(e, categoryId, id);
      } else if (
        type === "TYPE3" ||
        (type === "TYPE1" && catItem?.flag === "yes")
      ) {
        setShowNotesInput(false);
        yesnocreate(true);
      } else {
        setShowNotesInput(false);
        unitCreate(true);
        renderToNextInput(e, categoryId, id);
      }
    }
  };

  const pushIntoData = (outcomeCreateData, isHasNotes = false) => {
    let oldData: any = {};
    if (!isHasNotes) {
      if (!outcomeState?.isPersonal) {
        const foundOutcome = (outcomeState?.influenceList || []).find(
          (it) => it.influenceId === outcomeCreateData.influenceId
        );
        if (foundOutcome) {
          const journyList = { ...(outcomeState?.journeyList || {}) } as any;
          const foundDateItemIcon = journyList.influence_data.findIndex(
            (it) =>
              it.influenceId === outcomeCreateData.influenceId &&
              it?.date === outcomeCreateData.date
          );
          if (foundDateItemIcon > -1) {
            oldData = { ...journyList.influence_data[foundDateItemIcon] };
          }
        }
      } else {
        const journeyCategoryList = [...(journyList || [])];
        const foundOutcome = (journeyCategoryList || []).findIndex(
          (it) =>
            it.influenceIds &&
            it.influenceIds.includes(outcomeCreateData.influenceId)
        );
        if (foundOutcome > -1) {
          if (
            journeyCategoryList[foundOutcome].alls &&
            journeyCategoryList[foundOutcome].alls.length
          ) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(
              (it) => it.influenceId === outcomeCreateData.influenceId
            );
            if (foundIndex > -1) {
              if (
                journeyCategoryList[foundOutcome].alls[foundIndex]
                  .influenceResponses &&
                journeyCategoryList[foundOutcome].alls[foundIndex]
                  .influenceResponses.length
              ) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[
                  foundIndex
                ].influenceResponses.findIndex(
                  (it) => it.date === outcomeCreateData.date
                );
                if (foundDateValue > -1) {
                  oldData = {
                    ...journeyCategoryList[foundOutcome].alls[foundIndex]
                      .influenceResponses[foundDateValue],
                  };
                }
              }
            }
          }
        } else {
          const foundOther = (journeyCategoryList || []).findIndex(
            (it) => it.name === "Other"
          );
          if (foundOther > -1) {
            const outcomeFound = journeyCategoryList[
              foundOther
            ].influences.findIndex(
              (it) => it.influenceId === outcomeCreateData.influenceId
            );
            if (outcomeFound > -1) {
              if (
                journeyCategoryList[foundOther].influences[outcomeFound]
                  .influenceResponses &&
                journeyCategoryList[foundOther].influences[outcomeFound]
                  .influenceResponses.length
              ) {
                const foundDateValue = journeyCategoryList[
                  foundOther
                ].influences[outcomeFound].influenceResponses.findIndex(
                  (it) => it.date === outcomeCreateData.date
                );
                if (foundDateValue > -1) {
                  oldData = {
                    ...journeyCategoryList[foundOther].influences[outcomeFound]
                      .influenceResponses[foundDateValue],
                  };
                }
              }
            }
          }
        }
      }
      if (oldData?.note && oldData?.note.length) {
        outcomeCreateData.note = oldData?.note || [];
      }
    }

    // if (!outcomeState?.isPersonal) {
    const foundOutcomeDef = (outcomeState?.influenceList || []).find(
      (it) => it.influenceId === outcomeCreateData.influenceId
    );
    if (foundOutcomeDef) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any;
      const foundDateItemIcon = journyList.influence_data.findIndex(
        (it) =>
          it.influenceId === outcomeCreateData.influenceId &&
          it?.date === outcomeCreateData.date
      );
      if (foundDateItemIcon > -1) {
        journyList.influence_data[foundDateItemIcon] = {
          ...foundOutcomeDef,
          ...outcomeCreateData,
        };
      } else {
        journyList.influence_data.push({
          ...foundOutcomeDef,
          ...outcomeCreateData,
        } as any);
      }
      UserTypeDispatch(addJourneyAction(journyList as any));
    }
    // } else {
    const journeyCategoryList = [...(journyList || [])];
    const foundOutcome = (journeyCategoryList || []).findIndex(
      (it) =>
        it.influenceIds &&
        it.influenceIds.includes(outcomeCreateData.influenceId)
    );

    if (foundOutcome > -1) {
      if (
        journeyCategoryList[foundOutcome].alls &&
        journeyCategoryList[foundOutcome].alls.length
      ) {
        const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(
          (it) => it.influenceId === outcomeCreateData.influenceId
        );
        if (foundIndex > -1) {
          if (
            journeyCategoryList[foundOutcome].alls[foundIndex]
              .influenceResponses &&
            journeyCategoryList[foundOutcome].alls[foundIndex]
              .influenceResponses.length
          ) {
            const foundDateValue = journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].influenceResponses.findIndex(
              (it) => it.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].influenceResponses[foundDateValue] = {
                ...outcomeCreateData,
              };
            } else {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].influenceResponses.push(outcomeCreateData);
            }
          } else {
            journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].influenceResponses = [outcomeCreateData];
          }

          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    } else {
      const foundOther = (journeyCategoryList || []).findIndex(
        (it) => it.name === "Other"
      );
      if (foundOther > -1) {
        const outcomeFound = journeyCategoryList[
          foundOther
        ].influences.findIndex(
          (it) => it.influenceId === outcomeCreateData.influenceId
        );
        if (outcomeFound > -1) {
          if (
            journeyCategoryList[foundOther].influences[outcomeFound]
              .influenceResponses &&
            journeyCategoryList[foundOther].influences[outcomeFound]
              .influenceResponses.length
          ) {
            const foundDateValue = journeyCategoryList[foundOther].influences[
              outcomeFound
            ].influenceResponses.findIndex(
              (it) => it.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOther].influences[
                outcomeFound
              ].influenceResponses[foundDateValue] = {
                ...outcomeCreateData,
              };
            } else {
              journeyCategoryList[foundOther].influences[
                outcomeFound
              ].influenceResponses.push(outcomeCreateData);
            }
          } else {
            journeyCategoryList[foundOther].influences[
              outcomeFound
            ].influenceResponses = [outcomeCreateData];
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    }
  };
  const yesnocreate = (isHasNotes = false) => {
    let level = null;
    switch (inputValue.toLowerCase()) {
      case "y":
        level = "Yes";
        break;
      case "n":
        level = "No";
        break;
      default:
        if (["yes", "no"].includes(inputValue.toLowerCase())) {
          level = capitalizeFirstLetter(inputValue.toLowerCase());
        }
        break;
    }
    const outcomeCreateData = {
      date: formatedDate,
      influenceId: catItem?.influenceId,
      levelReach: level,
      note: [inputNotesValue],
      flag: catItem?.flag,
      type: catItem?.type,
      inverse: catItem?.inverse,
      colorCode:
        (catItem?.type === "TYPE3" && level === "No") || level === "No"
          ? catItem?.inverse === "true"
            ? palette.primary.newmain
            : "#F17F79"
          : catItem?.inverse === "true"
            ? "#F17F79"
            : palette.primary.newmain,
    };
    outcomeCreateData.note = outcomeCreateData.note.filter((it) => it);
    pushIntoData(outcomeCreateData, isHasNotes);

    setShowSnakbar(false);
    setNotesInputValue("");
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (
          data.showMessage &&
          formatedDate === moment().format("MM-DD-YYYY")
        ) {
          trackingDispatch(
            setDayCompleteMessage({
              open: data.showMessage,
              message: data.message,
            })
          );
        }
        setSnakbarMsg(data?.message);
        setOpenYesNoModal(false);
        setMsgType("success");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        setMsgType("error");
      });
  };
  const outcomeCreate = (isHasNotes = false) => {
    const outcomeCreateData = {
      date: formatedDate,
      influenceId: catItem?.influenceId,
      answerId:
        parseInt(inputValue) === 0
          ? null
          : catItem?.options[parseInt(inputValue) - 1].answerId,
      score:
        parseInt(inputValue) === 0
          ? 0
          : catItem?.options[parseInt(inputValue) - 1].score,
      index: parseInt(inputValue),
      note: [inputNotesValue],
      type: "TYPE2",
    };

    outcomeCreateData.note = outcomeCreateData.note.filter((it) => it);
    pushIntoData(outcomeCreateData, isHasNotes);
    setNotesInputValue("");
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (
          data.showMessage &&
          formatedDate === moment().format("MM-DD-YYYY")
        ) {
          trackingDispatch(
            setDayCompleteMessage({
              open: data.showMessage,
              message: data.message,
            })
          );
        }
        setInputValue("");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
  };

  const unitCreate = (isHasNotes = false) => {
    if (inputValue !== "" && !isNaN(inputValue)) {
      const outcomeCreateData = {
        influenceId: catItem?.influenceId,
        score: inputValue,
        note: [inputNotesValue],
        answerId: catItem?.options.filter((opt) => opt.value == inputValue)[0]
          ?.answerId,
        date: formatedDate,
        type: "TYPE12",
      };
      outcomeCreateData.note = outcomeCreateData.note.filter((it) => it);
      pushIntoData(outcomeCreateData, isHasNotes);
      setNotesInputValue("");
      trackingService
        .influenceCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          if (
            data.showMessage &&
            formatedDate === moment().format("MM-DD-YYYY")
          ) {
            trackingDispatch(
              setDayCompleteMessage({
                open: data.showMessage,
                message: data.message,
              })
            );
          }
          // setShowSnakbar(true);
          // setSnakbarMsg(data?.message);
          // props?.callservice();
          // setMsgType("success");
          setInputValue("");
        })
        .catch((error) => {
          setShowSnakbar(true);
          setSnakbarMsg(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
          );
          setMsgType("error");
        });
    }
  };
  let fontStyling = {

  }
  if (found) {
    nearValue = nearestValue(
      found?.type === "TYPE1" || found?.type === "TYPE12"
        ? found?.score
        : found?.index,
      catItem
    );

    if (found?.type === "TYPE2") {
      if (
        catItem.type === "TYPE2" &&
        catItem.options.length &&
        catItem.options[found?.index - 1]
      ) {
        nearValue = catItem.options[found?.index - 1].colorCode;
      }
    }
    if (Number(found?.score) === 0 && found?.type === "TYPE2") {
      nearValue = "#C7C7C7";
    }
  }

  if (found?.type === "TYPE1" || found?.type === "TYPE12") {
    if (found?.score && found?.score?.toString()?.length === 2) {
      fontStyling = {
        fontSize: "14px"
      }
    }
    if (found?.score?.toString()?.length >= 3) {
      fontStyling = {
        fontSize: "12px"
      }
    }
  }
  if(catItem?.influenceId === "2snNNyRQ7VCtg7DBF9bYUu") {
    console.log("openHoursModal", openHoursModal, openTimeOfDayModal, formatedDate, catItem);
  }
  const isToday = moment(formatedDate, "mm-DD-yyyy").format("mm-DD-yyyy") === moment(new Date()).format("MM-DD-yyyy")
  return (
    <td
      className={clsx(
        classes.cell,
        classes.borderBottom,
        isExpand ? classes.iconExpand : ""
      )}
      onMouseEnter={() => {
        setHeaderDate(formatedDate);
        setTooltipId(catItem._id);
      }}
      onMouseLeave={() => {
        setHeaderDate(null);
        setTooltipId(null);
      }}
      style={{
        position: "relative",
        height: isFirefox ? "100%" : "inherit",
        ...style,
      }}
      key={formatedDate + "_" + catItem._id}
    >
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={() => {
          setShowSnakbar(false);
        }}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      <div
        style={
          isExpand
            ? {
              height: "100%",
            }
            : {
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
        }
      >
        <div
          className={
            moment(calendar[calendar.length - 1]).format("MM-DD-YYYY") ===
              formatedDate
              ? classes.iconInnerWithoutAfter
              : classes.iconInner
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isExpand ? "flex-start" : "center",
            flexBasis: "100%",
          }}
          onClick={(e) => {
            if (clickCondition && !lockMatrix) {
              setItem(catItem);
            }
          }}
        >
          {showInput &&
            (catItem.type === "TYPE2" ||
              catItem.type === "TYPE1" ||
              catItem.type === "TYPE3" ||
              catItem.type === "TYPE12") ? (
            <span
              className={classes.addOutlineIconInput}
              onClick={() => {
                if (!lockMatrix) {
                  // setTimeout(() => {
                  if (catItem?.type === "TYPE2") {
                    setShowInput(false);
                    setOpenTrackingEventModal(true);
                    setInfluenceId(catItem._id);
                    setItem(catItem);
                    setInfluenceIdCalendar(catItem._id);
                    setSelectedOption(parseInt(optionIndex + 1));
                  } else if (
                    catItem?.type === "TYPE12" &&
                    catItem?.flag === "no"
                  ) {
                    setShowInput(false);
                    if (
                      !(
                        catItem?.type === "TYPE12" &&
                        catItem?.unitValue === "timeofday" &&
                        catItem?.metric_type === "NEUTRAL"
                      )
                    ) {
                      setOpenHoursModal(true);
                    }
                    setInfluenceId(catItem._id);
                    setItem(catItem);
                  }
                  let i = outcomeState.influenceList.findIndex(
                    (influence) => influence._id === catItem._id
                  );
                  setInfluenceIndex(i);
                }
              }}
            >
              <input
                name={catItem?._id}
                type="text"
                autoFocus
                onBlur={() => {
                  handleBlurInput(
                    catItem?.type,
                    { key: "kdksjdks", keyCode: "hdj" },
                    true,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleBlurInput(
                    catItem?.type,
                    e,
                    false,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
              />
            </span>
          ) : (
            <span
              className={classes.addOutlineIcon}
              style={found ? { border: "none", ...fontStyling } : {

              }}
              onDoubleClick={() => {
                if (clickCondition && !lockMatrix) {
                  if (catItem?.type === "TYPE2") {
                    let i = outcomeState.influenceList.findIndex(
                      (influence) => influence._id === catItem._id
                    );
                    setInfluenceIdCalendar(catItem._id);
                    setInfluenceIndex(i);
                    setShowInput(false);
                    setOpenTrackingEventModal(true);
                    setDisplayBorder(true);
                    setInfluenceId(catItem._id);
                    setItem(catItem);
                    setSelectedOption(parseInt(optionIndex + 1));
                  } else if (
                    catItem?.type === "TYPE3" ||
                    (catItem?.type === "TYPE1" && catItem?.flag === "yes")
                  ) {
                    setShowInput(false);
                    setInfluenceIdCalendar(catItem._id);
                    setOpenYesNoModal(true);
                    setInfluenceId(catItem._id);
                    setItem(catItem);
                  } else if (
                    catItem?.type === "TYPE12" &&
                    catItem?.flag === "no"
                  ) {
                    setShowInput(false);
                    if (
                      !(
                        catItem?.type === "TYPE12" &&
                        catItem?.unitValue === "timeofday" &&
                        catItem?.metric_type === "NEUTRAL"
                      )
                    ) {
                      setOpenHoursModal(true);
                    }
                    setInfluenceId(catItem._id);
                    setInfluenceIdCalendar(catItem._id);
                    setItem(catItem);
                  }
                }
              }}
            >
              <HTMLTooltip
                customProps={{
                  open:
                    showInput && catItem?._id === influenceIdInfluence
                      ? true
                      : tooltipId === catItem._id &&
                        headerDate === formatedDate &&
                        visibleTooltip
                        ? visibleTooltip
                        : false,
                  onClose: () => setVisibleTooltip(true),
                }}
                placement="right"
                customIcon={
                  <div>
                    {found ? (
                      <DoubleClickHandler
                        key={catItem._id}
                        id={formatedDate + "_" + catItem._id}
                        onClick={() => {
                          if (clickCondition && !lockMatrix) {
                            setShowInput(true);
                            setInfluenceId(catItem._id);
                            setInfluenceIdCalendar(catItem._id);
                            setItem(catItem);
                            if (
                              catItem?.type === "TYPE12" &&
                              catItem?.unitValue === "timeofday" &&
                              catItem?.metric_type === "NEUTRAL"
                            ) {
                              setOpenTimeOfDayModal(true);
                            } else if (
                              catItem?.type === "TYPE3" ||
                              (catItem?.type === "TYPE1" &&
                                catItem?.flag === "yes")
                            ) {
                              // setOpenYesNoModal(true);
                              setShowInput(true);
                            }
                          }
                        }}
                        onDoubleClick={(e) => {
                          if (clickCondition && !lockMatrix) {
                            e && e.stopPropagation();
                            setInfluenceId(catItem._id);
                            setItem(catItem);
                            setInfluenceIdCalendar(catItem._id);
                            if (
                              catItem?.type === "TYPE12" &&
                              catItem?.unitValue === "timeofday" &&
                              catItem?.metric_type === "NEUTRAL"
                            ) {
                              setShowInput(false);
                              setItem(catItem);
                              setOpenTimeOfDayModal(true);
                            } else if (
                              catItem?.type === "TYPE12" &&
                              catItem?.flag === "no"
                            ) {
                              setShowInput(true);
                              setInfluenceId(catItem._id);
                              setItem(catItem);
                            } else if (
                              catItem?.type === "TYPE3" ||
                              (catItem?.type === "TYPE1" &&
                                catItem?.flag === "yes")
                            ) {
                              setShowInput(false);
                              setItem(catItem);
                              setOpenYesNoModal(true);
                            }
                          }
                        }}
                      >
                        <span
                          className={
                            found?.note.length > 0 ? classes.borderOutline : ""
                          }
                          style={
                            found?.note.length > 0
                              ? {
                                padding: "2px",
                                border: `1px solid ${found?.timeOfDay ? "var(--color-border)" : nearValue
                                  ? nearValue
                                  : found.colorCode
                                    ? found.colorCode
                                    : (found?.type === "TYPE3" &&
                                      found?.levelReach === "No") ||
                                      found?.levelReach === "No"
                                      ? found?.inverse === "true"
                                        ? "var(--color-new-main)"
                                        : "#F17F79"
                                      : found?.inverse === "true"
                                        ? "#F17F79"
                                        : "var(--color-new-main)"
                                  }`,
                                borderRadius: "50%",
                              }
                              : {
                                border: `1px solid ${found?.timeOfDay ? "var(--color-border)" : nearValue
                                  ? nearValue
                                  : found.colorCode
                                    ? found.colorCode
                                    : (found?.type === "TYPE3" &&
                                      found?.levelReach === "No") ||
                                      found?.levelReach === "No"
                                      ? found?.inverse === "true"
                                        ? "var(--color-new-main)"
                                        : "#F17F79"
                                      : found?.inverse === "true"
                                        ? "#F17F79"
                                        : "var(--color-new-main)"
                                  }`,
                                backgroundColor: found.colorCode,
                              }
                          }
                        >
                          <span
                            style={{
                              backgroundColor: found?.timeOfDay ? "var(--color-border)" : nearValue
                                ? nearValue
                                : found.colorCode
                                  ? found.colorCode
                                  : (found?.type === "TYPE3" &&
                                    found?.levelReach === "No") ||
                                    found?.levelReach === "No"
                                    ? found?.inverse === "true"
                                      ? "var(--color-new-main)"
                                      : "#F17F79"
                                    : found?.inverse === "true"
                                      ? "#F17F79"
                                      : "var(--color-new-main)",
                              border: `1px solid ${found?.timeOfDay ? "var(--color-border)" : nearValue
                                ? nearValue
                                : found.colorCode
                                  ? found.colorCode
                                  : (found?.type === "TYPE3" &&
                                    found?.levelReach === "No") ||
                                    found?.levelReach === "No"
                                    ? found?.inverse === "true"
                                      ? "var(--color-new-main)"
                                      : "#F17F79"
                                    : found?.inverse === "true"
                                      ? "#F17F79"
                                      : "var(--color-new-main)"
                                }`,
                              borderRadius: "50%",
                              color: palette.common.white,
                              position: "relative",
                              ...fontStyling
                            }}
                          >
                            {found?.timeOfDay ? (
                              <Tooltip
                                title={
                                  <Typography
                                    sx={{
                                      fontSize: "10px !important",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {found?.timeOfDay}
                                  </Typography>
                                }
                              >
                                <div className={classes.clock}>
                                  {/* <div
                                    className={clsx(classes.hand, classes.hour)}
                                    style={{
                                      transform: `rotate(${
                                        found?.timeOfDay.split(":")[0] * 30 +
                                        found?.timeOfDay.split(":")[1] *
                                          (360 / 720)
                                      }deg)`,
                                    }}
                                  ></div>
                                  <div
                                    className={clsx(
                                      classes.hand,
                                      classes.minute
                                    )}
                                    style={{
                                      transform: `rotate(${
                                        found?.timeOfDay.split(":")[1] * 6
                                      }deg)`,
                                    }}
                                  ></div> */}
                                  {found?.timeOfDay}
                                </div>
                              </Tooltip>
                            ) : found?.type === "TYPE1" ||
                              found?.type === "TYPE12" ? (
                              found?.score
                            ) : (
                              found?.index
                            )}
                          </span>
                        </span>
                      </DoubleClickHandler>
                    ) : (
                      <span
                        key={catItem._id}
                        id={formatedDate + "_" + catItem._id}
                        onClick={() => {
                          if (clickCondition && !lockMatrix) {
                            setTimeout(() => {
                              setShowInput(true);
                              setInfluenceIdCalendar(catItem._id);
                              setInfluenceId(catItem._id);
                              setItem(catItem);
                              if (
                                catItem?.type === "TYPE3" ||
                                (catItem.type === "TYPE1" &&
                                  catItem.flag === "yes")
                              ) {
                                // setOpenYesNoModal(true);
                                setShowInput(true);
                              } else if (
                                catItem?.type === "TYPE12" &&
                                catItem?.unitValue === "timeofday" &&
                                catItem?.metric_type === "NEUTRAL"
                              ) {
                                setOpenTimeOfDayModal(true);
                              }
                            }, 200);
                          }
                        }}
                      >
                        {!lockMatrix && "+"}
                        {lockMatrix && isToday && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.2 2.00004V5.60004C15.2 5.75917 15.1368 5.91178 15.0243 6.0243C14.9117 6.13683 14.7591 6.20004 14.6 6.20004H11C10.8813 6.20013 10.7652 6.165 10.6664 6.09907C10.5676 6.03315 10.4907 5.93941 10.4452 5.82972C10.3998 5.72002 10.3879 5.59931 10.4111 5.48286C10.4343 5.36641 10.4915 5.25945 10.5755 5.17554L11.9487 3.80004C10.844 2.7416 9.37466 2.14842 7.84474 2.14329H7.81099C6.2433 2.14022 4.73744 2.75452 3.61924 3.85329C3.50465 3.96026 3.35276 4.01813 3.19605 4.01454C3.03933 4.01094 2.89025 3.94616 2.78069 3.83406C2.67113 3.72195 2.6098 3.57142 2.60981 3.41466C2.60981 3.25791 2.67117 3.10739 2.78074 2.99529C4.11827 1.68847 5.91176 0.953296 7.78171 0.945317C9.65165 0.937338 11.4514 1.65719 12.8 2.95254L14.177 1.57554C14.261 1.49205 14.3678 1.43528 14.484 1.41238C14.6002 1.38949 14.7206 1.40149 14.83 1.44688C14.9393 1.49227 15.0328 1.56902 15.0987 1.66745C15.1645 1.76589 15.1998 1.88161 15.2 2.00004ZM12.3807 12.1468C11.2694 13.2323 9.78025 13.8444 8.22673 13.8543C6.67322 13.8643 5.17641 13.2712 4.05124 12.2L5.42449 10.8268C5.50927 10.743 5.56718 10.6359 5.59082 10.519C5.61445 10.4022 5.60275 10.281 5.55721 10.1708C5.51167 10.0606 5.43434 9.96653 5.3351 9.9005C5.23585 9.83447 5.11919 9.7995 4.99999 9.80004H1.39999C1.24086 9.80004 1.08825 9.86325 0.975724 9.97578C0.863202 10.0883 0.799988 10.2409 0.799988 10.4V14C0.799895 14.1188 0.835033 14.2349 0.900956 14.3336C0.966878 14.4324 1.06062 14.5094 1.17031 14.5548C1.28001 14.6003 1.40072 14.6121 1.51717 14.589C1.63362 14.5658 1.74057 14.5085 1.82449 14.4245L3.19999 13.0475C4.52914 14.3303 6.30281 15.0494 8.14999 15.0545H8.18974C10.0708 15.0594 11.878 14.323 13.22 13.0048C13.3296 12.8927 13.3909 12.7422 13.3909 12.5854C13.3909 12.4287 13.3296 12.2781 13.22 12.166C13.1105 12.0539 12.9614 11.9891 12.8047 11.9855C12.648 11.9819 12.4961 12.0398 12.3815 12.1468H12.3807Z" fill="#EEEEEE" />
                        </svg>
                        }
                      </span>
                    )}
                  </div>
                }
                title={
                  lockMatrix && isToday ? "The integration shares your data at midnight with us" : lockMatrix ? "You can’t add manual data with integration plans" :
                    catItem.type === "TYPE12" && catItem.unitValue !== "timeofday"
                      ? `Enter ${foundMeasurement ? foundMeasurement.value : ""}`
                      : null
                }
                fontProps={lockMatrix ? {
                  fontSize: `${pxToRem(12)} !important`,
                  fontWeight: 400,
                  lineHeight: `${pxToRem(19.2)} !important`,
                } : {
                  fontSize: `${pxToRem(12)} !important`,
                }}
                sx={lockMatrix ? {
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "320px",
                    background: "var(--color-border)",
                    border: "1px solid var(--color-border)",
                    "& .MuiTooltip-arrow": {
                      color: "var(--color-border)",
                      "&:before": {
                        border: "1px solid var(--color-border)",
                      }
                    }
                  },
                } : {
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "300px",
                  },
                }}
              />
            </span>
          )}
        </div>
        {outcomeState?.isExpand ? (
          <div
            style={{
              display: "flex",
              width: "176px",
              // height: length,
              minHeight: "26px",
              padding: "12px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
              alignSelf: "stretch",
              borderRadius: "12px",
              border: "1px solid #F3F3F3",
              background: "#FFF",
              flexBasis: "100%",
              margin: "13px 8px 0px 0",
              overflowY: "auto",
              wordWrap: "break-word",
              ...heightObj,
            }}
            onClick={() => {
              // if(noData) {
              //   if (clickCondition) {
              //     setShowInput(true);
              //     setDisplayBorder(true);
              //     props.setOutcomeId(item._id);
              //     setItem(item);
              //   }
              // } else {
              if (clickCondition && !found) {
                let i = outcomeState.influenceList.findIndex(
                  (influence) => influence._id === catItem._id
                );
                setInfluenceIndex(i);
                setInfluenceIdCalendar(catItem._id);
                if (catItem?.type === "TYPE2") {
                  setShowInput(false);
                  setOpenTrackingEventModal(true);
                  setDisplayBorder(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                  setSelectedOption(parseInt(optionIndex + 1));
                } else if (
                  catItem?.type === "TYPE3" ||
                  (catItem?.type === "TYPE1" && catItem?.flag === "yes")
                ) {
                  setShowInput(false);
                  setOpenYesNoModal(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                } else if (
                  catItem?.type === "TYPE12" &&
                  catItem?.flag === "no" &&
                  catItem?.unitValue !== "timeofday"
                ) {
                  setShowInput(false);
                  setOpenHoursModal(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                }
              } else if (clickCondition && !showNotesInput) {
                setShowNotesInput(true);
                setInfluenceId(catItem._id);
                setInfluenceIdCalendar(catItem._id);
                setItem(catItem);
                setNotesInputValue(found?.note && found?.note[0]);
                if (found?.timeOfDay) {
                  setInputValue(found?.timeOfDay);
                } else if (
                  found?.type === "TYPE1" ||
                  found?.type === "TYPE12"
                ) {
                  setInputValue(found?.score);
                } else {
                  setInputValue(found?.index);
                }
                if (
                  catItem?.type === "TYPE12" &&
                  catItem?.unitValue === "timeofday" &&
                  catItem?.metric_type === "NEUTRAL"
                ) {
                  setOpenTimeOfDayModal(true);
                } else if (
                  catItem?.type === "TYPE3" ||
                  (catItem?.type === "TYPE1" && catItem?.flag === "yes")
                ) {
                  setNotesInputValue(found?.note && found?.note[0]);
                  setInputValue(found.levelReach);
                  // setOpenYesNoModal(true);
                  setShowNotesInput(true);
                }
              }
              // }
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              if (clickCondition) {
                setShowNotesInput(false);
                setInfluenceIdCalendar(catItem._id);
                if (catItem?.type === "TYPE2") {
                  setShowInput(false);
                  setOpenTrackingEventModal(true);
                  setDisplayBorder(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                  setSelectedOption(parseInt(optionIndex + 1));
                } else if (
                  catItem?.type === "TYPE3" ||
                  (catItem?.type === "TYPE1" && catItem?.flag === "yes")
                ) {
                  setShowInput(false);
                  setOpenYesNoModal(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                } else if (
                  catItem?.type === "TYPE12" &&
                  catItem?.flag === "no" &&
                  catItem?.unitValue !== "timeofday"
                ) {
                  setShowInput(false);
                  setOpenHoursModal(true);
                  setInfluenceId(catItem._id);
                  setItem(catItem);
                }
              }
            }}
          >
            {showNotesInput ? (
              <textarea
                name={catItem?._id}
                autoFocus
                ref={textareaRef}
                value={inputNotesValue}
                style={{
                  width: "100%",
                  minHeight: "10px",
                  resize: "none",
                }}
                onBlur={() => {
                  handleBlurInputArea(
                    catItem?.type,
                    { key: "kdksjdks", keyCode: "hdj" },
                    true,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
                onChange={(e) => {
                  setNotesInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleBlurInputArea(
                    catItem?.type,
                    e,
                    false,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
              />
            ) : (
              <>
                {found?.note && found?.note[0] && (
                  <Typography
                    id={`${catItem?.influenceId}_${formatedDate}`}
                    variant="h5"
                    sx={{
                      color: palette.common.black,
                      marginBottom: "0px !important",
                    }}
                    component="span"
                    title={found?.note}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: found?.note[0].replace(/\n/g, "<br />") || "",
                      }}
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    ></span>
                  </Typography>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>

      {/* SCALE BASE MODA */}
      {openTrackingEventModal && (
        <AddTrackingEventInfluenceModal
          setOpenTrackingEventModal={setOpenTrackingEventModal}
          index={influenceIndex}
          setOutcomeId={setInfluenceId}
          getBackgroudColor={() => { }}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setShowInput={setShowInput}
          influenceId={influenceIdInfluence}
          item={catItem}
          setShowSnakbar={setShowSnakbar}
          setSnakbarMsg={setSnakbarMsg}
          setMsgType={setMsgType}
          setOptionIndex={setOptionIndex}
          optionIndex={optionIndex}
          setDisplayBorder={setDisplayBorder}
          displayBorder={displayBorder}
          callService={callservice}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          journeyData={journyList}
          headerDate={formatedDate}
          renderToNextInput={() => {
            setTimeout(() => {
              renderToNextInput(
                {
                  key: "Enter",
                },
                outcomeState.isPersonal ? category.categoryId : null,
                catItem._id
              );
            }, 200);
          }}
        />
      )}
      {/* UNIT LEVEL MODAL */}
      {openHoursModal &&
        catItem?.type === "TYPE12" &&
        catItem?.flag === "no" &&
        catItem?.unitValue !== "timeofday" && (
          <AddHoursModal
            setOpenHoursModal={setOpenHoursModal}
            item={catItem}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            journeyData={journyList}
            headerDate={formatedDate}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            callService={callservice}
            setInfluenceId={setInfluenceId}
            renderToNextInput={() => {
              setTimeout(() => {
                renderToNextInput(
                  {
                    key: "Enter",
                  },
                  outcomeState.isPersonal ? category.categoryId : null,
                  catItem._id
                );
              }, 200);
            }}
          />
        )}

      {openTimeOfDayModal &&
        (catItem?.type === "TYPE12" ||
          (catItem?.unitValue === "timeofday" &&
            catItem?.metric_type === "NEUTRAL")) && (
          <AddTimeOfDay
            setOpenTimeOfDayModal={setOpenTimeOfDayModal}
            item={catItem}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            journeyData={journyList}
            headerDate={formatedDate}
            categoryId={category.categoryId}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            renderToNextInput={() => {
              setTimeout(() => {
                renderToNextInput(
                  {
                    key: "Enter",
                  },
                  outcomeState.isPersonal ? category.categoryId : null,
                  catItem._id
                );
              }, 200);
            }}
            callService={callservice}
            setInfluenceId={setInfluenceId}
          />
        )}

      {/* YES/NO MODAL */}
      {openYesNoModal &&
        (catItem?.type === "TYPE3" ||
          (catItem?.type === "TYPE1" && catItem?.flag === "yes")) && (
          <AddYesNoModal
            setOpenYesNoModal={setOpenYesNoModal}
            item={catItem}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            journeyData={journyList}
            headerDate={formatedDate}
            setShowSnakbar={setShowSnakbar}
            setSnakbarMsg={setSnakbarMsg}
            setMsgType={setMsgType}
            callService={callservice}
            setInfluenceId={setInfluenceId}
            renderToNextInput={() => {
              setTimeout(() => {
                renderToNextInput(
                  {
                    key: "Enter",
                  },
                  outcomeState.isPersonal ? category.categoryId : null,
                  catItem._id
                );
              }, 200);
            }}
          />
        )}
    </td>
  );
};

export default React.memo(IconPersonalInfluence);
