import { makeStyles } from "@material-ui/core";
import {
  DialogContent,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import palette from "../../theme/palette";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import CustomCalender from "./CustomCalender";
import { UserTypeContext } from "../../context/userStatus";
import { trackingService } from "../../services/tracking";
import {
  addNotesAction,
  addNotesJourneyAction,
} from "../../store/actions/userTypeAction";
import moment from "moment";
import { TrackingContext } from "../../context/userTracking";

interface IWellbeing {
  open: boolean;
  outcomeValueInput: any;
  setopenWellbeing: any;
  journeyData: any;
  filterId?: string;
  type?: string
}

const Wellbeing = (props: IWellbeing) => {
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  useEffect(() => {
    callService(ITrackerState.startDate, ITrackerState.endDate)
  }, [ITrackerState.startDate, ITrackerState.endDate])
  const callService = async (startDay, endDay) => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      // if (!outcomeState?.isPersonal) {
      let data = await trackingService.getJourneyList(
        outcomeState.token,
        bodyData
      );
      userTypeDispatch(addNotesAction(data));
      // } else {
      //   let data = await trackingService.getPersonalJourneyList(
      //     outcomeState.token,
      //     bodyData
      //   );
      //   userTypeDispatch(addNotesJourneyAction(data?.data));
      // }
    } catch (error) {
      console.log("jourey list error");
    }
  };


  console.log("props?.journeyData", props?.journeyData);
  
  return (
    <Grid>
      <Dialog
        open={props.open}
        onClose={() => props?.setopenWellbeing(false)}
        className="dialogWrapper dialogWrapper-tracking dialogWrapper-tracking-cal"
        fullWidth
      >
        <DialogTitle>
          Notes of {props?.outcomeValueInput}
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={() => props?.setopenWellbeing(false)}
          >
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomCalender
            journeyData={props?.journeyData}
            name={props?.outcomeValueInput}
            filterId={props?.filterId}
            type={props?.type}
            callService={callService}
            outcomeState={outcomeState}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Wellbeing;
