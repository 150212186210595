import { Box, Dialog } from "@mui/material"
import React from "react"
import CommonTypo from "../../../components/CommonTypo"
import NewButton from "../../../components/NewButton"

const ConnectingDialogue = ({
    openModal,
    isMobile,
    onClose,
}) => {
    let paperStyles: any = {
        display: 'flex !important',
        padding: '32px 24px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '24px !important',
        minWidth: "360px",
        maxWidth: "max-content",
        borderRadius: "12px",
        overflowY: "auto"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    const closemodal = () => {
        onClose()
    }
    return <>
        <Dialog
            onClose={closemodal}
            open={openModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"} width={"312px"}>
                <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">We’re working on that</CommonTypo>
                <CommonTypo size={16} lineHeight={19.2} fontWeight={400} color="var(--color-text-secondary)" extraSx={{
                    textAlign: "center"
                }}>It may take up to 5 minutes, we’ll notify you when it’s ready</CommonTypo>

            </Box>
            <Box display={"flex"} flex={1} width={"100%"} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                    <NewButton
                        buttonText="Ok"
                        onClick={closemodal}
                        extraSx={{
                            background: "var(--color-border)",
                            border: "none",
                            width: "102px",
                            height: "43px",
                            flex: 1,
                            marginBottom: "0px !important",
                            "&:hover": {
                                background: "var(--color-border)"
                            },
                            "&.MuiButton-outlined": {
                                border: `none`,
                            },
                            "&.MuiButton-contained": {
                                border: `none`,
                            },
                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                            fontSize: "16px !important",
                            lineHeight: "16px !important",
                            fontWeight: `600 !important`,
                            letterSpacing: "2% important"
                        }}
                    />
                </Box>


            </Box>
        </Dialog>
    </>
}

export default ConnectingDialogue

