import { Box, Popover, Switch } from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import CommonTypo from "../../components/CommonTypo"
import { styled } from "@mui/material/styles";
import palette from "../../theme/palette";
import NewButton from "../../components/NewButton";
import { userService } from "../../services/auth";
import moment from "moment";
import MobileLinkModal from "../trackerNewV2/mobileLinkModal";
import useWidth from "../../utils/useWidth";
import { UserTypeContext } from "../../context/userStatus";
interface switchProps {
    onChange: any;
    checked: boolean;
}
const Drop = ({
    color = "#3E4240"
}) => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 17H17" stroke={color} stroke-linecap="round" />
        <path d="M1 17L0.999999 1" stroke={color} stroke-linecap="round" />
    </svg>
const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
const minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
const IOSSwitch = styled((props: switchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        position: "absolute !important",
        // margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#2ECA45" : "var(--color-new-main)",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
            "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                width: 16,
                height: 16,
                backgroundColor: "#fff",
                margin: "4px",
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16,
        backgroundColor: "var(--white-background)",
        margin: "4px",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "var(--color-border)",
        border: `1px solid var(--color-border)`,
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
const Reminders = ({
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType
}) => {
    const { state: outcomeState } =
        useContext(UserTypeContext);
    const selectRef = useRef(null);
    const [sendDaily, setDaily] = useState(false)
    const [emailRemind, setEmailRemind] = useState(false)
    const [desktopRemind, setDesktopRemind] = useState(false)
    const [mobileRemind, setMobileRemind] = useState(false)
    const [showDay, setShowDay] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isSelected, setIsSelected] = React.useState(null);
    const [visibleMobileLoginModal, setVisibleMobileLoginModal] = useState(false);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [selectedScheduleTime, setSelectedScheduleTime] = useState({
        hour: "22",
        minutes: "00"
    })
    const open = Boolean(anchorEl);
    const sendDailyProps = {
        // make sure all required component's inputs/Props keys&types match
        onChange: (e) => {
            if (sendDaily) {
                setMobileRemind(false);
                setEmailRemind(false);
                setDesktopRemind(false)
            }
            setDaily(!sendDaily);
        },
        checked: sendDaily,
    };

    const emailReminderProps = {
        // make sure all required component's inputs/Props keys&types match
        onChange: (e) => {
            setEmailRemind(!emailRemind);
        },
        disabled: !sendDaily,
        checked: emailRemind,
    };

    const desktopReminderProps = {
        // make sure all required component's inputs/Props keys&types match
        onChange: (e) => {
            setDesktopRemind(!desktopRemind);
        },
        disabled: !sendDaily,
        checked: desktopRemind,
    };

    const mobileReminderProps = {
        // make sure all required component's inputs/Props keys&types match
        onChange: (e) => {
            setMobileRemind(!mobileRemind);
            if (!mobileRemind) {
                setVisibleMobileLoginModal(true)
            }
        },
        disabled: !sendDaily,
        checked: mobileRemind,
    };
    const userDetails = JSON.parse(localStorage.getItem("userValues"));
    const reminderCreate = (active = false) => {
        userService.reminderCreate({
            "time": !active ? moment().format() : moment(`${selectedScheduleTime.hour}:${selectedScheduleTime.minutes}`, 'HH:mm').format(),
            "status": !active || !emailRemind ? "NOT_ACTIVE" : "ACTIVE",
            desktopReminderStatus: !active || !desktopRemind ? "NOT_ACTIVE" : "ACTIVE",
            mobileReminderStatus: !active || !mobileRemind ? "NOT_ACTIVE" : "ACTIVE",
            uid: userDetails?.user?.uid ? userDetails?.user?.uid : userDetails?.uid
        }).then((data) => {
            setMsgType("success");
            setShowSnakbar(true);
            setSnakbarMsg(data.description);
            handleClose()
        })
    }
    const width = useWidth();
    const getReminderList = () => {
        userService.reminderList({
            type: "APP"
        }, outcomeState.token).then((data) => {
            if (data.data && data.data._id) {
                if (data.data.status === "ACTIVE" || data.data.desktopReminderStatus === "ACTIVE" || data.data.mobileReminderStatus === "ACTIVE") {
                    setDaily(true);
                }
                setEmailRemind(data.data.status === "ACTIVE");
                setDesktopRemind(data.data.desktopReminderStatus === "ACTIVE");
                setMobileRemind(data.data.mobileReminderStatus === "ACTIVE");
                const formatted = moment(data.data.time).format()
                // setSelectedScheduleTime({
                //     hour: formatted.split("T")[1].split(":")[0],
                //     minutes: formatted.split("T")[1].split(":")[1]
                // })
            }

        })
    }
    useEffect(() => {
        getReminderList()
    }, [])
    return <>
        <Box display={"flex"} flexDirection={"column"} width={"100%"} alignSelf={"stretch"} gap={"24px"}>
            <Box display={"flex"} flexDirection={"column"} width={"100%"} alignSelf={"stretch"} gap={"8px"}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} alignSelf={"stretch"} paddingBottom={"16px"} gap={"24px"}
                >
                    <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">Send daily tracking reminder</CommonTypo>
                    <IOSSwitch {...sendDailyProps} />
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} alignSelf={"stretch"} paddingBottom={"16px"} gap={"24px"}
                    paddingLeft={"32px"}
                    position={"relative"}
                >
                    <Box position={"absolute"} left={0} top={"-5px"}><Drop color={!sendDaily ? "var(--color-border)" : "#3E4240"} /></Box>
                    <CommonTypo size={16} lineHeight={19.2} color={!sendDaily ? "var(--color-border)" : "var(--color-text-primary)"}>E-mail reminder</CommonTypo>
                    <IOSSwitch {...emailReminderProps} />
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} alignSelf={"stretch"} paddingBottom={"16px"} gap={"24px"}
                    paddingLeft={"32px"}
                    position={"relative"}
                >
                    <Box position={"absolute"} left={0} top={"-5px"}><Drop color={!sendDaily ? "var(--color-border)" : "#3E4240"} /></Box>
                    <CommonTypo size={16} lineHeight={19.2} color={!sendDaily ? "var(--color-border)" : "var(--color-text-primary)"}>Desktop reminder</CommonTypo>
                    <IOSSwitch {...desktopReminderProps} />
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} alignSelf={"stretch"} paddingBottom={"16px"} gap={"24px"}
                    paddingLeft={"32px"}
                    position={"relative"}
                >
                    <Box position={"absolute"} left={0} top={"-5px"}><Drop color={!sendDaily ? "var(--color-border)" : "#3E4240"} /></Box>
                    <CommonTypo size={16} lineHeight={19.2} color={!sendDaily ? "var(--color-border)" : "var(--color-text-primary)"}>Mobile reminder</CommonTypo>
                    <IOSSwitch {...mobileReminderProps} />
                </Box>
            </Box>
            {/* <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} alignSelf={"stretch"} paddingBottom={"16px"} gap={"24px"}
            >
                <CommonTypo size={16} lineHeight={19.2} color={!sendDaily ? "var(--color-border)" : "var(--color-text-primary)"}>Reminder time (24h format)</CommonTypo>
                <Box sx={{
                    background: "var(--white-background)",
                    width: "102px",
                    height: "43px",
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Box sx={{ display: "flex", alignItems: 'center', gap: "4px" }} ref={selectRef}>
                        <Box sx={{
                            width: '35px',
                            height: '27px',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: !sendDaily ? "var(--color-border)" : isSelected === "hour" ? "var(--color-border)" : "var(--white-background)"
                        }} onClick={() => {
                            if (sendDaily) {
                                setAnchorEl(selectRef.current)
                                setIsSelected('hour')
                            }

                        }}>
                            <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.hour || ""}</CommonTypo>
                        </Box>
                        <CommonTypo color="#000000">:</CommonTypo>
                        <Box sx={{
                            width: '35px',
                            height: '27px',
                            border: '1px solid #EEEEEE',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: !sendDaily ? "var(--color-border)" : isSelected === "minutes" ? "var(--color-border)" : "var(--white-background)"
                        }} onClick={() => {
                            if (sendDaily) {
                                setAnchorEl(selectRef.current)
                                setIsSelected('minutes')
                            }

                        }}>
                            <CommonTypo size={16} lineHeight={19.2} color="var(--color-text-primary)">{selectedScheduleTime.minutes || ""}</CommonTypo>
                        </Box>
                    </Box>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                background: "#FFFFFF",
                                boxShadow: "0px 0px 64px 0px #190D1A1A",
                                borderRadius: "12px",
                                width: "102px",
                                marginTop: "10px"
                            }
                        }}
                    >
                        <Box sx={{
                            padding: "0px",
                            borderRadius: "8px",
                            height: "198px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",

                            // justifyContent: "flex-end"
                        }}>
                            {(isSelected === "minutes" ? minutes : hours).map(it => <Box key={it} display={"flex"} alignItems={"center"} justifyContent={isSelected === "minutes" ? "flex-end" : "flex-start"} width={"100%"} borderRadius={"8px"} padding={"4px 8px"} sx={{
                                cursor: "pointer"
                            }} onClick={() => {
                                setSelectedScheduleTime({
                                    ...selectedScheduleTime,
                                    [isSelected]: it
                                })
                                setIsSelected(null)
                                setAnchorEl(null)
                            }}>
                                <CommonTypo size={12} color="var(--color-text-primary)" lineHeight={14.4}>{it}</CommonTypo>
                            </Box>)}
                        </Box>
                    </Popover>
                </Box>
            </Box> */}
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                <NewButton
                    buttonText={"Save"}
                    variant="contained"
                    onClick={() => reminderCreate(sendDaily)}
                    boxSx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                        padding: "16px"
                    }}
                    typographySx={{
                        fontSize: "16px !important",
                        color: "var(--color-text-primary)",
                        fontWeight: `600 !important`
                    }}
                />
            </Box>
        </Box>
        <MobileLinkModal visibleMobileLoginModal={visibleMobileLoginModal}
            setVisibleMobileLoginModal={setVisibleMobileLoginModal}
            isMobile={width < 768} />
    </>
}

export default Reminders